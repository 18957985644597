import React, { useState, useContext, useEffect } from 'react'
import './ContactLead.css'
import MenuBar from '../../Components/MenuBar/MenuBar'
import { MainContext } from '../../ContextStore/MainContext';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';
import Header from '../../Components/Header/Header';
import Happy_Birthday from './email_templates/Happy_BirthDay';
import Welcome_mail from './email_templates/Welcome_mail';
import ModalForm from '../../Components/ModalForm/ModalForm';
import Footer from '../../Components/FooterComponent/Footer';
import axios from 'axios'


import toast, { Toaster } from 'react-hot-toast';
import ReactLoading from 'react-loading';

export default function ContactLead() {

    const [selectedContactOption, setSelectedContactOption] = useState("")
    const [selectedMailTemplate, setSelectedMailtemplate] = useState("")
    const [selectedWhatsAppTemplate, setSelectedWhatsApptemplate] = useState("")

    const { BASE_URL, leadEditedData, setLeadEditedData, isContactLeadClicked, setSelectedSubOption, setOpenModal } = useContext(MainContext)
    // console.log(leadEditedData);
    const navigate = useNavigate()
    const [isLoading, setIsloading] = useState(false)

    const [emailTemplateList, setEmailTemplateList] = useState([])
    const [whatsappTemplateList, setWhatsappTemplateList] = useState([])
    const [selectedMailSubject, setSelectedMailSubject] = useState('')
    const [selectedWhatsAppTemplateLink, setSelectedWhatsAppTemplateLink] = useState('https://botsailor.com/api/v1/whatsapp/send/template?apiToken=4764%7ChgJZoDg4Vbe8DIq3V2sWF6XhyIUv8e6ux8JpHp2B&phoneNumberID=241749175682154&botTemplateID=75693&botTemplateHeaderMediaUrl=https%3A%2F%2Fs.yimg.com%2Ffz%2Fapi%2Fres%2F1.2%2F7wmcqjtgVFiMeMxLOlW3sw--%7EC%2FYXBwaWQ9c3JjaGRkO2ZpPWZpdDtoPTI0MDtxPTgwO3c9MjQw%2Fhttps%3A%2F%2Fs.yimg.com%2Fzb%2Fimgv1%2Fe9d6381f-6a16-39d9-b6f9-1467838c022c%2Ft_500x300&botTemplateQuickreplyButtonValues=%5B%22EXTERNAL_ECOMMERCE_CONFIRM_ORDER%22%2C%22EXTERNAL_ECOMMERCE_CONFIRM_ORDER%22%5D&sendToPhoneNumber=')

    useEffect(() => {
        // console.log(leadEditedData.email);
        if (leadEditedData.email === "") {
            navigate('/lead-management')
        }
    }, [])

    useEffect(() => {
        // console.log(leadEditedData);
        const storedLeadData = localStorage.getItem("leadData");

        if (storedLeadData !== "undefined" && storedLeadData !== null) {
            // console.log("enter");
            // setLeadEditedData(JSON.parse(localStorage.getItem("leadData")));
        }
        else {
            // navigate('/lead-management')
            // toast.error('Please choose only one action either select or click')
        }
    }, []);



    useEffect(() => {
        fetch(`${BASE_URL}templates/email_template/`)
            .then(response => {
                if (!response.ok) throw new Error('Failed to fetch data');
                return response.json();
            })
            .then(data => {
                // console.log(data);
                setEmailTemplateList(data)
            })
            .catch(error => {
                console.log(error);
            });


        fetch(`${BASE_URL}templates/whatsapp_template/`)
            .then(response => {
                if (!response.ok) throw new Error('Failed to fetch data');
                return response.json();
            })
            .then(data => {
                // console.log(data);
                setWhatsappTemplateList(data)
            })
            .catch(error => {
                console.log(error);
            });
    }, [])






    function sendEmail() {
        setIsloading(true)
        const mailDetails = {
            recipient_emails: Array.isArray(leadEditedData) ? leadEditedData.map(item => item.email) : [leadEditedData.email],
            recipient_names: Array.isArray(leadEditedData) ? leadEditedData.map(item => item.firstname) : [leadEditedData.firstname],
            template_name: selectedMailTemplate
        }
        if (!mailDetails.recipient_emails) {
            toast.error("Please select atleast one Email")
            setIsloading(false)
            return
        }

        if (selectedMailTemplate === "") {
            toast.error("Please select  Template")
            setIsloading(false)
            return
        }
        try {
            fetch(`${BASE_URL}send_emails/`, {
                method: "POSt",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },

                body: JSON.stringify(mailDetails)
            })
                .then(response => {
                    console.log(response);
                    return response.json();
                })
                .then(data => {

                    setIsloading(false)
                    console.log(data);
                    if (data.detail === "Unable to locate credentials")
                        toast.error('Unable to locate credentials')
                    if (data.message === "Emails sent successfully!")
                        toast.success("Success")
                    else
                        toast.error("Unabel to send")

                })
                .catch(error => {
                    setIsloading(false)
                    console.log(error);
                });
        }
        catch (err) {
            console.log(err);
        }
    }

    function sendWhatsApp() {
        setIsloading(true)
        const mailDetails = {
            recipient_emails: Array.isArray(leadEditedData) ? leadEditedData.map(item => item.mobile) : [leadEditedData.mobile],
            recipient_names: Array.isArray(leadEditedData) ? leadEditedData.map(item => item.firstname) : [leadEditedData.firstname],
            template_name: selectedWhatsAppTemplate
        }

        if (!mailDetails.recipient_emails) {
            toast.error("Please select atleast one Email")
            setIsloading(false)
            return
        }

        if (selectedWhatsAppTemplate === "") {
            toast.error("Please select Template")
            setIsloading(false)
            return
        }
        // console.log(mailDetails.recipient_emails);

        mailDetails.recipient_emails.length > 0 &&

            fetch(`${BASE_URL}whatsapp_integration/send_whatsapp_msg/?template_name=${selectedWhatsAppTemplate}`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(mailDetails.recipient_emails)
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    // console.log(data);
                    setIsloading(false)
                    if (data) {
                        toast.success(data)
                    }
                    else {
                        toast.error('Error Occcured. Please try again');
                    }

                })
                .catch(error => {
                    setIsloading(false)
                    toast.error('Error Occcured. Please try again');
                });


    }







    return (
        <div>
            <Header />

            <div className='contact_lead_screen'>
                <div className='buttons_and_templates_section'>
                    <p style={{ marginTop: "110px" }} className='page_heading'>Contact Lead</p>

                    <div className='lead_contact_leads_buttons'>
                        <p className='consultant_management_button'
                            onClick={() => {
                                setSelectedContactOption("Send Mail")
                            }}>Send Mail</p>

                        <p className='consultant_management_button'
                            onClick={() => {
                                setSelectedContactOption("Send WhatsApp")
                            }}>Send  WhatsApp</p>

                        {(leadEditedData.email || leadEditedData.length === 1) && <p className='consultant_management_button'
                            onClick={() => {
                                setSelectedContactOption("Call")
                            }}>Call</p>}
                    </div>

                    {selectedContactOption === "Call" &&
                        <div>
                            <p className='mobile_number'>Mobile : {leadEditedData[0] ? leadEditedData[0].mobile : JSON.parse(localStorage.getItem('leadData'))['mobile']}</p>
                            <p className='consultant_management_button' style={{ width: "fit-content" }}
                                onClick={() => {
                                    setSelectedSubOption("Add Notes Call")
                                    setOpenModal(true)

                                }}>Add Notes (Call Details)</p>
                        </div>
                    }

                    {
                        selectedContactOption && selectedContactOption !== "Call" && selectedContactOption === "Send Mail" &&
                        <div>
                            <div>
                                <FormControl style={{ width: "25vw" }} className='template_dropdown'>
                                    <InputLabel id="demo-simple-select-label">Select Template</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedMailTemplate}
                                        label="Select Template"
                                        onChange={(e) => setSelectedMailtemplate(e.target.value)}
                                    >
                                        {
                                            emailTemplateList.length > 0 && emailTemplateList.map((e, id) => {
                                                return (
                                                    <MenuItem value={e.template_name} key={id} onClick={() => { setSelectedMailSubject(e.subject) }}>{e.template_name}</MenuItem>
                                                )
                                            })
                                        }

                                    </Select>
                                </FormControl>

                                <div className='template_body'>
                                    <p className='selected_contacted_option'>{selectedContactOption}</p>

                                    <div className='template_sub_sections'>
                                        <span className='template_side_heading'>To : </span>
                                        {leadEditedData.length > 0 ? leadEditedData?.map((e, id) => {
                                            return (
                                                <span key={id}>
                                                    {selectedContactOption === "Send Mail" ? e?.email : e?.mobile}{id < leadEditedData.length - 1 ? ", " : null}
                                                </span>
                                            )
                                        }) : <span>{selectedContactOption === "Send Mail" ? leadEditedData.email : leadEditedData.mobile}</span>}
                                    </div>

                                    <div className='template_sub_sections'>
                                        <span className='template_side_heading'>Subject : </span>
                                        <p>{selectedMailSubject}</p>
                                    </div>

                                </div>
                            </div>

                            <div style={{ marginLeft: "20px", marginTop: "20px" }} className='form_submit_button' onClick={() => {
                                sendEmail()
                            }}>
                                {
                                    !isLoading ?
                                        <span>Send</span> :
                                        <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                                }
                            </div>
                        </div>
                        // <div>
                        //      {selectedContactOption === "Send Mail" && leadEditedData?.email!=="NA" && (
                        //         <a href={`https://mail.google.com/mail/?view=cm&fs=1&to=${leadEditedData?.mail}`} target="_blank" rel="noreferrer">
                        //            Click here to send mail to {leadEditedData?.email}
                        //         </a>
                        //     )}
                        //      {selectedContactOption === "Send Mail" && leadEditedData?.email==="NA" && (
                        //        <p style={{fontSize:"18px",fontWeight:'500',textAlign:"center"}}>No email found</p>
                        //     )}
                        // </div>
                    }





                    {
                        selectedContactOption && selectedContactOption !== "Call" && selectedContactOption === "Send WhatsApp" &&
                        // <div>
                        //     <div>
                        //         <FormControl style={{ width: "25vw" }} className='template_dropdown'>
                        //             <InputLabel id="demo-simple-select-label">Select Template</InputLabel>
                        //             <Select
                        //                 labelId="demo-simple-select-label"
                        //                 id="demo-simple-select"
                        //                 value={selectedWhatsAppTemplate}
                        //                 label="Select Template"
                        //                 onChange={(e) => setSelectedWhatsApptemplate(e.target.value)}
                        //             >
                        //                 {
                        //                     whatsappTemplateList.length > 0 && whatsappTemplateList.map((e, id) => {
                        //                         return (
                        //                             <MenuItem value={e.template_name} key={id} onClick={() => setSelectedWhatsAppTemplateLink(e.url)}>{e.template_name}</MenuItem>
                        //                         )
                        //                     })
                        //                 }

                        //             </Select>
                        //         </FormControl>

                        //         <div className='template_body'>
                        //             <p className='selected_contacted_option'>{selectedContactOption}</p>
                        //             <a href="https://wa.me/916301357273" target="_blank">Contact us on WhatsApp</a>
                        //             <div className='template_sub_sections'>
                        //                 <span className='template_side_heading'>To : </span>
                        //                 {leadEditedData.length > 0 ? leadEditedData?.map((e, id) => {
                        //                     return (
                        //                         <span key={id}>
                        //                             {selectedContactOption === "Send Mail" ? e?.email : e?.mobile}{id < leadEditedData.length - 1 ? ", " : null}
                        //                         </span>
                        //                     )
                        //                 }) : <span>{selectedContactOption === "Send Mail" ? leadEditedData.email : leadEditedData.mobile}</span>}
                        //             </div>

                        //             {/* <div className='template_sub_sections'>
                        //                 <span className='template_side_heading'>Subject : </span>
                        //                 <p>Not Available</p>
                        //             </div> */}


                        //         </div>
                        //     </div>

                        //     <div style={{ marginLeft: "20px", marginTop: "20px" }} className='form_submit_button' onClick={() => {
                        //         sendWhatsApp()
                        //     }}>
                        //         {
                        //             !isLoading ?
                        //                 <span>Send</span> :
                        //                 <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                        //         }
                        //     </div>
                        // </div>
                        <div>
                            {selectedContactOption === "Send WhatsApp" && (
                                <a href={`https://wa.me/${leadEditedData?.mobile}`} target="_blank" rel="noreferrer">
                                    Click here to chat with {leadEditedData?.mobile} on WhatsApp
                                </a>
                            )}
                           
                        </div>
                    }

                </div>

                {/* {leadEditedData &&
                    <div className='lead_details_section' style={{ marginTop: "110px" }}>
                        <p className='page_heading' style={{ marginTop: '0px' }}>Lead Details</p>
                             <div><p>First Name:</p> <p>{leadEditedData?.firstname}</p></div>
                            <div><p>Last Name: </p> <p>{leadEditedData?.lastname}</p></div>
                            <div><p>Incorrect Lead: </p><p>{leadEditedData?.incorrect_lead_flag}</p></div>
                            <div><p>Email: </p> <p>{leadEditedData?.email}</p></div>
                            <div><p>Incorrect Email: </p><p>{leadEditedData?.incorrect_email_flag}</p></div>
                            <div><p>Mobile: </p> <p>{leadEditedData?.mobile}</p></div>
                            <div><p>Incorrect Mobile: </p><p>{leadEditedData?.incorrect_mobile_flag}</p></div>
                            <div><p>State: </p> <p>{leadEditedData?.state}</p></div>
                            <div><p>City: </p> <p>{leadEditedData?.city}</p></div>
                            <div><p>Gender: </p><p>{leadEditedData?.gender}</p></div>
                            <div><p>Age: </p><p>{leadEditedData?.age}</p></div>
                            <div><p>Highest Qualification: </p><p>{leadEditedData?.highest_qualification}</p></div>
                            <div><p>Year of Passing: </p><p>{leadEditedData?.year_of_passing}</p></div>
                            <div><p>Reason for taking course: </p><p>{leadEditedData?.reason_for_taking_this_course}</p></div>
                            <div><p>Occupation: </p><p>{leadEditedData?.occupation}</p></div>
                            <div><p>Date Enrolled: </p><p>{leadEditedData?.date_enrolled?.substring(0, 10)}</p></div>
                            <div><p>Registered Date: </p><p>{leadEditedData?.enquiry_date?.substring(0, 10)}</p></div>
                            <div><p>FollowUp Date: </p><p>{leadEditedData?.followup_date}</p></div>
                            <div><p>Device: </p><p>{leadEditedData?.device}</p></div>
                            <div><p>Course Interested: </p><p>{leadEditedData?.course_interested}</p></div>
                            <div><p>Batch: </p><p>{leadEditedData?.batch}</p></div>
                            <div><p>Batch Start Date: </p><p>{leadEditedData?.batch_start_date?.substring(0, 10)}</p></div>
                            <div><p>Campaign: </p><p>{leadEditedData?.campaign}</p></div>
                            <div><p>Lead Status: </p><p>{leadEditedData?.lead_status}</p></div>
                            <div><p>Lead Source: </p><p>{leadEditedData?.lead_source}</p></div>
                            <div><p>Source URL: </p><p>{leadEditedData?.source_url}</p></div>
                            <div><p>Lead Medium: </p><p>{leadEditedData?.lead_medium}</p></div>
                            <div><p>Personal Info: </p><p>{leadEditedData?.level_1} - {leadEditedData?.level_2} - {leadEditedData?.level_3}</p></div>
                            <div><p>Consult Assigned: </p><p>{leadEditedData?.primary_consultant}</p></div>

                    </div>} */}

            </div>

            <div style={{ marginTop: '90px', width: "100%", overflowX: 'hidden' }}>
                <Footer />
            </div>

            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: '',
                    duration: 5000,
                    style: {
                        background: 'white',
                        color: '#000',
                    },

                    success: {
                        duration: 5000,
                        theme: {
                            primary: 'green',
                            secondary: 'white',
                        },
                    },
                }}
            />
            <ModalForm />
        </div>
    )
}
