import React, { useContext, useEffect } from 'react'
import MenuBar from '../../Components/MenuBar/MenuBar'
import '../../Components/TableComponent/TableComponent.css'
import { MainContext } from '../../ContextStore/MainContext';
import ModalForm from '../../Components/ModalForm/ModalForm';

import TableComponent2 from '../../Components/TableComponent/TableComponent2';


import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/FooterComponent/Footer';

export default function LeadManagement() {
    const { setIsContactLeadClicked,BASE_URL } = useContext(MainContext)
    const navigate = useNavigate()
    

    useEffect(() => {
        setIsContactLeadClicked(false)
        const decryptedSelectedOption = Cookies.get("selectedOption") ? CryptoJS.AES.decrypt(Cookies.get("selectedOption"), 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString(CryptoJS.enc.Utf8) : null;
        if (!decryptedSelectedOption) {
            navigate('/')
        }
    }, [])


    
   
    





    return (
        <div className='home_component' style={{height:'100vh',overflowY:'scroll'}}>
        
            <div>
                <Header/>
            </div>

            {/* <div className='table_and_buttons' style={{marginTop:"50px"}}>
                {localStorage.getItem('selectedField')==="New"?<NewLeads/>:
                localStorage.getItem('selectedField')==="Closed"?<ClosedLeads/>:
                localStorage.getItem('selectedField')==="Followup"?<FolllowupLeads/>:<TableComponent2/>}
            
            </div> */}
            <div className='table_and_buttons' style={{marginTop:"50px"}}>
                <TableComponent2 
                courses={localStorage.getItem("course_interested")?JSON.parse(localStorage.getItem("course_interested")):[]}
                status={localStorage.getItem("lead_status")?JSON.parse(localStorage.getItem("lead_status")):[]}
                leadSource={localStorage.getItem("lead_source")?JSON.parse(localStorage.getItem("lead_source")):[]}
                />
            </div>


            <div style={{ marginTop: '100px', width: "100%", overflowX: 'hidden' }}>
                <Footer />
            </div>

            <ModalForm />

        </div>
    )
}
