import React, { useContext, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import '../../Components/ModalForm/ModalForm.css'
import { MainContext } from '../../ContextStore/MainContext';
import '../Settings/Settings.css'
import toast, { Toaster } from 'react-hot-toast';
import ReactLoading from 'react-loading';
import TextField from '@mui/material/TextField';




export default function SettingsModal() {
    const { BASE_URL, openTemplateModal, setOpenTemplateModal } = useContext(MainContext)
    const handleClose = () => setOpenTemplateModal(false);
    const [isLoading, setIsLoading] = useState(false)
    const [templateName, setTemplateName] = useState('')
    const [templateCode, setTemplateCode] = useState('')



    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "fit-content",
        bgcolor: '#eee',
        border: 'none !important',
        outline: "none !important",
        boxShadow: 24,
        borderRadius: 2,
        p: 2,
    };

   


    function AddTemplate() {
        setIsLoading(true)
        if (templateName === "") {
            toast.error("Please Enter Template Name")
            setIsLoading(false)
            return
        }

        // if (templateCode === "") {
        //     toast.error("Please Enter Template Code")
        //     setIsLoading(false)
        //     return
        // }
        else {
            const parsedTemplateCode = JSON.parse(localStorage.getItem('template_code')); 
                fetch(`${BASE_URL}templates/whatsapp_template/?template_name=${templateName}`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(parsedTemplateCode)
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setIsLoading(false)
                    if (data) {
                        toast.success("Template Added Successfully")
                    }
                    else{
                        toast.error('Error Occcured. Please try again');
                    }
                    setTimeout(()=>{
                        setOpenTemplateModal(false)
                    },1000)
                })
                .catch(error => {
                    setIsLoading(false)
                    toast.error('Error Occcured. Please try again');
                });
        }
      
    }




    return (
        <div>
            <Modal
                open={openTemplateModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='modalform_component'
            >
                <Box sx={style}>
                    {
                        <div className='settingUpdateBox'>
                            <p className='settingUpdateBoxheading'>Add WhatsApp API</p>

                            <TextField variant="outlined" id="outlined-basic" type='text' value={templateName} label='Template Name' className='form_input'
                                onChange={(e) => setTemplateName(e.target.value)}
                            />
                            {/* <textarea placeholder='API code'  className='form_input'    onChange={(e) => setTemplateCode(e.target.value)}
                            style={{ border:'1px solid #ccc' }}
                            ></textarea> */}

                            <div type='submit' style={{ textDecoration: "none", marginTop: "10px" }} onClick={AddTemplate} className='settingUpdateBoxButton'>
                                {
                                    !isLoading ?
                                        <span>Add API</span> :
                                        <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                                }
                            </div>
                        </div>
                    }

                </Box>
            </Modal>

            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: '',
                    duration: 2500,
                    style: {
                        background: 'white',
                        color: '#000',
                    },

                    success: {
                        duration: 2500,
                        theme: {
                            primary: 'green',
                            secondary: 'white',
                        },
                    },
                }}
            />
        </div>
    );
}
