// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StatsBoxComponent{
    width: 17.5vw;height: 8.5vw;display: flex;align-items: center;margin: 5px;background-color: var(--mainColor);
    flex-direction: column;justify-content: center;align-self: center;margin-left: 0px;margin-right: 0px;
    border: 1px solid var(--mainColor);border-radius: 5px;box-shadow: 0px 5px 15px rgba(0,0,0,0.15);
    opacity: 0.9;
}
.StatsBoxValue{
    color: white;display: flex;font-size: 18px;font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/Components/StatsBox/StatsBox.css"],"names":[],"mappings":"AAAA;IACI,aAAa,CAAC,aAAa,CAAC,aAAa,CAAC,mBAAmB,CAAC,WAAW,CAAC,kCAAkC;IAC5G,sBAAsB,CAAC,uBAAuB,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB;IACpG,kCAAkC,CAAC,kBAAkB,CAAC,yCAAyC;IAC/F,YAAY;AAChB;AACA;IACI,YAAY,CAAC,aAAa,CAAC,eAAe,CAAC,4EAA4E;AAC3H","sourcesContent":[".StatsBoxComponent{\n    width: 17.5vw;height: 8.5vw;display: flex;align-items: center;margin: 5px;background-color: var(--mainColor);\n    flex-direction: column;justify-content: center;align-self: center;margin-left: 0px;margin-right: 0px;\n    border: 1px solid var(--mainColor);border-radius: 5px;box-shadow: 0px 5px 15px rgba(0,0,0,0.15);\n    opacity: 0.9;\n}\n.StatsBoxValue{\n    color: white;display: flex;font-size: 18px;font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
