// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.apply_leave_heading{
    font-size: 18px;font-weight: bold;margin-left: 10px;color: var(--mainColor);
}
.date_and_reason{
    display: flex;flex-direction: column;align-items: center;
}
.reason_input{
    width: 24.4vw;padding: 10px;outline: none;border: 1px solid #222;height: 4vh;
}
.show_leaves_heading{
    font-size: 20px;font-weight: bold;cursor: pointer;
}
.show_leaves_heading:hover{
    scale: 1.05;transition-duration: 0.5s;
}
.leavesList{
    margin-left: 15px;margin-top: 25px;box-shadow: 0px 5px 15px rgba(0,0,0,0.1);padding: 10px;
    border-radius: 5px;height: 40vh;overflow-y: scroll;width: 50vw;margin-right: 4vw;
}
.leavesList::-webkit-scrollbar {
    width: 5px; 
}
.date_input::placeholder{
color: #666;
}
.date_input{
    outline: none !important;padding-left: 10px;
    border-color: #222 !important;width: 25vw;margin: 10px;height: 50px;
}
.leavesList::-webkit-scrollbar-track {
    background-color: #e7e2e2; 
}

.leavesList::-webkit-scrollbar-thumb {
    background-color: #888; 
}
.each_leave{
    border: 2px solid white;border-bottom-color: #aaa;
}
.leave_sub_heading{
    font-size: 15px;font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/Screens/ConsultantSettings/ConsultantSettings.css"],"names":[],"mappings":"AAAA;IACI,eAAe,CAAC,iBAAiB,CAAC,iBAAiB,CAAC,uBAAuB;AAC/E;AACA;IACI,aAAa,CAAC,sBAAsB,CAAC,mBAAmB;AAC5D;AACA;IACI,aAAa,CAAC,aAAa,CAAC,aAAa,CAAC,sBAAsB,CAAC,WAAW;AAChF;AACA;IACI,eAAe,CAAC,iBAAiB,CAAC,eAAe;AACrD;AACA;IACI,WAAW,CAAC,yBAAyB;AACzC;AACA;IACI,iBAAiB,CAAC,gBAAgB,CAAC,wCAAwC,CAAC,aAAa;IACzF,kBAAkB,CAAC,YAAY,CAAC,kBAAkB,CAAC,WAAW,CAAC,iBAAiB;AACpF;AACA;IACI,UAAU;AACd;AACA;AACA,WAAW;AACX;AACA;IACI,wBAAwB,CAAC,kBAAkB;IAC3C,6BAA6B,CAAC,WAAW,CAAC,YAAY,CAAC,YAAY;AACvE;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;AAC1B;AACA;IACI,uBAAuB,CAAC,yBAAyB;AACrD;AACA;IACI,eAAe,CAAC,iBAAiB;AACrC","sourcesContent":[".apply_leave_heading{\n    font-size: 18px;font-weight: bold;margin-left: 10px;color: var(--mainColor);\n}\n.date_and_reason{\n    display: flex;flex-direction: column;align-items: center;\n}\n.reason_input{\n    width: 24.4vw;padding: 10px;outline: none;border: 1px solid #222;height: 4vh;\n}\n.show_leaves_heading{\n    font-size: 20px;font-weight: bold;cursor: pointer;\n}\n.show_leaves_heading:hover{\n    scale: 1.05;transition-duration: 0.5s;\n}\n.leavesList{\n    margin-left: 15px;margin-top: 25px;box-shadow: 0px 5px 15px rgba(0,0,0,0.1);padding: 10px;\n    border-radius: 5px;height: 40vh;overflow-y: scroll;width: 50vw;margin-right: 4vw;\n}\n.leavesList::-webkit-scrollbar {\n    width: 5px; \n}\n.date_input::placeholder{\ncolor: #666;\n}\n.date_input{\n    outline: none !important;padding-left: 10px;\n    border-color: #222 !important;width: 25vw;margin: 10px;height: 50px;\n}\n.leavesList::-webkit-scrollbar-track {\n    background-color: #e7e2e2; \n}\n\n.leavesList::-webkit-scrollbar-thumb {\n    background-color: #888; \n}\n.each_leave{\n    border: 2px solid white;border-bottom-color: #aaa;\n}\n.leave_sub_heading{\n    font-size: 15px;font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
