import React, { useState, useContext } from 'react'
import './WhatsAppIntegration.css'
import Header from '../../Components/Header/Header';
import { MainContext } from '../../ContextStore/MainContext';
import toast, { Toaster } from 'react-hot-toast';
import TemplateModal from './TemplateModal'

import ReactLoading from 'react-loading';

export default function WhatsAppIntegration() {
    const [isTestWhatsApi, setIsTestWhatsAppApi] = useState({})
    const [apiCode, setApiCode] = useState('')
    const { BASE_URL, setOpenTemplateModal } = useContext(MainContext)
    const [isTestAPILoading, setIsTestAPILoading] = useState(false)
    const [isTemplateWorking, setIsTemplateWorking] = useState(false)
  


    function testAPI() {
        setIsTestAPILoading(true)
        localStorage.setItem('template_code',apiCode)
        fetch(`${BASE_URL}whatsapp_integration/test_whatsapp_api/`, {
            method: "POSt",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },

            body: apiCode
        })
            .then(response => {
                return response.json();
                
            })
            .then(data => {
                setIsTestAPILoading(false)
                if (data === "success") {
                    setIsTemplateWorking(true)
                    toast.success('Test is successful.Please check whatsApp for the message')
                }
                else {
                    toast.error('Invalid JSON')
                }
            })
            .catch((err) => {
                setIsTestAPILoading(false)
                toast.error('Error occured')
            })
    }

   


    return (
        <div>
            <Header />
            <p className='whatsappIntegrationHeading'>WhatsApp Integration</p>
            <div className='whatsappIntegration'>
                <p onClick={() => setIsTestWhatsAppApi(true)} className='testApiHeading'>Test WhatsApp Template</p>
                {
                    isTestWhatsApi &&
                    <div>
                        <textarea placeholder='Paste the whatsapp api code' onChange={(e) => setApiCode(e.target.value)} className='codetextArea'></textarea>

                        <div className='testApiButton' onClick={() => {
                            testAPI()
                        }}>
                            {
                                !isTestAPILoading ?
                                    <span>Test Template</span> :
                                    <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                            }
                        </div>
                    </div>
                }
            </div>


           {isTemplateWorking && <div className='template_and_image'>
                <div className='saveTemplate'>
                    <p className='disclaimer'><span style={{ color: 'red' }}>* </span>Save WhatsApp API in the database only after you receive the message on your WhatsApp number</p>
                    <p onClick={() => setOpenTemplateModal(true)} className='testApiButton' style={{ width: 'fit-content', marginTop: '10px' }}>Click here to Save WhatsApp API</p>

                </div>
            </div>}


            

            <TemplateModal />
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: '',
                    duration: 3500,
                    style: {
                        background: 'white',
                        color: '#000',
                    },

                    success: {
                        duration: 3500,
                        theme: {
                            primary: 'green',
                            secondary: 'white',
                        },
                    },
                }}
            />
        </div>
    )
}
