// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile_section{
    display: flex;justify-content: center;
}
.profile_sub_section{
    display: flex;flex-direction: column;margin-top: 120px;padding: 15px;
    width: 30vw;box-shadow: 0px 5px 15px rgba(0,0,0,0.1);border-radius: 10px;

}
.profile_option_and_icon{
    display: flex;align-items: center;justify-content: space-between;border:1px solid white;
    border-bottom-color: #ddd;padding-bottom: 2.5px;cursor: pointer;padding-top: 2.5px;
}
.profile_email{
    margin-top: 20px;
}
.profile_option{
    color: var(--mainColor);
}
.profile_icon{
    color: var(--mainColor);
}`, "",{"version":3,"sources":["webpack://./src/Screens/Profile/Profile.css"],"names":[],"mappings":"AAAA;IACI,aAAa,CAAC,uBAAuB;AACzC;AACA;IACI,aAAa,CAAC,sBAAsB,CAAC,iBAAiB,CAAC,aAAa;IACpE,WAAW,CAAC,wCAAwC,CAAC,mBAAmB;;AAE5E;AACA;IACI,aAAa,CAAC,mBAAmB,CAAC,8BAA8B,CAAC,sBAAsB;IACvF,yBAAyB,CAAC,qBAAqB,CAAC,eAAe,CAAC,kBAAkB;AACtF;AACA;IACI,gBAAgB;AACpB;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,uBAAuB;AAC3B","sourcesContent":[".profile_section{\n    display: flex;justify-content: center;\n}\n.profile_sub_section{\n    display: flex;flex-direction: column;margin-top: 120px;padding: 15px;\n    width: 30vw;box-shadow: 0px 5px 15px rgba(0,0,0,0.1);border-radius: 10px;\n\n}\n.profile_option_and_icon{\n    display: flex;align-items: center;justify-content: space-between;border:1px solid white;\n    border-bottom-color: #ddd;padding-bottom: 2.5px;cursor: pointer;padding-top: 2.5px;\n}\n.profile_email{\n    margin-top: 20px;\n}\n.profile_option{\n    color: var(--mainColor);\n}\n.profile_icon{\n    color: var(--mainColor);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
