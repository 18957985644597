// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.each_batch{
    width: 20vw;border: 1px solid #999;padding: 5px; border-radius: 5px;margin-top: 10px;
   
}
.batch_name,.batch_date,.batch_time,.instructor_name{
    line-height: 0.5;font-size: 14px;margin-top: 10px;margin-bottom: 10px;
}
.batch_name{
    color: var(--mainColor);font-weight: bold;line-height: 1;opacity: 0.9;
}
.batch_time{
    padding-left: 10px;
}
.batchbox_heading{
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/Components/BatchesBox/BatchesBox.css"],"names":[],"mappings":"AAAA;IACI,WAAW,CAAC,sBAAsB,CAAC,YAAY,EAAE,kBAAkB,CAAC,gBAAgB;;AAExF;AACA;IACI,gBAAgB,CAAC,eAAe,CAAC,gBAAgB,CAAC,mBAAmB;AACzE;AACA;IACI,uBAAuB,CAAC,iBAAiB,CAAC,cAAc,CAAC,YAAY;AACzE;AACA;IACI,kBAAkB;AACtB;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".each_batch{\n    width: 20vw;border: 1px solid #999;padding: 5px; border-radius: 5px;margin-top: 10px;\n   \n}\n.batch_name,.batch_date,.batch_time,.instructor_name{\n    line-height: 0.5;font-size: 14px;margin-top: 10px;margin-bottom: 10px;\n}\n.batch_name{\n    color: var(--mainColor);font-weight: bold;line-height: 1;opacity: 0.9;\n}\n.batch_time{\n    padding-left: 10px;\n}\n.batchbox_heading{\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
