// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.templateSection{
    display: flex;flex-direction: column;align-items: center;justify-content: center;
    border: 1px solid #888;padding: 15px;border-radius: 5px;width:23vw;padding-bottom: 30px;
}
.templateComponent{
    display: flex;justify-content: center;width: 100vw;margin-top: 40px;
}
.templateHeading{
    font-weight: bold;font-size: 20px;
}
.templateParams{
    margin-top: 15px;display: flex;flex-wrap: wrap;
}
.eachTemplateParamDiv{
    margin-top: 25px;
}
.eachTemplateParam{
    background-color: var(--mainColor);color: white;font-size: 13px;padding: 10px;border-radius: 5px;
    margin-right: 10px;margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Screens/TemplateForm/TemplateForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa,CAAC,sBAAsB,CAAC,mBAAmB,CAAC,uBAAuB;IAChF,sBAAsB,CAAC,aAAa,CAAC,kBAAkB,CAAC,UAAU,CAAC,oBAAoB;AAC3F;AACA;IACI,aAAa,CAAC,uBAAuB,CAAC,YAAY,CAAC,gBAAgB;AACvE;AACA;IACI,iBAAiB,CAAC,eAAe;AACrC;AACA;IACI,gBAAgB,CAAC,aAAa,CAAC,eAAe;AAClD;AACA;IACI,gBAAgB;AACpB;AACA;IACI,kCAAkC,CAAC,YAAY,CAAC,eAAe,CAAC,aAAa,CAAC,kBAAkB;IAChG,kBAAkB,CAAC,gBAAgB;AACvC","sourcesContent":[".templateSection{\n    display: flex;flex-direction: column;align-items: center;justify-content: center;\n    border: 1px solid #888;padding: 15px;border-radius: 5px;width:23vw;padding-bottom: 30px;\n}\n.templateComponent{\n    display: flex;justify-content: center;width: 100vw;margin-top: 40px;\n}\n.templateHeading{\n    font-weight: bold;font-size: 20px;\n}\n.templateParams{\n    margin-top: 15px;display: flex;flex-wrap: wrap;\n}\n.eachTemplateParamDiv{\n    margin-top: 25px;\n}\n.eachTemplateParam{\n    background-color: var(--mainColor);color: white;font-size: 13px;padding: 10px;border-radius: 5px;\n    margin-right: 10px;margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
