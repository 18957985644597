import React, {  useContext,useEffect } from 'react'
import MenuBar from '../../Components/MenuBar/MenuBar'
import './Home.css'
import { MainContext } from '../../ContextStore/MainContext';
import ModalForm from '../../Components/ModalForm/ModalForm';
import TableComponent from '../../Components/TableComponent/TableComponent';
import TableComponent2 from '../../Components/TableComponent/TableComponent2';

import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';


export default function Home() {
  const { role, openModal, selectedOption } = useContext(MainContext)
  const navigate=useNavigate()

  useEffect(() => {
    const decryptedSelectedOption = Cookies.get("selectedOption") ? CryptoJS.AES.decrypt(Cookies.get("selectedOption"), 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString(CryptoJS.enc.Utf8) : null;
    if(!decryptedSelectedOption){
        navigate('/')
    }
}, [])


  // function lead_management_table(SNo, Name, Email, Mobile, City, State, Course_intersested, Consultant_assigned, Lead_stage, Last_contacted) {
  //   return { SNo, Name, Email, Mobile, City, State, Course_intersested, Consultant_assigned, Lead_stage, Last_contacted };
  // }


  // const lead_management_rows = [
  //   lead_management_table(1, "Kumar", "kumar@gmail.comkumar@gmail.comkumar@gmail.com", "9874653210", "Hyd", "Telangana", "Data Science", "Raj", "Prospect", "Jan 22nd"),
  //   lead_management_table(2, "Pavan", "pavan@gmail.com", "8745631029", "Hyd", "Telangana", "Data Science", "Raj", "Prospect", "Jan 22nd"),
  //   lead_management_table(3, "Ravi", "ravi@gmail.com", "7896543210", "Hyd", "Telangana", "Data Science", "Raj", "Prospect", "Jan 22nd"),
  //   lead_management_table(4, "Ashok", "ashok@gmail.com", "8749653210", "Hyd", "Telangana", "Data Science", "Raj", "Prospect", "Jan 22nd"),
  //   lead_management_table(5, "Mani", "mani@gmail.com", "6789453720", "Hyd", "Telangana", "Data Science", "Raj", "Prospect", "Jan 22nd")
  // ];


  function consultant_management_table(Check, SNo, Org_ID, First_Name, Last_Name, Email, Mobile, Role) {
    return { Check, SNo, Org_ID, First_Name, Last_Name, Email, Mobile, Role };
  }

  const consultant_management_rows = [
    consultant_management_table("check", 1, "KSR", "Koti", "Reddy", "kotireddy@gmail.com", "9874653210", "Admin"),
    consultant_management_table("check", 2, "KSR", "Mahesh", "Reddy", "maheshreddy@gmail.com", "8745631029", "Admin"),
    consultant_management_table("check", 3, "KSR", "Phani", "Kumar", "phani@gmail.com", "7896543210", "Consultant"),
    consultant_management_table("check", 4, "KSR", "Raj", "Sekhar", "rajsekhar@gmail.com", "8749653210", "Consultant"),
    consultant_management_table("check", 5, "KSR", "Ravindra", "Reddy", "ravindra@gmail.com", "6789453720", "Consultant")
  ]

  return (
    <div className='home_component'>
      <div>
        <MenuBar />
      </div>

      <div className='table_and_buttons'>
        {
          (selectedOption === "Consultant Management") &&
          <TableComponent tableHeader={["Check", "SNo", "Org_ID", "First_Name", "Last_Name", "Email", "Mobile", "Role"]}
            tableRows={consultant_management_rows}
          />  
        }

        {
          selectedOption === "Lead Management" && <TableComponent2 />
        }

      </div>

      <ModalForm />

    </div>
  )
}
