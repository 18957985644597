import React,{useContext,useEffect} from 'react'
import './Profile.css'
import Header from '../../Components/Header/Header';
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from 'react-router-dom'
import { MainContext } from '../../ContextStore/MainContext'

import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';



export default function Profile() {

    const {role,setRole}=useContext(MainContext)
    useEffect(() => {
        const decryptedRole = Cookies.get("role") ? CryptoJS.AES.decrypt(Cookies.get("role"), 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString(CryptoJS.enc.Utf8) : null;
        setRole(decryptedRole)
        if (decryptedRole !== "admin" && decryptedRole !== "consultant") {
            navigate('/')
        }
    }, [])


    const navigate=useNavigate()
    return (
        <div>
            <Header />
            <div  className='profile_section'>
                <div className='profile_sub_section'>
                    <div>
                        <p className='profile_email'>Email : {localStorage.getItem('consultantEmail')}</p>
                        <p className='profile_email'>Mobile : {localStorage.getItem('consultantMobile')}</p>
                    </div>
                    <div className='profile_option_and_icon' onClick={()=>navigate('/reset-password')}>
                        <p className='profile_option'>Change Password</p>
                        <IoIosArrowForward className='profile_icon' />
                    </div>
                    {role==="consultant" && <div className='profile_option_and_icon'  onClick={()=>navigate('/consultant-settings')}>
                        <p className='profile_option'>Apply Leave</p>
                        <IoIosArrowForward className='profile_icon' />
                    </div>}
                    <div className='profile_option_and_icon'  onClick={()=>navigate('/')}>
                        <p className='profile_option'>Logout</p>
                        <IoIosArrowForward className='profile_icon' />
                    </div>
                </div>
            </div>
        </div>
    )
}
