// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_section_admin_homepage{
    position: fixed;width: 98vw;top: 0px;z-index: 100;display: flex;justify-content: space-between;
    box-shadow: 0px 5px 15px rgba(0,0,0,0.05);padding: 10px;padding-left: 40px;
    align-items: center;background-color: white;
}
.admin_homepage_header_options{
    display: flex;margin-right: 60px;
}
.admin_homepage_header_options>p{
padding: 10px 15px;background-color: var(--mainColor);color: white;margin: 10px;height: -moz-fit-content;height: fit-content;
cursor: pointer;border-radius: 5px;border: 1px solid var(--mainColor);
}
.admin_homepage_header_options>p:hover{
    background-color: white;color: var(--mainColor);transition-duration: 0.75s;
    border: 1px solid var(--mainColor);
}
.header_logo_admin_homepage{
    width: 8vw;height: 10vh;cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Components/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,eAAe,CAAC,WAAW,CAAC,QAAQ,CAAC,YAAY,CAAC,aAAa,CAAC,8BAA8B;IAC9F,yCAAyC,CAAC,aAAa,CAAC,kBAAkB;IAC1E,mBAAmB,CAAC,uBAAuB;AAC/C;AACA;IACI,aAAa,CAAC,kBAAkB;AACpC;AACA;AACA,kBAAkB,CAAC,kCAAkC,CAAC,YAAY,CAAC,YAAY,CAAC,wBAAmB,CAAnB,mBAAmB;AACnG,eAAe,CAAC,kBAAkB,CAAC,kCAAkC;AACrE;AACA;IACI,uBAAuB,CAAC,uBAAuB,CAAC,0BAA0B;IAC1E,kCAAkC;AACtC;AACA;IACI,UAAU,CAAC,YAAY,CAAC,eAAe;AAC3C","sourcesContent":[".header_section_admin_homepage{\n    position: fixed;width: 98vw;top: 0px;z-index: 100;display: flex;justify-content: space-between;\n    box-shadow: 0px 5px 15px rgba(0,0,0,0.05);padding: 10px;padding-left: 40px;\n    align-items: center;background-color: white;\n}\n.admin_homepage_header_options{\n    display: flex;margin-right: 60px;\n}\n.admin_homepage_header_options>p{\npadding: 10px 15px;background-color: var(--mainColor);color: white;margin: 10px;height: fit-content;\ncursor: pointer;border-radius: 5px;border: 1px solid var(--mainColor);\n}\n.admin_homepage_header_options>p:hover{\n    background-color: white;color: var(--mainColor);transition-duration: 0.75s;\n    border: 1px solid var(--mainColor);\n}\n.header_logo_admin_homepage{\n    width: 8vw;height: 10vh;cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
