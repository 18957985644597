import React, { useEffect, useState, useContext, Children } from "react";
import { Chart } from "react-google-charts";
import { MainContext } from "../../ContextStore/MainContext";
import './Charts.css'
// import ModalChart from "../ModalChart/ModalChart";


export default function MonthChart({ leadData }) {
  const [chartData, setChartData] = useState([]);
  const [chartData1, setChartData1] = useState([]);
  const { selectedYear, startDate, setStartDate, setSelectedYear, selectedMonth, setSelectedMonth, selectedConsultant, selectedCourse } = useContext(MainContext)


  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  useEffect(() => {
    // console.log(leadData);
    // if(selectedYear===""){
    //   setSelectedYear('2024')
    // }
    const leadCounts = {};
    let filteredData = leadData
    // .filter(lead=>(lead.lead_status!=='Lost' && lead.lead_status!=='Inactive' && lead.lead_status!=='Closed'));
    if (selectedConsultant) {
      filteredData = filteredData.filter(lead => lead.primary_consultant === selectedConsultant);
    }
    if (selectedCourse) {
      filteredData = filteredData.filter(lead => lead.course_interested === selectedCourse);
    }
    // if (selectedMonth) {
    //   filteredData = filteredData.filter(lead => {
    //     const leadDateMonth = new Date(lead.followup_date.split(" ")[0]).getMonth() + 1;
    //     return leadDateMonth === selectedMonth;
    //   });
    // }

    filteredData &&
    filteredData.forEach((lead) => {
      const date = new Date(lead.followup_date);
      const leadYear = date.getFullYear();
      const leadMonth = date.getMonth() + 1;
  
      if (leadYear === selectedYear) {
        const key = `${leadMonth < 10 ? '0' + leadMonth : leadMonth}`;
        if (leadCounts[key]) {
          leadCounts[key]++;
        } else {
          leadCounts[key] = 1;
        }
      }
    });
  
  const formattedData = Object.entries(leadCounts)
    .sort(([monthA], [monthB]) => parseInt(monthA) - parseInt(monthB)) 
    .map(([month, count]) => {
      return [
        `${monthNames[parseInt(month) - 1]}`,
        count,
        "#b87333"
      ];
    });
  
  setChartData([["Month", "Number of Leads", { role: "color" }], ...formattedData]);
  

  }, [selectedYear, selectedMonth, selectedConsultant, selectedCourse]);




  const chartOptions = {
    hAxis: {
      title: `Month`,
      titleTextStyle: {
        color: '#333',
        fontSize: 16,

      },
      textStyle: {
        fontSize: 14,
      },
    },
    vAxis: {
      title: 'Number of Leads',
      titleTextStyle: {
        color: '#333',
        fontSize: 16,
      },
      format: '0',
    },
    colors: ['#3366cc'],
  };




  // const handleSelect = (e) => {
  //   try {
  //     const selectedItem = chartData[e.chartWrapper.getChart().getSelection()[0].row + 1];
  //     if (selectedItem) {
  //       console.log(selectedItem);
  //       const xlabel = selectedItem[0];
  //       setSelectedMonth(xlabel)
  //       console.log(xlabel);
  //     }
  //   }
  //   catch (err) {
  //     console.log(err);
  //   }
  // };



  useEffect(() => {
    if (!selectedMonth) return;

    const leadCounts = {};
    let filteredData = leadData;
    if (selectedConsultant) {
      filteredData = filteredData.filter(lead => lead.primary_consultant === selectedConsultant);
    }
    if (selectedCourse) {
      filteredData = filteredData.filter(lead => lead.course_interested === selectedCourse);
    }
    try {
      filteredData && filteredData.forEach((lead) => {
        const date = new Date(lead.followup_date);
        const leadYear = date.getFullYear();
        const leadMonth = date.getMonth();

        if (leadYear === selectedYear && monthNames[leadMonth] === selectedMonth) {
          const key = `${date.getDate()}`;
          if (leadCounts[key]) {
            leadCounts[key]++;
          } else {
            leadCounts[key] = 1;
          }
        }
      });

      const formattedData = Object.entries(leadCounts).map(([date, count]) => {
        return [
          date + "-" + selectedMonth + "-" + selectedYear,
          count,
          "#b87333"
        ];
      });

      setChartData1([["Date", "Number of Leads", { role: "style" }], ...formattedData]);
    }
    catch (err) {
      console.log(err);
    }

  }, [selectedMonth, selectedConsultant, selectedCourse]);



  return (
    <div className="chartSection">
      {chartData.length > 1 ? (
        <Chart id="my-chart" chartType="ColumnChart" width="92vw" height="400px" data={chartData} options={chartOptions}
        // chartEvents={[
        //   {
        //     eventName: 'select',
        //     callback: handleSelect,
        //   },

        // ]} 
        />
      ) : <p style={{ padding: '10px' }}>No Data Found for Month Column Chart. Please Select the Year in filters</p>}
      {/* <ModalChart leadData={leadData}/> */}
    </div>
  );
}
