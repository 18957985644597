import React, { useState, useContext, useEffect } from 'react'
import './LeadPaymentDetails.css'
import MenuBar from '../../Components/MenuBar/MenuBar'
import Header from '../../Components/Header/Header'
import { MainContext } from '../../ContextStore/MainContext'
import toast, { Toaster } from 'react-hot-toast';
import PaymentModal from './PaymentModal'
import { useNavigate } from 'react-router-dom'
import ReactLoading from 'react-loading'
import Footer from '../../Components/FooterComponent/Footer'



export default function LeadPaymentDetails() {
    const {leadEditedData, setLeadEditedData, BASE_URL, setOpenPaymentModal, setSelectedSubOption, paymentDetails, setPaymentDetails } = useContext(MainContext)
    const [paymentActivity, setPaymentActivity] = useState([])
    const [isPaymentActivityFound, setIsPaymentActivityFound] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoading1, setIsLoading1] = useState(false)

    const [feeReceiptFile, setFeeReceiptFile] = useState(null)
    const [feeReceiptsList, setFeeReceiptsList] = useState([])

    useEffect(() => {
        const storedLeadData = localStorage.getItem("leadData");
        if (storedLeadData!=="undefined" && storedLeadData!==null) {
            setLeadEditedData(JSON.parse(localStorage.getItem("leadData")));
        }
    }, []);

    useEffect(() => {
        if (leadEditedData.email === "") {
            navigate('/lead-management')
        }
    }, [])
    
    const navigate = useNavigate()

    function submitFeeRecipt() {
        setIsLoading(true)

        if (!feeReceiptFile) {
            toast.error("Please Select file")
            setIsLoading(false)
            return
        }
        else {
            const formData = new FormData();
            formData.append('receipt_file', feeReceiptFile);
            // formData.append('lead_id', JSON.parse(localStorage.getItem('leadData')).lead_id);
            // const boundary = 'WebKitFormBoundary' + Math.random().toString(36).substring(2);
            fetch(`${BASE_URL}upload_fee_receipts/?lead_id=${JSON.parse(localStorage.getItem('leadData')).lead_id}`, {
                method: "POST",
                
                body: formData
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    console.log(data);
                    setIsLoading(false)
                    if (data.message==="File uploaded successfully") {
                        toast.success("Added Successfully")
                    }
                    else{
                        toast.error("Failed to upload")
                    }
                    setFeeReceiptFile(null)
                    // window.location.reload()
                })
                .catch(error => {
                    console.log(error);
                    setIsLoading(false)
                    toast.error('Server error. Please try again later...');
                });
        }
    }

    function retrieveReceipts() {
        setIsLoading1(true)
        fetch(`${BASE_URL}get_receipt_links/${JSON.parse(localStorage.getItem('leadData')).lead_id}`, {
            method: "GET",
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                setIsLoading1(false)
                if (data) {
                    // console.log(data);
                    setFeeReceiptsList(data)
                }
            })
            .catch(error => {
                setIsLoading1(false)
                toast.error('Server error. Please try again later...');
            });
    }

    useEffect(() => {
        const id = JSON.parse(localStorage.getItem("leadData"))?.lead_id
        id && fetch(`${BASE_URL}payment/payment_details?lead_id=${JSON.parse(localStorage.getItem("leadData"))?.lead_id}`, {
            method: 'GET'
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data.length === 0) {
                    // toast.error('No payment history found')
                }
                // console.log(data);
                setPaymentDetails(data)
            })
            .catch(error => {
                console.log(error);
            });


    }, [])

    useEffect(() => {
        const id = JSON.parse(localStorage.getItem("leadData"))?.lead_id
        try{
        id && fetch(`${BASE_URL}payment/payment_activity_history?lead_id=${id}`, { method: 'GET' })
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data.detail === "No lead history found") {
                    // toast.error("No lead history found")
                    setIsPaymentActivityFound(false)
                }
                else {
                    setPaymentActivity(data.reverse())
                }
            })
            .catch(error => {
                console.log(error);
                // toast.error('Server error. Please try again later...');
            });
        }
        catch(err){
            console.log(err);
        }
    }, [])

    return (
        <div>
            <Header />
            <div style={{ display: 'flex',width:"98.5vw",overflowX:"hidden" }}>
                {leadEditedData &&
                    <div className='lead_details_section' style={{ marginTop: "110px",width:'25vw',height:'75vh' }}>
                        <p className='page_heading' style={{marginTop:'0px'}}>Lead Details</p>
                            <div><p>First Name:</p> <p>{leadEditedData?.firstname}</p></div>
                            <div><p>Last Name: </p> <p>{leadEditedData?.lastname}</p></div>
                            <div><p>Email: </p> <p>{leadEditedData?.email}</p></div>
                            <div><p>Mobile: </p> <p>{leadEditedData?.mobile}</p></div>
                            <div><p>Primary Consult: </p><p>{leadEditedData?.primary_consultant}</p></div>
                            <div><p>FollowUp Date: </p><p>{leadEditedData?.followup_date}</p></div>
                            <div><p>Notes: </p><p>{leadEditedData?.notes}</p></div>
                            <div><p>Incorrect Lead: </p><p>{leadEditedData?.incorrect_lead_flag}</p></div>
                            <div><p>Incorrect Email: </p><p>{leadEditedData?.incorrect_email_flag}</p></div>
                            <div><p>Incorrect Mobile: </p><p>{leadEditedData?.incorrect_mobile_flag}</p></div>
                            <div><p>Lead Status: </p><p>{leadEditedData?.lead_status}</p></div>
                            <div><p>Lead Source: </p><p>{leadEditedData?.lead_source}</p></div>
                            <div><p>Course Interested: </p><p>{leadEditedData?.course_interested}</p></div>
                            <div><p>State: </p> <p>{leadEditedData?.state}</p></div>
                            <div><p>City: </p> <p>{leadEditedData?.city}</p></div>
                            <div><p>Gender: </p><p>{leadEditedData?.gender}</p></div>
                            <div><p>Age: </p><p>{leadEditedData?.age}</p></div>
                            <div><p>Highest Qualification: </p><p>{leadEditedData?.highest_qualification}</p></div>
                            <div><p>Year of Passing: </p><p>{leadEditedData?.year_of_passing}</p></div>
                            <div><p>Reason for taking course: </p><p>{leadEditedData?.reason_for_taking_this_course}</p></div>
                            <div><p>Occupation: </p><p>{leadEditedData?.occupation}</p></div>
                            <div><p>Date Enrolled: </p><p>{leadEditedData?.date_enrolled?.substring(0, 10)}</p></div>
                            {/* <div><p>Registered Date: </p><p>{leadEditedData?.enquiry_date?.substring(0, 10)}</p></div> */}
                            <div><p>Device: </p><p>{leadEditedData?.device}</p></div>
                            <div><p>Batch: </p><p>{leadEditedData?.batch}</p></div>
                            <div><p>Batch Start Date: </p><p>{leadEditedData?.batch_start_date?.substring(0, 10)}</p></div>
                            <div><p>Campaign: </p><p>{leadEditedData?.campaign}</p></div>
                            <div><p>Source URL: </p><p>{leadEditedData?.source_url}</p></div>
                            <div><p>Lead Medium: </p><p>{leadEditedData?.lead_medium}</p></div>
                            <div><p>Personal Info: </p><p>{leadEditedData?.level_1} - {leadEditedData?.level_2} - {leadEditedData?.level_3}</p></div>
                            <div><p>Secondary Assigned: </p><p>{leadEditedData?.secondary_consultant}</p></div>
                            <div><p>Escalation Consultant: </p><p>{leadEditedData?.escalation_consultant}</p></div>
                            <div><p>Escalation Notes: </p><p>{leadEditedData?.escalation_notes}</p></div>


                    </div>}

                <div style={{ marginTop: '100px', marginLeft: '2vw' }}>
                    <p className='payment_details_heading'>Payment Details</p>
                    <div>
                        {
                            paymentDetails.length > 0 && paymentDetails?.map((e, id) => {
                                return (
                                    <div key={id} className='total_payment_details'>
                                        <p className='each_payment_detail'><span className='payment_details_sub_heading'>Total Fees : </span>{e.total_fees}</p>
                                        <p className='each_payment_detail'><span className='payment_details_sub_heading'>Total Fees Due : </span>{e.total_fees_due}</p>
                                        <p className='each_payment_detail'><span className='payment_details_sub_heading'>Total Fees Paid : </span>{e.total_fees_paid}</p>
                                        <p className='each_payment_detail'><span className='payment_details_sub_heading'>Total Discount : </span>{e.discount}</p>
                                        <p className='each_payment_detail'><span className='payment_details_sub_heading'>Installment 1  : </span>{e.installment_1}</p>
                                        <p className='each_payment_detail'><span className='payment_details_sub_heading'>Installment 1 Date : </span>{e.installment_1_date}</p>
                                        <p className='each_payment_detail'><span className='payment_details_sub_heading'>Installment 2 : </span>{e.installment_2}</p>
                                        <p className='each_payment_detail'><span className='payment_details_sub_heading'>Installment 2 Date : </span>{e.installment_2_date}</p>
                                        <p className='each_payment_detail'><span className='payment_details_sub_heading'>Installment 3 : </span>{e.installment_3}</p>
                                        <p className='each_payment_detail'><span className='payment_details_sub_heading'>Installment 3 Date : </span>{e.installment_3_date}</p>
                                        <p className='each_payment_detail'><span className='payment_details_sub_heading'>Installment 4 : </span>{e.installment_4}</p>
                                        <p className='each_payment_detail'><span className='payment_details_sub_heading'>Installment 4 Date : </span>{e.installment_4_date}</p>
                                        <p className='each_payment_detail'><span className='payment_details_sub_heading'>Notes : </span>{e.notes}</p>
                                        <p className='each_payment_detail'><span className='payment_details_sub_heading'>Payment Remainder Date : </span>{e.payment_reminder_date}</p>

                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        paymentDetails.length === 0 && <p className='consultant_management_button' style={{ width: 'fit-content',marginTop:"5px" }}
                            onClick={() => {
                                setOpenPaymentModal(true)
                                setSelectedSubOption('Add Payment')
                            }}>Add Payment</p>
                    }
                    {
                        paymentDetails.length > 0 && <p className='consultant_management_button' style={{ width: 'fit-content',marginTop:"5px" }}
                            onClick={() => {
                                setOpenPaymentModal(true)
                                setSelectedSubOption('Update Payment')
                            }}>Update Payment</p>
                    }

                    <div className='form_input' style={{ width: "fit-content", display: "flex",alignItems:'center' }}>
                        <label htmlFor="fileInput" style={{ marginLeft: '-10px' }}>Upload Fee Receipt:  </label>
                        <input type="file" placeholder='fee receipt' accept=".pdf, .doc, .docx, .png, .jpg, .jpeg" onChange={(e) => setFeeReceiptFile(e.target.files[0])} />

                        {feeReceiptFile && <div className='form_submit_button' onClick={submitFeeRecipt} style={{marginTop:"0px",marginLeft:"25px",backgroundColor:'var(--mainColor)'}}>
                            {
                                !isLoading ?
                                    <span>Submit</span> :
                                    <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                            }
                        </div>}
                    </div>

                    <div className='form_submit_button' onClick={retrieveReceipts} style={{marginTop:"0px",backgroundColor:'var(--mainColor)'}}>
                            {
                                !isLoading1 ?
                                    <span>Retrieve Fee Receipts</span> :
                                    <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                            }
                        </div>
                        <div className='fee_receipts_list' style={{borderBottomColor:Object.keys(feeReceiptsList).length > 0?'#777':'white'}}>
                            {
                              feeReceiptsList && Object.keys(feeReceiptsList).length > 0 && Object.keys(feeReceiptsList).map((e,id)=>{
                                return(
                                    <p className='each_receipt' key={id} onClick={()=>window.open(feeReceiptsList[e],'_blank')}><span>{id+1} {")"} </span> {e}</p>
                                )
                               })
                            }
                        </div>


                    <div className='lead_activity_section' style={{marginTop:"20px",height:"38vh"}}>
                        <p className='lead_activity_heading'>Payment Activity</p>
                        {isPaymentActivityFound ? <div>

                            {
                                paymentActivity && paymentActivity.map((e, id) => {
                                    return (
                                        <div key={id} className='date_and_activity'>
                                            <p className='activity_date'><span>Date : </span> {e.modified_date?.substring(0, 10)}</p>
                                            <p className='activity_name'><span>Activity : </span> {e.activity}</p>
                                            <p className='activity_name'><span>Consultant : </span> {e.consultant_name && e.consultant_name}</p>
                                        </div>
                                    )
                                })
                            }
                        </div> : <p>No Payment Activity Found !</p>}
                    </div>


                </div>
            </div>

            <div style={{ marginTop: '90px', width: "100%", overflowX: 'hidden' }}>
                <Footer />
            </div>


            <PaymentModal />
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: '',
                    duration: 2500,
                    style: {
                        background: 'white',
                        color: '#000',
                    },

                    success: {
                        duration: 2500,
                        theme: {
                            primary: 'green',
                            secondary: 'white',
                        },
                    },
                }}
            />

        </div>
    )
}
