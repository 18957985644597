import React, { useContext } from 'react'
import './StatsBox.css'
import { useNavigate } from 'react-router-dom'
import { MainContext } from '../../ContextStore/MainContext'
import toast, { Toaster } from 'react-hot-toast';


export default function StatsBox({ statName, field,startDate,selectedMonth,selectedYear, value, backgroundColor, cursor }) {
  const navigate = useNavigate()
  const { setSelectedStat, setSelectedChartSource, setSelectedChartLocation, leadData1, setLeadData1,role } = useContext(MainContext)
  const primary_consultant = localStorage.getItem('consultantEmail')
  return (
    <div style={{ cursor: cursor, width: '15vw' }} className='StatsBoxComponent'
      onClick={() => {
        localStorage.setItem('selectedField', field)
        setSelectedStat(field)
        if (field === "New") {
          sessionStorage.setItem('leadStatusForData',"New")
          const newData = leadData1.filter(lead => lead.lead_status === "New");
          setLeadData1(newData.sort((a, b) => b.lead_id - a.lead_id));
          if (value !== 0) {
            navigate('/lead-management')
          }
          else {
            toast.error('No data found')
          }

        }
        else if (field === "Paid") {
          sessionStorage.setItem('leadStatusForData',"Paid")
          const newData = leadData1.filter(lead =>( lead?.lead_status === "Paid" ));
          setLeadData1(newData.sort((a, b) => b.lead_id - a.lead_id));
          if (value !== 0) {
            navigate('/lead-management')
          }
          else {
            toast.error('No data found')
          }

        }
        else if (field === "Followup") {
          sessionStorage.setItem('leadStatusForData',"Followup")
          sessionStorage.setItem('startDate',(startDate !== '' ? new Date(startDate).toDateString() : new Date().toDateString()))
          sessionStorage.setItem('selectedMonth',selectedMonth)
          sessionStorage.setItem('selectedYear',selectedYear)
          if(role==="admin"){
            const newData = leadData1.filter(lead => (
              lead?.lead_status === "Follow Up" && 
              (
                new Date(lead.followup_date).toDateString() === (startDate !== '' ? new Date(startDate).toDateString() : new Date().toDateString()) ||
                (selectedMonth && new Date(lead.followup_date).getMonth() + 1 === selectedMonth) ||
                (selectedYear && new Date(lead.followup_date).getFullYear() === selectedYear)
              )
            ));
            setLeadData1(newData.sort((a, b) => b.lead_id - a.lead_id));
          }
          if(role==="consultant"){
            const newData = leadData1.filter(lead => (
              lead?.lead_status === "Follow Up" && 
              lead.primary_consultant === primary_consultant && 
              (
                new Date(lead.followup_date).toDateString() === (startDate !== '' ? new Date(startDate).toDateString() : new Date().toDateString()) ||
                (selectedMonth && new Date(lead.followup_date).getMonth() + 1 === selectedMonth) ||
                (selectedYear && new Date(lead.followup_date).getFullYear() === selectedYear)
              )
            ));
            setLeadData1(newData.sort((a, b) => b.lead_id - a.lead_id));
          }
        
          if (value !== 0) {
            navigate('/lead-management')
          }
          else {
            toast.error('No data found')
          }

        }
        else if (field === "Closed" || field === "Lost" || field === "Inactive") {
          const newData = leadData1.filter(lead => (lead.lead_status === "Closed" || lead.lead_status === "Lost" || lead.lead_status === "Inactive"));
          setLeadData1(newData.sort((a, b) => b.lead_id - a.lead_id));
          if (value !== 0) {
            navigate('/lead-management')
          }
          else {
            toast.error('No data found')
          }

        }
        else if (field === "Payment") {
          sessionStorage.setItem('leadStatusForData',"Payment")
          const newData = leadData1.filter(lead => new Date(lead.payment_reminder_date).toDateString() === new Date().toDateString());
          setLeadData1(newData.sort((a, b) => b.lead_id - a.lead_id));
          if (value !== 0) {
            navigate('/lead-management')
          }
          else {
            toast.error('No data found')
          }

        }
        else if (field === "Total Leads") {
          sessionStorage.setItem('leadStatusForData',"Total")
          setLeadData1(leadData1.sort((a, b) => b.lead_id - a.lead_id));
          navigate('/lead-management')
        }
        setSelectedChartSource('')
        setSelectedChartLocation('')
      }}
    >
      <div className='StatsBoxValue'>{statName === "Fee Remainders" ? <><p style={{ marginRight: "10px", color: 'white' }}>Fee </p> <p style={{ color: 'white' }}> Remainders</p></>
        : statName}</div>
      <p className='StatsBoxValue'>{value}</p>

      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: '',
          duration: 2500,
          style: {
            background: 'white',
            color: '#000',
          },

          success: {
            duration: 2500,
            theme: {
              primary: 'green',
              secondary: 'white',
            },
          },
        }}
      />
    </div>
  )
}
