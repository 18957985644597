import React, { useContext, useState, useEffect } from 'react';
import { DataGrid, GridToolbarFilterButton, GridFilterInputValue, GridFilterInputSingleSelect } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { MainContext } from '../../ContextStore/MainContext';
import './TableComponent.css'

import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import DatePicker from 'react-datepicker';
import { FaArrowUp } from "react-icons/fa";
import Footer from '../FooterComponent/Footer';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


// ngrok config add-authtoken 2kMLINnZAH5AE0KIXl4ycw1HViv_3H922WGzRQquC7nsvA4PY



export default function TableComponent2({ courses, status, leadSource }) {
    const primary_consultant = localStorage.getItem('consultantEmail')

    const [leadList, setleadList] = useState([])
    const [isLoading, setIsloading] = useState(false)
    const [searchvalue, setSearchValue] = useState('')
    const [searchDate, setSearchDate] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [searchLeadSource, setSearchLeadSource] = useState('')
    const [loading, setLoading] = useState(false)
    const [searchedCourse, setSearchedCourse] = useState('')

    const { setRole, role, setOpenModal, setSelectedSubOption, setLeadEmailsList, setLeadMobilesList, setLeadDetails, BASE_URL,
        rowDetails, setRowDetails, leadEditedData, setLeadEditedData, selectedStat, selectedChartSource,
        selectedYear, selectedMonth, selectedConsultant, selectedCourse, leadData1, setLeadData1, setSelectedYear,
        genderList, setGenderList,
        ageList, setAgeList,
        courseInterested, setCourseInterested,
        leadStatusList, setLeadStatusList,
        leadSourceList, setLeadSourceList,
        campaignList, setCampaignList,
    } = useContext(MainContext)
    const [isChecked, setIsChecked] = useState(false)


    // const customFilterOperators = [
    //     {
    //       label: 'Contains',
    //       value: 'contains',
    //       getApplyFilterFn: (filterItem) => {
    //         console.log("filterItem",filterItem);
    //         if (!filterItem.columnField || !filterItem.value) {
    //           return null;
    //         }
    //         return ( {value} ) => {
    //             console.log("value",value);
    //           return value?.toString().includes(filterItem.value.toString());
    //         };
    //       },
    //       InputComponent: GridFilterInputSingleSelect,
    //     },
    //     {
    //       label: 'Is',
    //       value: 'is',
    //       getApplyFilterFn: (filterItem) => {
    //         if (!filterItem.columnField || !filterItem.value) {
    //           return null;
    //         }
    //         return ({ value }) => {
    //             console.log("value",value);
    //           return value?.toString() === filterItem.value.toString();
    //         };
    //       },
    //       InputComponent: GridFilterInputSingleSelect,
    //     },
    //     {
    //       label: 'Is not',
    //       value: 'isNot',
    //       getApplyFilterFn: (filterItem) => {
    //         if (!filterItem.columnField || !filterItem.value) {
    //           return null;
    //         }
    //         return ({ value }) => {
    //           return value?.toString() !== filterItem.value.toString();
    //         };
    //       },
    //       InputComponent: GridFilterInputSingleSelect,
    //     }
    //   ];

    const columns = [
        // { field: 'lead_id', headerName: 'Lead ID', width: 70 },
        // { field: 'sno', headerName: 'SNo', width: 70 },
        // { field: 'priority', headerName: 'Priority', width: 130 },
        { field: 'firstname', headerName: 'First Name', width: 130 },
        // { field: 'lastname', headerName: 'Last Name', width: 130 },
        { field: 'email', headerName: 'Email', width: 130 },
        { field: 'mobile', headerName: 'Contact Number', width: 130 },
        { field: 'primary_consultant', headerName: 'Primary Consultant', width: 130 },
        // { field: 'followup_date', headerName: 'FollowUp Date(yyyy-mm-dd)', width: 150 },
        { field: 'notes', headerName: 'Notes', width: 125 },
        {
            field: 'lead_status', headerName: 'Lead Status', width: 130, editable: true,
            type: "singleSelect",
            valueOptions: status?.map((e) => e?.lead_status)
        },
        // { field: 'secondary_consultant', headerName: 'Secondary Consultant', width: 130 },
        {
            field: 'course_interested', headerName: 'Course Interested', width: 130, editable: true,
            type: "singleSelect",
            valueOptions: courses?.map((e) => e?.course_interested),
            // filterOperators: customFilterOperators,
            filterable: true,

        },
        {
            field: 'lead_source', headerName: 'Lead Source', width: 130, editable: true,
            type: "singleSelect",
            valueOptions: leadSource?.map((e) => e?.lead_source),

        },
        // { field: 'age', headerName: 'Age', width: 90 },
        // { field: 'batch', headerName: 'Batch', width: 130 },
        // { field: 'batch_start_date', headerName: 'Batch Start Date(yyyy-mm-dd)', width: 150 },
        // { field: 'campaign', headerName: 'Campaign', width: 130 },
        // { field: 'city', headerName: 'City', width: 130 },
        // { field: 'state', headerName: 'State', width: 130 },
        // { field: 'date_enrolled', headerName: 'Date Enrolled(yyyy-mm-dd)', width: 130 },
        // { field: 'device', headerName: 'Device', width: 130 },
        // { field: 'fees_due', headerName: 'Fees Due', width: 130 },
        // { field: 'fees_paid', headerName: 'Fees Paid', width: 130 },
        // { field: 'gender', headerName: 'Gender', width: 90 },
        // { field: 'payment_date', headerName: 'Payment Date', width: 130 },
        // { field: 'total_fees', headerName: 'Total Fees', width: 130 },
    ];


    const navigate = useNavigate();

    const sortByPriorityAndDate = (a, b) => {

        const priorityOrder = {
            "Very High": 5,
            "High": 4,
            "Medium": 3,
            "Low": 2,
            "Very Low": 1
        };

        const priorityComparison = priorityOrder[b.priority] - priorityOrder[a.priority];

        if (priorityComparison !== 0) {
            return priorityComparison;
        }

        const dateA = new Date(a.followup_date);
        const dateB = new Date(b.followup_date);

        return dateA - dateB;
    };

    useEffect(() => {
        setRowDetails([])
        const decryptedRole = Cookies.get("role") ? CryptoJS.AES.decrypt(Cookies.get("role"), 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString(CryptoJS.enc.Utf8) : null;
        setRole(decryptedRole)

        const extractedEmails = leadData1?.map((item) => item.email);
        const extractedMobiles = leadData1?.map((item) => item.mobile);
        setLeadEmailsList(extractedEmails)
        setLeadMobilesList(extractedMobiles)

    }, []);


    useEffect(() => {
        genderList.length === 0 && fetch(`${BASE_URL}custom_settings/gender`)
            .then(response => {
                if (!response.ok) throw new Error('Failed to fetch data');
                return response.json();
            })
            .then(data => {
                setGenderList(data)
                localStorage.setItem("gender_list", JSON.stringify(data))
            })
            .catch(error => {
                console.log(error);
            });



        ageList.length === 0 && fetch(`${BASE_URL}custom_settings/age`)
            .then(response => {
                return response.json();
            })
            .then(data => {
                setAgeList(data)
                localStorage.setItem("age_list", JSON.stringify(data))
            })
            .catch(error => {
                console.log(error);
            });



        courseInterested.length === 0 && fetch(`${BASE_URL}custom_settings/course_interested`)
            .then(response => {
                if (!response.ok) throw new Error('Failed to fetch data');
                return response.json();
            })
            .then(data => {
                setCourseInterested(data)
                localStorage.setItem("course_interested", JSON.stringify(data))
            })
            .catch(error => {
                console.log(error);
            });




        leadStatusList.length === 0 && fetch(`${BASE_URL}custom_settings/lead_status`)
            .then(response => {
                if (!response.ok) throw new Error('Failed to fetch data');
                return response.json();
            })
            .then(data => {
                setLeadStatusList(data)
                localStorage.setItem("lead_status", JSON.stringify(data))
            })
            .catch(error => {
                console.log(error);
            })


        leadSourceList.length === 0 && fetch(`${BASE_URL}custom_settings/lead_source`)
            .then(response => {
                if (!response.ok) throw new Error('Failed to fetch data');
                return response.json();
            })
            .then(data => {
                setLeadSourceList(data)
                localStorage.setItem("lead_source", JSON.stringify(data))
            })
            .catch(error => {
                console.log(error);
            });



        campaignList.length === 0 && fetch(`${BASE_URL}custom_settings/campaign`)
            .then(response => {
                if (!response.ok) throw new Error('Failed to fetch data');
                return response.json();
            })
            .then(data => {
                setCampaignList(data)
                localStorage.setItem("campaign", JSON.stringify(data))
            })
            .catch(error => {
                console.log(error);
            });

    }, [])



    const filteredLeads = leadData1.filter(lead => {
        const { followup_date, email, mobile, firstname } = lead;

        if (startDate && endDate) {
            const searchStartDateObject = new Date(startDate);
            const searchEndDateObject = new Date(endDate);
            const leadDateObject = new Date(followup_date);

            if (leadDateObject >= searchStartDateObject && leadDateObject <= searchEndDateObject.setDate(searchEndDateObject.getDate() + 1)) {
                return (email.toLowerCase().includes(searchvalue.toLowerCase()) || firstname.toLowerCase().includes(searchvalue.toLowerCase()) ||
                    mobile.includes(searchvalue))
            }
        } else {
            return (email.toLowerCase().includes(searchvalue.toLowerCase()) || firstname.toLowerCase().includes(searchvalue.toLowerCase()) ||
                mobile.includes(searchvalue))
        }
        return false;
    });


    const filteredLeads1 = leadData1.filter(lead => {
        const { followup_date, email, firstname, mobile, primary_consultant } = lead;

        if ((startDate && endDate) || searchvalue) {
            if (startDate && endDate) {
                const searchStartDateObject = new Date(startDate);
                const searchEndDateObject = new Date(endDate);
                const leadDateObject = new Date(followup_date);

                if (leadDateObject >= searchStartDateObject && leadDateObject <= searchEndDateObject.setDate(searchEndDateObject.getDate() + 1)) {
                    return email.toLowerCase().includes(searchvalue.toLowerCase()) || firstname.toLowerCase().includes(searchvalue.toLowerCase()) ||
                        mobile.includes(searchvalue);
                }
            } else {
                return email.toLowerCase().includes(searchvalue.toLowerCase()) || firstname.toLowerCase().includes(searchvalue.toLowerCase()) ||
                    mobile.includes(searchvalue);
            }
        }
        else {
            return primary_consultant === localStorage.getItem('consultantEmail')
        }
        return false;
    });

    useEffect(() => {
        try {
            setLoading(true)

            leadData1.length == 0 && fetch(`${BASE_URL}lead/leads`)
                .then(response => response.json())
                .then(data => {
                    const leadStatusForData = sessionStorage.getItem('leadStatusForData')
                    if (leadStatusForData === "Total") {
                        setLeadData1(data.sort((a, b) => b.lead_id - a.lead_id))
                    }
                    else if (leadStatusForData === "Payment") {
                        const newData = data.filter(lead => new Date(lead.payment_reminder_date).toDateString() === new Date().toDateString());
                        setLeadData1(newData.sort((a, b) => b.lead_id - a.lead_id));
                    }
                    else if (leadStatusForData === "Followup") {
                        const startDate = sessionStorage.getItem('startDate')
                        const selectedMonth = sessionStorage.getItem('selectedMonth')
                        const selectedYear = sessionStorage.getItem('selectedYear')
                        const decryptedRole = Cookies.get("role") ? CryptoJS.AES.decrypt(Cookies.get("role"), 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString(CryptoJS.enc.Utf8) : null;

                        if (decryptedRole === "admin") {
                            const newData = data.filter(lead => (
                                lead?.lead_status === "Follow Up" &&
                                (
                                    new Date(lead.followup_date).toDateString() === (startDate !== '' ? new Date(startDate).toDateString() : new Date().toDateString()) ||
                                    (selectedMonth && new Date(lead.followup_date).getMonth() + 1 === selectedMonth) ||
                                    (selectedYear && new Date(lead.followup_date).getFullYear() === selectedYear)
                                )
                            ));

                            setLeadData1(newData.sort((a, b) => b.lead_id - a.lead_id));
                        }
                        else if (decryptedRole === "consultant") {

                            const newData = data.filter(lead => (
                                lead?.lead_status === "Follow Up" &&
                                lead.primary_consultant === primary_consultant &&
                                (
                                    new Date(lead.followup_date).toDateString() === (startDate !== '' ? new Date(startDate).toDateString() : new Date().toDateString()) ||
                                    (selectedMonth && new Date(lead.followup_date).getMonth() + 1 === selectedMonth) ||
                                    (selectedYear && new Date(lead.followup_date).getFullYear() === selectedYear)
                                )
                            ));
                            setLeadData1(newData.sort((a, b) => b.lead_id - a.lead_id));
                        }
                    }
                    else {
                        const filterData = data.filter(lead => (lead.lead_status === sessionStorage.getItem('leadStatusForData')))
                        setLeadData1(filterData.sort((a, b) => b.lead_id - a.lead_id))
                    }
                    setLoading(false)
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false)
                })
        }
        catch (err) {
            console.log(err);
            setLoading(false)
        }



    }, [])



    useEffect(() => {
        const decryptedRole = Cookies.get("role") ? CryptoJS.AES.decrypt(Cookies.get("role"), 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString(CryptoJS.enc.Utf8) : null;
        setRole(decryptedRole)
        if (decryptedRole !== "admin" && decryptedRole !== "consultant") {
            navigate('/')
        }
    }, [])

    function deleteLead() {
        setIsloading(true)
        const decryptedEmail = Cookies.get("email") ? CryptoJS.AES.decrypt(Cookies.get("email"), 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString(CryptoJS.enc.Utf8) : null;

        decryptedEmail && fetch(`${BASE_URL}lead/consultants/{consultant_id}?lead_id=${leadEditedData[0].lead_id}&deleted_by=${decryptedEmail}`, {
            method: "DELETE"
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data) {
                    setIsloading(false)
                    // toast.success("Deleted Successfully")
                    window.location.reload()
                }
            })
            .catch(error => {
                setIsloading(false)
                alert('Server error. Please try again later...');
            });
    }

    const addSnoToData = (data) => {
        return data.map((item, index) => ({
            ...item,
            sno: index + 1,
        }));
    };

    const getRowClassName = (params) => {
        return 'tableRow';
    };



    const [filterModel, setFilterModel] = useState(() => {
        const savedFilterModel = localStorage.getItem('filterModel');
        try {
          const parsedFilterModel = savedFilterModel ? JSON.parse(savedFilterModel) : { items: [] };
          return Array.isArray(parsedFilterModel.items) ? parsedFilterModel : { items: [] };
        } catch (error) {
          console.error('Failed to parse filter model from local storage', error);
          return { items: [] };
        }
      });
    
      const handleFilterModelChange = (newFilterModel) => {
        const validatedFilterModel = newFilterModel && Array.isArray(newFilterModel.items)
          ? newFilterModel
          : { items: [] };
        setFilterModel(validatedFilterModel);
        localStorage.setItem('filterModel', JSON.stringify(validatedFilterModel));
      };

    return (
        <div>
            <div className='lead_heading_and_filters'>
                <p className='page_heading'>Lead Management</p>
                <div className='search_section'>

                    {/* <FormControl  >
                  <InputLabel id="demo-simple-select-label" style={{marginTop:"-12px",fontSize:"15px"}}>Course</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={searchedCourse}
                    label="Course"
                    className='search_input'
                    style={{width:"20vw",}} 
                    onChange={(e) => setSearchedCourse(e.target.value)}
                  >
                    {
                      localStorage.getItem('course_interested') && JSON.parse(localStorage.getItem('course_interested')).map((e, id) => {
                        return (
                          <MenuItem value={e.course_interested} key={id} style={{color:"black !important",fontSize:"12px !important",width:"20vw"}}>{e.course_interested}</MenuItem>
                        )
                      })
                    }

                  </Select>
                </FormControl> */}
                <div>
                    {
                        localStorage.getItem('filterModel') && <p className='clearFiltersButton' onClick={()=>{
                            localStorage.removeItem('filterModel');window.location.reload()}}> Clear filter</p>
                    }
                </div>

                    <DatePicker
                        selected={startDate}
                        onChange={(date) => { setStartDate(date) }}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Start Date"
                        className='search_input'
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                    />

                    <DatePicker
                        selected={endDate}
                        onChange={(date) => { setEndDate(date) }}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="End Date"
                        className='search_input'
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                    />
                    {<input placeholder='Email/Mobile/Name' className='search_input' onChange={(e) => setSearchValue(e.target.value)}></input>}
                </div>
            </div>


            <div className='data_grid'>
                <DataGrid
                    rows={addSnoToData(role === "admin" ? filteredLeads : filteredLeads1)}
                    getRowId={(row) => row.lead_id}
                    components={{
                        NoRowsOverlay: () => (
                            <div style={{ width: '100%', textAlign: 'center', padding: '20px', marginTop: "50px" }}>
                                <span style={{ fontWeight: '500', fontSize: '18px' }}>{loading ? 'Loading...' : 'No data found'}</span>
                            </div>
                        ),
                    }}
                    filterModel={filterModel}
                    onFilterModelChange={handleFilterModelChange}

                    columns={[
                        {
                            field: 'sno',
                            headerName: 'SNo',
                            width: 70,
                            renderCell: (params) => {
                                return (
                                    <div>
                                        {params.value}
                                    </div>
                                );
                            },
                        },

                        {
                            field: 'priority',
                            headerName: 'Priority',
                            width: 120,
                            editable: true,
                            type: "singleSelect",
                            valueOptions: ['Very High', 'High', 'Medium', 'Low', 'Very Low'],
                            renderCell: (params) => {
                                const { row } = params;
                                const flagColorMap = {
                                    "Very High": 'rgb(237, 71, 71)',
                                    "High": '#ff8000',
                                    "Medium": '#ffff00',
                                    "Low": '#00ff00',
                                    "Very Low": 'lightblue'
                                };
                                const flagStyle = {
                                    color: flagColorMap[row.priority] || 'transparent',
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '2px',
                                    position: 'absolute',
                                    bottom: '20px',
                                    right: '20px'
                                };
                                return (
                                    <div style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <p>{params.value}</p>
                                        <div style={flagStyle}><FaArrowUp /></div>
                                    </div>
                                );
                            },
                        },

                        ...columns,
                        {
                            field: 'followup_date',
                            headerName: 'FollowUp Date(yyyy-mm-dd)',
                            width: 150,
                            renderCell: (params) => {
                                return (
                                    <div>
                                        {/* <p>{params.value}</p> */}
                                        {params.row.lead_status === 'Follow Up' ? params.value : "-"}
                                    </div>
                                );
                            },
                        },
                    ]}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                        // filter: {
                        //     filterModel,
                        //   },
                    }}
                    pageSizeOptions={[10, 20]}
                    checkboxSelection
                    onRowSelectionModelChange={(rowId) => {
                        setIsChecked(!isChecked)
                        setRowDetails(rowId)
                        const selectedData = leadData1.filter(row => rowId.includes(row.lead_id));
                        setLeadEditedData(selectedData);

                    }}
                    onRowClick={(e) => {
                        setLeadEditedData(e.row)
                        localStorage.setItem("leadData", JSON.stringify(e.row))
                        navigate("/lead-details")
                    }}
                    getRowClassName={getRowClassName}
                    sx={{
                        '& .MuiDataGrid-columnHeader': { backgroundColor: 'var(--mainColor)', color: 'white' },
                        '& .MuiButtonBase-root': {
                            color: 'white',
                        },
                        '& .MuiButtonBase-root svg': {
                            color: '#333',
                        },
                    }}


                />



                {<div className='consultant_management_buttons'>
                    <p className='consultant_management_button'
                        onClick={() => {
                            setSelectedSubOption("Add New Lead")
                            setOpenModal(true)
                        }}>Add New Lead</p>

                    {rowDetails && rowDetails.length === 1 &&
                        <div style={{ display: 'flex', marginTop: '2px' }}>

                            <div className='consultant_management_button' onClick={() => {
                                setSelectedSubOption("Archieve Lead")
                                deleteLead()
                            }} style={{ height: 'fit-content', marginTop: "15px" }}>
                                {
                                    !isLoading ?
                                        <span>Archieve Lead</span> :
                                        <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                                }
                            </div>

                        </div>
                    }

                    {rowDetails && rowDetails.length == 1 &&
                        <p className='consultant_management_button' style={{ width: "fit-content" }}
                            onClick={() => {
                                navigate("/contact-lead")
                            }}>Contact Lead</p>
                    }
                    <div className='disclaimer_section'>
                        <p className='each_disclaimer'><span style={{ color: 'red' }}>*</span> Click on any lead to see complete details</p>
                        <p className='each_disclaimer' style={{ marginLeft: "10px" }}><span style={{ color: 'red' }}>*</span> Select multiple/Single lead to contact/send details</p>
                        <p className='each_disclaimer' style={{ marginLeft: "10px" }}><span style={{ color: 'red' }}>*</span> Hover the cursor over column heading and click three dots icon to get filters</p>

                    </div>

                </div>}



            </div>


        </div>
    );
}


