// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.reset_password_component{
    display: flex;justify-content: center;margin-top: 50px;
}

.reset_password_section{
    border:1px solid #ccc; width: 40vw;
}
.reset_padsword_heading{
    font-size: 30px; font-weight: bold; margin-top: 2px; text-align: center;
}

.rp_input{
    border: none;  outline: none;font-size: 16px; padding:10px 5px 10px 15px; border-color: white;
}
.input_label{
    font-size: 17px;
}

.input_and_icon{
    border: 1px solid white; border-bottom-color: #aaa; padding-left:15px;
    display: flex; align-items: center;margin-bottom: 30px;
}
::placeholder { 
    color: #ccc;
  }
.rp_input:focus{
    outline: none;
}
.rp_icon{
    font-size: 20px;color: var(--mainColor);
}
.submit_button_section{
   background-color: #333; color: white; text-align: center;  padding: 10px; cursor: pointer; border-radius: 10px;
   margin-top: 10px;
}





`, "",{"version":3,"sources":["webpack://./src/Screens/Resetpassword/Resetpassword.css"],"names":[],"mappings":";AACA;IACI,aAAa,CAAC,uBAAuB,CAAC,gBAAgB;AAC1D;;AAEA;IACI,qBAAqB,EAAE,WAAW;AACtC;AACA;IACI,eAAe,EAAE,iBAAiB,EAAE,eAAe,EAAE,kBAAkB;AAC3E;;AAEA;IACI,YAAY,GAAG,aAAa,CAAC,eAAe,EAAE,0BAA0B,EAAE,mBAAmB;AACjG;AACA;IACI,eAAe;AACnB;;AAEA;IACI,uBAAuB,EAAE,yBAAyB,EAAE,iBAAiB;IACrE,aAAa,EAAE,mBAAmB,CAAC,mBAAmB;AAC1D;AACA;IACI,WAAW;EACb;AACF;IACI,aAAa;AACjB;AACA;IACI,eAAe,CAAC,uBAAuB;AAC3C;AACA;GACG,sBAAsB,EAAE,YAAY,EAAE,kBAAkB,GAAG,aAAa,EAAE,eAAe,EAAE,mBAAmB;GAC9G,gBAAgB;AACnB","sourcesContent":["\n.reset_password_component{\n    display: flex;justify-content: center;margin-top: 50px;\n}\n\n.reset_password_section{\n    border:1px solid #ccc; width: 40vw;\n}\n.reset_padsword_heading{\n    font-size: 30px; font-weight: bold; margin-top: 2px; text-align: center;\n}\n\n.rp_input{\n    border: none;  outline: none;font-size: 16px; padding:10px 5px 10px 15px; border-color: white;\n}\n.input_label{\n    font-size: 17px;\n}\n\n.input_and_icon{\n    border: 1px solid white; border-bottom-color: #aaa; padding-left:15px;\n    display: flex; align-items: center;margin-bottom: 30px;\n}\n::placeholder { \n    color: #ccc;\n  }\n.rp_input:focus{\n    outline: none;\n}\n.rp_icon{\n    font-size: 20px;color: var(--mainColor);\n}\n.submit_button_section{\n   background-color: #333; color: white; text-align: center;  padding: 10px; cursor: pointer; border-radius: 10px;\n   margin-top: 10px;\n}\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
