import React, { useState } from 'react'
import './TemplateForm.css'
import TextField from '@mui/material/TextField';
import ReactLoading from 'react-loading'
import toast, { Toaster } from 'react-hot-toast';



export default function TemplateForm() {

  const [campaignName, setCampaignName] = useState('')
  const [mediaUrl, setMediaUrl] = useState('')
  const [templateParams, setTemplateParams] = useState([])
  const [eachTemplateParam, setEachTemplateParam] = useState('')
  const [isLoading, setIsloading] = useState(false)

  function submitTemplate() {
    setIsloading(true)
    toast.success('Success')
  }
  function AddTemplateparam() {
    if(eachTemplateParam){
      setTemplateParams(prev => [...prev, eachTemplateParam])
      setEachTemplateParam('')
    }
    else{
      toast.error('Enter Template param')
    }
  }
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      AddTemplateparam();
    }
  };


  return (
    <div className='templateComponent'>

      <div className='templateSection'>
        <p className='templateHeading'>Template Form</p>
        <TextField variant="outlined" id="outlined-basic" type='text' value={campaignName} label='Campaign Name' className='form_input'
          onChange={(e) => setCampaignName(e.target.value)}
        />
        <TextField variant="outlined" id="outlined-basic" type='text' value={mediaUrl} label='Media Url' className='form_input'
          onChange={(e) => setMediaUrl(e.target.value)}
        />

        <TextField variant="outlined" id="outlined-basic" type='text' value={eachTemplateParam} label='Add template Param' className='form_input'
          onChange={(e) => setEachTemplateParam(e.target.value)}  onKeyPress={handleKeyPress}
        />
        <div className='form_submit_button' onClick={() => AddTemplateparam()}>
          {
            !isLoading ?
              <span>Add Param</span> :
              <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
          }
        </div>

        <div className='templateParams'>
          {
            templateParams.length > 0 && templateParams.map((e, id) => {
              return (
                <div key={id} className='eachTemplateParamDiv'>
                  <span className='eachTemplateParam'>{e}</span>
                </div>
              )
            })
          }
        </div>




        <div className='form_submit_button' onClick={submitTemplate} style={{ marginTop: "40px" }}>
          {
            !isLoading ?
              <span>Submit</span> :
              <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
          }
        </div>
      </div>

      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: '',
          duration: 2500,
          style: {
            background: 'white',
            color: '#000',
          },

          success: {
            duration: 2500,
            theme: {
              primary: 'green',
              secondary: 'white',
            },
          },
        }}
      />
    </div>
  )
}
