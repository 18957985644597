import React, { useState, useContext } from 'react';
import './Resetpassword.css'
import { useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import toast, { Toaster } from 'react-hot-toast';
import { MainContext } from '../../ContextStore/MainContext';
import { FaMobileAlt } from "react-icons/fa";
import Footer from '../../Components/FooterComponent/Footer';

export default function ResetEmail() {
    const { BASE_URL } = useContext(MainContext)
    const [mobile, setMobile] = useState('')
    const [isLoading, setisLoading] = useState(false)
    const [email, setEmail] = useState("")
    const navigate = useNavigate()


    function getEmail() {
        setisLoading(true)
        if (mobile.length < 10) {
            toast.error("Invalid Phone Number")
            setisLoading(false)
            return
        }

        const details = {
            mobile: mobile
        }

        fetch(`${BASE_URL}user/forgot_email`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },

            body: JSON.stringify(details)
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data.detail) {
                    toast.error(data.detail)
                }
                else {
                    setEmail(data)
                }
                setisLoading(false);
            })
            .catch(error => {
                setisLoading(false)
                toast.error('Server error. Please try again later...')
            });

    }


    return (

        <div >
            <div className='landing_page_header'>
                <img src='/ksr_main_logo.png' alt='ksr datavizon' className='landing_page_logo'></img>
                <p className='landing_page_header_items' onClick={() => navigate('/login')}>Login</p>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>

                <div className='reset_password_section'>
                    <div className='image_section_login'>
                        <img src='/ksr_main_logo.png' alt='ksr datavizon'></img>
                    </div>
                    <p className='reset_padsword_heading'>Reset Email</p>

                    <div style={{padding:'30px'}}>

                        <div className='input_and_icon'>
                            <FaMobileAlt className='rp_icon' />
                            <input type="text" placeholder="Enter Phone Number" style={{ border: "none", outline: "none" }} className='rp_input' value={mobile} onChange={(e) => setMobile(e.target.value)}
                            />
                        </div>

                        <div onClick={getEmail} className='submit_button_section' style={{backgroundColor:'var(--mainColor)',textDecoration: "none"}}>
                            {
                                !isLoading ?
                                    <span>Submit</span> :
                                    <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                            }
                        </div>
                        {
                            email && <div>
                                <p>Email Address : {email}</p>
                                <p style={{ cursor: "pointer",color:'var(--mainColor)',fontWeight:'500' }} onClick={() => navigate('/')}>Go Back To Login</p>
                            </div>
                        }

                    </div>



                </div>
            </div>

            <div style={{ marginTop: '50px', width: "100%", overflowX: 'hidden' }}>
                        <Footer />
                    </div>

            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: '',
                    duration: 5000,
                    style: {
                        background: 'white',
                        color: '#000',
                    },

                    success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'white',
                        },
                    },
                }}
            />
        </div>
    )
}
