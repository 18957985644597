import React, { useEffect, useState, useContext } from "react";
import { Chart } from "react-google-charts";
import { MainContext } from "../../ContextStore/MainContext";
import './Charts.css'



export default function LocationChart({ leadData }) {
    const [chartData, setChartData] = useState([]);
  const {selectedYear,selectedMonth,selectedConsultant,selectedCourse}=useContext(MainContext)
  const monthNames=['January','February','March','April','May','June','July','August','September','October','November','December']


  useEffect(() => {
    const leadCounts = {};

    let filteredData = leadData
    // .filter(lead=>(lead.lead_status!=='Lost' && lead.lead_status!=='Inactive' && lead.lead_status!=='Closed'));
    if (selectedConsultant) {
      filteredData = filteredData.filter(lead => lead.primary_consultant === selectedConsultant);
    }
    if (selectedCourse) {
      filteredData = filteredData.filter(lead => lead.course_interested === selectedCourse);
    }

    filteredData && filteredData.forEach(lead => {
        const date = new Date(lead.followup_date);
        const leadYear = date.getFullYear();
        const leadMonth = date.getMonth();

        if ((selectedMonth && leadMonth === selectedMonth-1 && leadYear === selectedYear) || (!selectedMonth && leadYear === selectedYear)) {
            if (leadCounts[lead.city]) {
                leadCounts[lead.city]++;
            } else {
                leadCounts[lead.city] = 1;
            }
        } else if (!selectedYear) {
            if (leadCounts[lead.city]) {
                leadCounts[lead.city]++;
            } else {
                leadCounts[lead.city] = 1;
            }
        }
    });

    const formattedData = Object.entries(leadCounts).map(([leadSource, count]) => {
        return [leadSource, count];
    });

    setChartData([["Location", "Number of Leads"], ...formattedData]);

}, [selectedMonth, selectedYear,selectedConsultant,selectedCourse]);



    const chartOptions = {
        hAxis: {
            title: 'City',
            titleTextStyle: {
                color: '#333',
                fontSize: 14,
            },
            textStyle: {
                fontSize: 12,
            },
        },
        vAxis: {
            title: 'Number of Leads',
            titleTextStyle: {
                color: '#333',
                fontSize: 14,
            },
            format: '0',
        },
    };



    return (
        <div className="chartSection">
            {chartData.length > 1 ? (
                <Chart chartType="ColumnChart" width="43.5vw" height="400px" data={chartData} options={chartOptions} />
            ):<p>No Data Found for Month Location Chart.Please change filter values...</p>}
        </div>
    );
}
