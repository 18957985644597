import React,{useEffect,useState,useContext} from 'react'
import './Header.css'
import { useNavigate } from 'react-router-dom'
import { MainContext } from '../../ContextStore/MainContext'

import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';


export default function Header() {
    const {role,setRole}=useContext(MainContext)
    const navigate =useNavigate()
    useEffect(() => {
        const decryptedRole = Cookies.get("role") ? CryptoJS.AES.decrypt(Cookies.get("role"), 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString(CryptoJS.enc.Utf8) : null;
        setRole(decryptedRole)
        if (decryptedRole !== "admin" && decryptedRole !== "consultant") {
            navigate('/')
        }
    }, [])

    return (
        <div className='header_section_admin_homepage'>
            <img src='/ksr_main_logo.png' alt='ksr datavizon' className='header_logo_admin_homepage' onClick={()=>{
                if(role==="admin"){
                    navigate('/admin-home')
                }
                if(role==="consultant"){
                    navigate('/consultant-home')
                }
            }}></img>
            <div className='admin_homepage_header_options'>
               {role==="admin" && <p onClick={() => navigate('/consultant-management')}>Consultant Management</p>}
                <p onClick={() => navigate('/lead-management')}>Lead Management</p>
                {role==="admin" && <p onClick={() => navigate('/lead-settings')}>Lead Settings</p>}
                { <p onClick={() => navigate('/profile')}>Profile</p>}
            </div>
        </div>
    )
}
