// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.consultant_homepage_main_section{
    display: flex;margin-top: 10px;margin-left: 50px;
}
.upcoming_batches>p{
    font-size: 22.5px;color: black;
}
.upcoming_batches{
    width: 22vw;
}
.each_custom_note{
    border: 2px solid white;border-bottom-color: #777;padding-bottom: 10px;
}
.notes_icons{
    font-size: 22.5px;margin-top: 5px;cursor: pointer;
}
.add_notes_button{
    font-weight: 400 !important;font-size: 15px !important;background-color: var(--mainColor);color: white !important;cursor: pointer;
    width: -moz-fit-content;
    width: fit-content;padding:5px 10px;border-radius: 5px;margin-top: -10px !important;

}`, "",{"version":3,"sources":["webpack://./src/Screens/ConsultantHomePage/ConsultantHomePage.css"],"names":[],"mappings":"AAAA;IACI,aAAa,CAAC,gBAAgB,CAAC,iBAAiB;AACpD;AACA;IACI,iBAAiB,CAAC,YAAY;AAClC;AACA;IACI,WAAW;AACf;AACA;IACI,uBAAuB,CAAC,yBAAyB,CAAC,oBAAoB;AAC1E;AACA;IACI,iBAAiB,CAAC,eAAe,CAAC,eAAe;AACrD;AACA;IACI,2BAA2B,CAAC,0BAA0B,CAAC,kCAAkC,CAAC,uBAAuB,CAAC,eAAe;IACjI,uBAAkB;IAAlB,kBAAkB,CAAC,gBAAgB,CAAC,kBAAkB,CAAC,4BAA4B;;AAEvF","sourcesContent":[".consultant_homepage_main_section{\n    display: flex;margin-top: 10px;margin-left: 50px;\n}\n.upcoming_batches>p{\n    font-size: 22.5px;color: black;\n}\n.upcoming_batches{\n    width: 22vw;\n}\n.each_custom_note{\n    border: 2px solid white;border-bottom-color: #777;padding-bottom: 10px;\n}\n.notes_icons{\n    font-size: 22.5px;margin-top: 5px;cursor: pointer;\n}\n.add_notes_button{\n    font-weight: 400 !important;font-size: 15px !important;background-color: var(--mainColor);color: white !important;cursor: pointer;\n    width: fit-content;padding:5px 10px;border-radius: 5px;margin-top: -10px !important;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
