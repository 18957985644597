// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact_lead_screen{
    display: flex;justify-content: center;margin-left: 0px;margin-right: 0px;
}
.buttons_and_templates_section{
    /* width: 50vw; */
}
.lead_contact_leads_buttons{
    display: flex;height: -moz-fit-content;height: fit-content;justify-content: center;margin-bottom: 20px;
}
.template_dropdown{
    margin-left: 20px !important;
}
.selected_contacted_option{
    text-align: center;font-weight: bold;font-size: 23px;color: #333;
}
.template_body{
    box-shadow: 0px 5px 15px rgba(0,0,0,0.1);width: 50vw;margin-left: 20px;padding: 10px;margin-top: 10px;
    border-radius: 5px;padding-top: 1px;
}
.template_sub_sections{
    margin-top: 30px;border: 1px solid white;border-bottom-color: #ccc;padding-bottom: 10px;
}
.template_side_heading{
    font-weight: bold;padding-top: 20px !important;color: #555;
}
.send_button{
background-color: black;color: white;width: -moz-fit-content;width: fit-content;text-align: center;
padding: 10px 20px;border-radius: 5px;cursor: pointer;margin-left: 0px;
}
.mobile_number{
    margin-top: 10px;margin-left: 20px;font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/Screens/ContactLead/ContactLead.css"],"names":[],"mappings":"AAAA;IACI,aAAa,CAAC,uBAAuB,CAAC,gBAAgB,CAAC,iBAAiB;AAC5E;AACA;IACI,iBAAiB;AACrB;AACA;IACI,aAAa,CAAC,wBAAmB,CAAnB,mBAAmB,CAAC,uBAAuB,CAAC,mBAAmB;AACjF;AACA;IACI,4BAA4B;AAChC;AACA;IACI,kBAAkB,CAAC,iBAAiB,CAAC,eAAe,CAAC,WAAW;AACpE;AACA;IACI,wCAAwC,CAAC,WAAW,CAAC,iBAAiB,CAAC,aAAa,CAAC,gBAAgB;IACrG,kBAAkB,CAAC,gBAAgB;AACvC;AACA;IACI,gBAAgB,CAAC,uBAAuB,CAAC,yBAAyB,CAAC,oBAAoB;AAC3F;AACA;IACI,iBAAiB,CAAC,4BAA4B,CAAC,WAAW;AAC9D;AACA;AACA,uBAAuB,CAAC,YAAY,CAAC,uBAAkB,CAAlB,kBAAkB,CAAC,kBAAkB;AAC1E,kBAAkB,CAAC,kBAAkB,CAAC,eAAe,CAAC,gBAAgB;AACtE;AACA;IACI,gBAAgB,CAAC,iBAAiB,CAAC,iBAAiB;AACxD","sourcesContent":[".contact_lead_screen{\n    display: flex;justify-content: center;margin-left: 0px;margin-right: 0px;\n}\n.buttons_and_templates_section{\n    /* width: 50vw; */\n}\n.lead_contact_leads_buttons{\n    display: flex;height: fit-content;justify-content: center;margin-bottom: 20px;\n}\n.template_dropdown{\n    margin-left: 20px !important;\n}\n.selected_contacted_option{\n    text-align: center;font-weight: bold;font-size: 23px;color: #333;\n}\n.template_body{\n    box-shadow: 0px 5px 15px rgba(0,0,0,0.1);width: 50vw;margin-left: 20px;padding: 10px;margin-top: 10px;\n    border-radius: 5px;padding-top: 1px;\n}\n.template_sub_sections{\n    margin-top: 30px;border: 1px solid white;border-bottom-color: #ccc;padding-bottom: 10px;\n}\n.template_side_heading{\n    font-weight: bold;padding-top: 20px !important;color: #555;\n}\n.send_button{\nbackground-color: black;color: white;width: fit-content;text-align: center;\npadding: 10px 20px;border-radius: 5px;cursor: pointer;margin-left: 0px;\n}\n.mobile_number{\n    margin-top: 10px;margin-left: 20px;font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
