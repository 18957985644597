import React, {  useContext,useEffect } from 'react'
import MenuBar from '../../Components/MenuBar/MenuBar'
import Header from '../../Components/Header/Header'
import '../../Components/TableComponent/TableComponent.css'

// import { MainContext } from '../../ContextStore/MainContext';
import ModalForm from '../../Components/ModalForm/ModalForm';
import TableComponent from '../../Components/TableComponent/TableComponent';
import Footer from '../../Components/FooterComponent/Footer';

import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';


export default function ConsultantManagement() {
//   const { role, openModal, selectedOption } = useContext(MainContext)
  const navigate=useNavigate()

  useEffect(() => {

    const decryptedSelectedOption = Cookies.get("selectedOption") ? CryptoJS.AES.decrypt(Cookies.get("selectedOption"), 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString(CryptoJS.enc.Utf8) : null;
    if(!decryptedSelectedOption){
        navigate('/')
    }
}, [])




  return (
    <div style={{height:'100vh',overflowY:'scroll'}}>
        <Header/>
      

      <div className='table_and_buttons' style={{marginTop:"50px"}}>
          <TableComponent />  
      </div>

      <div style={{ marginTop: '90px', width: "100%", overflowX: 'hidden' }}>
                <Footer />
            </div>

      <ModalForm />


    </div>
  )
}
