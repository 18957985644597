import React, { useEffect, useState, useContext } from "react";
import { Chart } from "react-google-charts";
import { MainContext } from "../../ContextStore/MainContext";



export default function CourseChart({ leadData,primary_consultant,startDate }) {
    const [chartData, setChartData] = useState([]);
  const {selectedYear,selectedMonth}=useContext(MainContext)
  const monthNames=['January','February','March','April','May','June','July','August','September','October','November','December']

  

  useEffect(() => {
    const leadCounts = {};

    let filteredData =leadData
    if (selectedMonth) {
        filteredData = filteredData.filter(lead => {
          const leadDateMonth = new Date(lead.followup_date.split(" ")[0]).getMonth() + 1;
          return leadDateMonth === selectedMonth;
        });
      }

      if (startDate) {
        filteredData = filteredData.filter(lead => {
          const leadDate = new Date(lead.followup_date.split(" ")[0]);
          const startDateObj = new Date(startDate);
          return leadDate.toDateString() === startDateObj.toDateString() 
        });
      }


    filteredData && filteredData.forEach(lead => {
      
        if ((lead.primary_consultant === primary_consultant)) {
            if (leadCounts[lead.course_interested]) {
                leadCounts[lead.course_interested]++;
            } else {
                leadCounts[lead.course_interested] = 1;
            }
        }
    });

    const formattedData = Object.entries(leadCounts).map(([course_interested, count]) => {
        return [course_interested, count];
    });

    setChartData([["Course", "Number of Leads"], ...formattedData]);

}, [selectedMonth, selectedYear,startDate]);



    const chartOptions = {
        hAxis: {
            title: 'Course',
            titleTextStyle: {
                color: '#333',
                fontSize: 14,
            },
            textStyle: {
                fontSize: 12,
            },
        },
        vAxis: {
            title: 'Number of Leads',
            titleTextStyle: {
                color: '#333',
                fontSize: 14,
            },
            format: '0',
        },
    };



    return (
        <div style={{marginLeft:'0px'}}>
            {chartData.length > 1 ? (
                <Chart chartType="ColumnChart" width="72.5vw" height="400px" data={chartData} options={chartOptions} />
            ):<p>No Data Found for Month Location Chart.Please change filter values...</p>}
        </div>
    );
}
