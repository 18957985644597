// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.consultant_dashboard_page{
    display: flex;
}
.chart1{
    border: 2px solid #ccc;padding: 10px;margin: 20px;width: -moz-fit-content;width: fit-content;margin-top: 0px;
}
.charts_list{
    display: grid;grid-template-columns: repeat(2,1fr);row-gap: 0px;
    place-items: center;height: 80vh;overflow-y: scroll;
}
.charts_list::-webkit-scrollbar {
    width: 5px;margin-left: 0px;margin-right: 10px !important;padding: 10px !important;
}

.charts_list::-webkit-scrollbar-track {
    background: #ddd;border-radius: 50px;
}

.charts_list::-webkit-scrollbar-thumb {
    background: #888;border-radius: 50px;
}

.charts_list::-webkit-scrollbar-thumb:hover {
    background: #444;
}`, "",{"version":3,"sources":["webpack://./src/Screens/ConsultantDashboard/ConsultantDashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,sBAAsB,CAAC,aAAa,CAAC,YAAY,CAAC,uBAAkB,CAAlB,kBAAkB,CAAC,eAAe;AACxF;AACA;IACI,aAAa,CAAC,oCAAoC,CAAC,YAAY;IAC/D,mBAAmB,CAAC,YAAY,CAAC,kBAAkB;AACvD;AACA;IACI,UAAU,CAAC,gBAAgB,CAAC,6BAA6B,CAAC,wBAAwB;AACtF;;AAEA;IACI,gBAAgB,CAAC,mBAAmB;AACxC;;AAEA;IACI,gBAAgB,CAAC,mBAAmB;AACxC;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".consultant_dashboard_page{\n    display: flex;\n}\n.chart1{\n    border: 2px solid #ccc;padding: 10px;margin: 20px;width: fit-content;margin-top: 0px;\n}\n.charts_list{\n    display: grid;grid-template-columns: repeat(2,1fr);row-gap: 0px;\n    place-items: center;height: 80vh;overflow-y: scroll;\n}\n.charts_list::-webkit-scrollbar {\n    width: 5px;margin-left: 0px;margin-right: 10px !important;padding: 10px !important;\n}\n\n.charts_list::-webkit-scrollbar-track {\n    background: #ddd;border-radius: 50px;\n}\n\n.charts_list::-webkit-scrollbar-thumb {\n    background: #888;border-radius: 50px;\n}\n\n.charts_list::-webkit-scrollbar-thumb:hover {\n    background: #444;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
