import React, { useState, useEffect, useContext } from 'react'
import './ConsultantDashboard.css'
import MenuBar from '../../Components/MenuBar/MenuBar'
import Header from '../../Components/MenuBar/Header'
import { MainContext } from '../../ContextStore/MainContext'
import toast, { Toaster } from 'react-hot-toast';
import { Chart } from "react-google-charts";




export default function ConsultantDashboard() {

    const [consultantAssignedCount, setConsultantAssignedCount] = useState([])
    const [courseCount, setCoursesCount] = useState([])
    const { BASE_URL } = useContext(MainContext)



    useEffect(() => {
        fetch(`${BASE_URL}lead/leads`)
            .then(response => {
                return response.json();
            })
            .then(data => {
       
                const consultantCount = {};
                const coursesCount={};

                data.forEach(lead => {
                   

                    const consultant = lead.consultant_assigned;
                    consultantCount[consultant] = (consultantCount[consultant] || 0) + 1;

                const courses = lead.course_interested;
                    coursesCount[courses] = (coursesCount[courses] || 0) + 1;
                });
     

                const formattedConsultantData = Object.entries(consultantCount).map(([consultant, count]) => [consultant, count]);
                setConsultantAssignedCount(formattedConsultantData)

                const formattedCoursesData = Object.entries(coursesCount).map(([courses, count]) => [courses, count]);
                setCoursesCount(formattedCoursesData)
            })
            .catch(error => {
                toast.error('Server error. Please try again later...');
            });
    }, []);

   

    const consultantAssignedCountData = [
        ["Consultant", "Count"],
        ...consultantAssignedCount
    ];

    const coursesCountdata = [
        ["Courses", "Count"],
        ...courseCount
    ];



    const consultantAssignedCountOptions = {
        title: "Count of Leads Assigned to Consultants",
        titlePosition: "out",
        titleTextStyle: { fontSize: 18, bold: true, color: '#15c3f3', paddingLeft: 25 },
        legend: {
            position: "right",
            textStyle: {
                color: "#666",
                fontSize: 14,
            },
        },
        tooltip: { trigger: "visible", isHtml: true },
        chartArea: { width: '90%', height: '15vh' },
        backgroundColor: '#f5f5f5',
        pieSliceText: 'value',
        is3D: true,
    };

    const coursesCountOptions = {
        title: "Count of Courses Interested",
        titlePosition: "out",
        titleTextStyle: { fontSize: 18, bold: true, color: '#15c3f3', paddingLeft: 25 },
        legend: {
            position: "right",
            textStyle: {
                color: "#666",
                fontSize: 14,
            },
        },
        tooltip: { trigger: "visible", isHtml: true },
        chartArea: { width: '90%', height: '15vh' },
        backgroundColor: '#f5f5f5',
        pieSliceText: 'value',
        is3D: true,
    };

    return (
        <div className='consultant_dashboard_page'>
            <MenuBar />
            <Header />
            <div>
                <p style={{ marginTop: "70px" }} className='page_heading'>Consultant Dashboard</p>
                <div style={{ width: "75vw" }} className='charts_list'>

                    {coursesCountdata && <div className='chart1'>
                        <Chart
                            chartType="PieChart"
                            data={coursesCountdata}
                            options={coursesCountOptions}
                            width={"32vw"}
                            height={"32vh"}
                        /> </div>
                    }

                    {consultantAssignedCountData && <div className='chart1'>
                        <Chart
                            chartType="PieChart"
                            data={consultantAssignedCountData}
                            options={consultantAssignedCountOptions}
                            width={"32vw"}
                            height={"32vh"}
                        /> </div>
                    }

                </div>
            </div>

            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: '',
                    duration: 2500,
                    style: {
                        background: 'white',
                        color: '#000',
                    },

                    success: {
                        duration: 2500,
                        theme: {
                            primary: 'green',
                            secondary: 'white',
                        },
                    },
                }}
            />
        </div>
    )
}
