import React, { useState, useContext } from 'react';
import './Resetpassword.css'
import { useNavigate } from 'react-router-dom';
import { MdEmail } from "react-icons/md";
import { FaLock } from "react-icons/fa";
import ReactLoading from 'react-loading';
import toast, { Toaster } from 'react-hot-toast';
import { MainContext } from '../../ContextStore/MainContext';
import Footer from '../../Components/FooterComponent/Footer';


export default function Resetpassword() {
    const { email, setEmail, BASE_URL } = useContext(MainContext)
    const [OTP, setOTP] = useState("")
    const [userOTP, setUserOTP] = useState("")
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isEmailSubmitted, setIsEmailSubmitted] = useState(false)
    const [isLoading, setisLoading] = useState(false)
    const [isOTPVerified, setISOTPVerified] = useState(false)
    const [successMsg, setSuccessMsg] = useState("")

    const navigate = useNavigate()

    function submitEmail() {
        setisLoading(true)
        if (email === "") {
            toast.error("Please Enter Email")
            setisLoading(false)
            return
        }
        const lowerEmail=email.toLowerCase()
        fetch(`${BASE_URL}send_otp?email=${lowerEmail}`, {
            method: "GET"
        })
            .then(response => {
                if (response.ok) {

                    setOTP(response.otp)
                    setisLoading(false)
                    setIsEmailSubmitted(true)
                }
            })
            .catch((err) => {

                setisLoading(false)
                toast.error('Server error. Please try again later...')
            });

    }

    function verifyOTP() {
        // Incorrect OTP
        setisLoading(true)
        fetch(`${BASE_URL}verify_otp?otp=${userOTP}&email=${email}`, {
            method: "GET"
        })
            .then(response => {
                if (!response.ok) throw new Error('Failed to fetch data');
                return response.json();
            })
            .then(data => {
                if (data === "Incorrect OTP") {
                    toast.error("Incorrect OTP")
                }
                else {
                    setISOTPVerified(true)
                }
                setisLoading(false);
            })
            .catch((err) => {

                setisLoading(false)
                toast.error('Server error. Please try again later...')
            });



    }

    function updatePassword() {
        setisLoading(true)
        if (password.length < 8) {
            toast.error("password should be atleast 8 characters")
            setisLoading(false)
            return
        }
        if (password !== confirmPassword) {
            toast.error("Passwords did not match")
            setisLoading(false)
            return
        }
        const details = {
            email: email,
            new_password: password
        }
        fetch(`${BASE_URL}user/password_reset`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',

            },

            body: JSON.stringify(details)
        })
            .then(response => {
                if (!response.ok) throw new Error('Failed to fetch data');
                return response.json();
            })
            .then(data => {
                if (data === "Password updated successfully") {
                    setSuccessMsg("Password updated successfully")
                    toast.success("Password updated successfully")
                }
                setisLoading(false);
            })
            .catch(error => {
                setisLoading(false)
                toast.error('Server error. Please try again later...')
            });

    }


    return (

        <div>
            <div className='landing_page_header'>
                <img src='/ksr_main_logo.png' alt='ksr datavizon' className='landing_page_logo'></img>
                {/* <p className='landing_page_header_items' onClick={() => navigate('/login')}>Login</p> */}
            </div>


            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>

                <div className='reset_password_section'>
                    <div className='image_section_login'>
                        <img src='/ksr_main_logo.png' alt='ksr datavizon'></img>
                    </div>
                    <p className='reset_padsword_heading'>Reset password</p>

                    {
                        !isEmailSubmitted &&
                        <div style={{ padding: '30px' }}>

                            {/* <p className='input_label'>Enter Email</p> */}
                            <div className='input_and_icon'>
                                <MdEmail className='rp_icon' />
                                <input type="text" placeholder="Email" style={{ border: "none", outline: "none" }} className='rp_input' value={email} onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>

                            <div onClick={submitEmail} className='submit_button_section' style={{ backgroundColor: 'var(--mainColor)', textDecoration: "none" }}>
                                {
                                    !isLoading ?
                                        <span>Submit</span> :
                                        <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                                }
                            </div>

                        </div>
                    }




                    {
                        isEmailSubmitted && !isOTPVerified &&
                        <div  style={{ padding: '30px' }}>

                            <p className='input_label' style={{textAlign:'center'}}>Enter OTP sent to {email}</p>
                            <div className='input_and_icon'>
                                <FaLock className='rp_icon' />
                                <input placeholder="OTP" type="text" style={{ border: "none", outline: "none" }} className='rp_input' value={userOTP} onChange={(e) => setUserOTP(e.target.value)} />
                            </div>

                            <div style={{backgroundColor: 'var(--mainColor)', textDecoration: "none" }} onClick={verifyOTP} className='submit_button_section'>
                                {
                                    !isLoading ?
                                        <span>Verify OTP</span> :
                                        <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                                }
                            </div>

                        </div>}

                    {
                        isOTPVerified &&
                        <div style={{ padding: '30px' }}>

                            {/* <p className='input_label'>Enter Password</p> */}
                            <div className='input_and_icon'>
                                <FaLock className='rp_icon' />
                                <input placeholder="New Passwrod" type="password" style={{ border: "none", outline: "none" }} className='rp_input' value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>

                            {/* <p className='input_label'>Confirm Password</p> */}
                            <div className='input_and_icon'>
                                <FaLock className='rp_icon' />
                                <input placeholder="Confirm Password" type="password" style={{ border: "none", outline: "none" }} className='rp_input' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                            </div>

                            <div style={{backgroundColor: 'var(--mainColor)', textDecoration: "none" }} onClick={updatePassword} className='submit_button_section'>
                                {
                                    !isLoading ?
                                        <span>Update password</span> :
                                        <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                                }
                            </div>

                            {
                                successMsg && <p style={{ cursor: 'pointer',color: 'var(--mainColor)',marginTop:'25px' }}  onClick={() => navigate('/')}>Go Back to Login</p>
                            }

                        </div>}


                   


                </div>


            </div>

            <div style={{ marginTop: '50px', width: "100%", overflowX: 'hidden' }}>
                        <Footer />
                    </div>

            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: '',
                    duration: 5000,
                    style: {
                        background: 'white',
                        color: '#000',
                    },

                    success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'white',
                        },
                    },
                }}
            />
        </div>
    )
}
