import React, { useContext, useState, useEffect } from 'react';
import { DataGrid, GridToolbarFilterButton } from '@mui/x-data-grid';
import { MainContext } from '../../ContextStore/MainContext';
import ReactLoading from 'react-loading';

import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import toast,{Toaster} from 'react-hot-toast';


const columns = [
    // { field: 'id', headerName: 'ID', width: 70 },
    { field: 'sno', headerName: 'SNo', width: 80 },
    { field: 'firstname', headerName: 'First Name', width: 150 },
    { field: 'lastname', headerName: 'Last Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'mobile', headerName: 'Contact Number', width: 150 },
    { field: 'role', headerName: 'Role', width: 150 },
    // { field: 'org_id', headerName: 'Org Id', width: 130 },

];






export default function TableComponent() {
    const [isLoading, setIsloading] = useState(false)

    const { setRole, role, setOpenModal, setSelectedSubOption,
        BASE_URL, setConsultantEditedData, rowDetails, setRowDetails,consultantList, setConsultantList } = useContext(MainContext)

    const [isChecked, setIsChecked] = useState(false)
    const navigate = useNavigate();






    useEffect(() => {
       consultantList.length===0 && fetch(`${BASE_URL}consultant/consultants`)
            .then(response => {
                if (!response.ok) throw new Error('Failed to fetch data');
                return response.json();
            })
            .then(data => {
                const sortedData = [...data].sort((a, b) => parseInt(b.lead_id) - parseInt(a.lead_id));
                setConsultantList(sortedData)
                let consultantArray = sortedData.map(item => ({ [`${item.firstname} ${item.lastname}`]: item.email }));
                localStorage.setItem('consultant_list', JSON.stringify(consultantArray));
            })
            .catch(error => {
                alert('Server error. Please try again later...');
            });
    }, []);

    useEffect(() => {
        const decryptedRole = Cookies.get("role") ? CryptoJS.AES.decrypt(Cookies.get("role"), 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString(CryptoJS.enc.Utf8) : null;
        setRole(decryptedRole)
        if (decryptedRole !== "admin" || decryptedRole === "consultant") {
            navigate('/')
        }
    }, [])

    function deleteConsultant() {
        setIsloading(true)
        const decryptedID = Cookies.get("id")
     

        decryptedID && fetch(`${BASE_URL}consultant/consultants/${rowDetails[0]}?deleted_by_id=${decryptedID}`, {
            method: "DELETE",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },

        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                toast.success("Deleted Successfully")
                setIsloading(false)
                setConsultantList(prevData => prevData.filter(item => item.id !== rowDetails[0]));
                
            })
            .catch(error => {
                setIsloading(false)
                toast.error('Server error. Please try again later...');
            });
    }



    const addSnoToData = (data) => {
        return data.map((item, index) => ({
            ...item,
            sno: index + 1,
        }));
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <p className='page_heading' style={{ marginTop: '60px' }}>Consultant Management</p>
            <div className='data_grid1'>
                <DataGrid
                    rows={addSnoToData(consultantList)}
                    getRowId={(row) => row.id}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}

                    pageSizeOptions={[10, 20]}
                    checkboxSelection
                    // headerStyle={{ backgroundColor: '#2196f3', color: '#fff' }}
                    // headerClassName="custom-header"
                    sx={{
                        '& .MuiDataGrid-columnHeader': { backgroundColor: 'var(--mainColor)', color: 'white' },
                        '& .MuiButtonBase-root': {
                            color: 'white',
                        },
                        '& .MuiButtonBase-root svg': {
                            color: '#333',
                        },
                    }}
                    onRowSelectionModelChange={(rowId) => {
                        setIsChecked(!isChecked)
                        setRowDetails(rowId)
                        const selectedData = consultantList.filter(row => rowId.includes(row.id));
                        setConsultantEditedData(selectedData);

                    }}
                    onRowClick={(e) => {
                        setConsultantEditedData(e.row)
                    }}

                />



                {role === "admin" && <div className='consultant_management_buttons' style={{ marginLeft: "-10px" }}>
                    <p className='consultant_management_button'
                        onClick={() => {
                            setSelectedSubOption("Add New Consultant")
                            setOpenModal(true)
                        }}>Add New Consultant</p>

                    {rowDetails && rowDetails.length === 1 &&
                        <div style={{ display: 'flex' }}>
                            <p className='consultant_management_button'
                                onClick={() => {
                                    setSelectedSubOption("Edit Consultant")
                                    setOpenModal(true)
                                }}>Edit Consultant</p>

                            <div className='consultant_management_button' onClick={() => {
                                setSelectedSubOption("Delete Consultant")
                                deleteConsultant()
                            }} style={{ height: 'fit-content', marginTop: "15px" }}>
                                {
                                    !isLoading ?
                                        <span>Delete Consultant</span> :
                                        <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                                }
                            </div>
                        </div>
                    }
                    <div className='disclaimer_section'>
                        <p className='each_disclaimer'><span style={{ color: 'red' }}>*</span> Select Consultant to edit and delete</p>
                    </div>
                </div>}


            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: '',
                    duration: 2500,
                    style: {
                        background: 'white',
                        color: '#000',
                    },

                    success: {
                        duration: 2500,
                        theme: {
                            primary: 'green',
                            secondary: 'white',
                        },
                    },
                }}
            />
        </div>
    );
}
