import React, { useContext, useEffect, useState } from 'react'
import './MenuBar.css'
import { MainContext } from '../../ContextStore/MainContext'
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';

export default function MenuBar() {
    const { setSelectedOption, setOpenModal } = useContext(MainContext)
    const [decryptRole, setDecryptRole] = useState("")
    const navigate = useNavigate();

    useEffect(() => {
        const decryptedRole = Cookies.get("role") ? CryptoJS.AES.decrypt(Cookies.get("role"), 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString(CryptoJS.enc.Utf8) : null;
        setDecryptRole(decryptedRole)
        if (decryptedRole !== "admin" && decryptedRole !== "consultant") {
            navigate('/')
        }
    }, [])



    return (
        <div className='menubar_component'>
            <img src='/ksr_logo.png' className='menubar_ksr_logo' alt='ksr datavizon'></img>
            {
                decryptRole === "admin" &&
                <div className='menubar_list'>
                    <p className='menubar_option'
                        onClick={() => {
                            setSelectedOption("Consultant Management")
                            const opt = CryptoJS.AES.encrypt("Consultant Management", 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString();
                            Cookies.set('selectedOption', opt, { expires: 7 })
                            navigate("/consultant-management")
                        }}>Consultant Management</p>

                    <p className='menubar_option'
                        onClick={() => {
                            setSelectedOption("Lead Management")
                            const opt = CryptoJS.AES.encrypt("Lead Management", 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString();
                            Cookies.set('selectedOption', opt, { expires: 7 })
                            navigate("/lead-management")
                        }}>Lead Management</p>

                    <p className='menubar_option'
                        onClick={() => {
                            setSelectedOption("Consultant Dashboard")
                            const opt = CryptoJS.AES.encrypt("Consultant Dashboard", 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString();
                            Cookies.set('selectedOption', opt, { expires: 7 })
                            navigate('/consultant-dashboard')
                        }}>Consultant Dashboard</p>

                    <p className='menubar_option'
                        onClick={() => {
                            setSelectedOption("Lead Dashboard")
                            const opt = CryptoJS.AES.encrypt("Lead Dashboard", 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString();
                            Cookies.set('selectedOption', opt, { expires: 7 })
                            navigate('/lead-dashboard')
                        }}>Lead Dashboard</p>



                    <p className='menubar_option'
                        onClick={() => {
                            setSelectedOption("Lead Settings")
                            const opt = CryptoJS.AES.encrypt("Lead Settings", 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString();
                            Cookies.set('selectedOption', opt, { expires: 7 })
                            navigate("/lead-settings")
                        }}>Lead Settings</p>


                </div>
            }
            {
                decryptRole === "consultant" &&
                <div className='menubar_list'>
                    <p className='menubar_option'
                        onClick={() => {
                            setSelectedOption("Lead Management")
                            const opt = CryptoJS.AES.encrypt("Lead Management", 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString();
                            Cookies.set('selectedOption', opt, { expires: 7 })
                            navigate("/lead-management")

                        }}>Lead Management</p>

                    <p className='menubar_option'
                        onClick={() => {
                            setSelectedOption("Lead Dashboard")
                            const opt = CryptoJS.AES.encrypt("Lead Dashboard", 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString();
                            Cookies.set('selectedOption', opt, { expires: 7 })
                            navigate('/lead-dashboard')
                        }}>Lead Dashboard</p>
                    
                    <p className='menubar_option'
                        onClick={() => {
                            setSelectedOption("Settings")
                            const opt = CryptoJS.AES.encrypt("Settings", 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString();
                            Cookies.set('selectedOption', opt, { expires: 7 })
                            navigate('/consultant-settings')
                        }}>Settings</p>
                </div>
            }

        </div>
    )
}
