import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import './Login.css'

// Sample data and columns
const yourData = [
  { id: 1, name: 'John', age: 30, status: 'active' },
  { id: 2, name: 'Doe', age: 25, status: 'inactive' },
  { id: 3, name: 'Smith', age: 35, status: 'active' },
];

const yourColumns = [
  { field: 'name', headerName: 'Name', width: 150 },
  { field: 'age', headerName: 'Age', width: 150 },
  { field: 'status', headerName: 'Status', width: 150 },
];

// Function to determine row class name
const getRowClassName = (params) => {
    console.log(params.row.name); 
    return params.row.name === "John" ? 'evenRow' :params.row.name === "Doe"? 'oddRow':'evenRow';
  };

// Main DataGrid component
function CustomDataGrid() {
  return (
    <div style={{ height: 300, width: '100%' }}>
      <DataGrid
        rows={yourData}
        columns={yourColumns}
        getRowClassName={getRowClassName}
      />
    </div>
  );
}

export default CustomDataGrid;
