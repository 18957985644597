import React, { useContext, useEffect, useState } from 'react'
import './Settings.css'
import { MainContext } from '../../ContextStore/MainContext';
import SettingsModal from './SettingsModal';
import Header from '../../Components/Header/Header';
import { MdDelete } from "react-icons/md";
import { MdEdit } from "react-icons/md";

import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import Footer from '../../Components/FooterComponent/Footer';
import toast, { Toaster } from 'react-hot-toast';
import ReactLoading from 'react-loading';


export default function LeadManagement() {
    const { BASE_URL, setopenSettingsModal, setSelectedSettingOption, emailTemplateList, setEmailTemplateList,
        whatsappTemplateList, setWhatsappTemplateList,
        genderList, setGenderList,
        ageList, setAgeList,
        courseInterested, setCourseInterested,
        leadStatusList, setLeadStatusList,
        leadSourceList, setLeadSourceList,
        campaignList, setCampaignList, 
        role } = useContext(MainContext)

    const [isImageLoading, setIsImageLoading] = useState(false)
    const [isImageretrieving, setIsImageRetrieving] = useState(false)
    const [templateImage, setTemplateImage] = useState(null)
    const [imageLink, setImageLink] = useState(null)
    const [imageURLs, setImageURLs] = useState([])


    const navigate = useNavigate()

    useEffect(() => {

        const decryptedSelectedOption = Cookies.get("selectedOption") ? CryptoJS.AES.decrypt(Cookies.get("selectedOption"), 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString(CryptoJS.enc.Utf8) : null;
        if (!decryptedSelectedOption) {
            navigate('/')
        }
        // eslint-disable-next-line              
    }, [])

    // useEffect(() => {
    //     const decryptedRole = Cookies.get("role") ? CryptoJS.AES.decrypt(Cookies.get("role"), 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString(CryptoJS.enc.Utf8) : null;

    //     if (decryptedRole !== "admin") {
    //         navigate('/page-not-found')
    //     }
    // }, []);




    useEffect(() => {

        genderList.length === 0 && fetch(`${BASE_URL}custom_settings/gender`)
            .then(response => {
                if (!response.ok) throw new Error('Failed to fetch data');
                return response.json();
            })
            .then(data => {
                setGenderList(data)
                localStorage.setItem("gender_list", JSON.stringify(data))
            })
            .catch(error => {
                console.log(error);
            });



        ageList.length === 0 && fetch(`${BASE_URL}custom_settings/age`)
            .then(response => {
                return response.json();
            })
            .then(data => {
                setAgeList(data)
                localStorage.setItem("age_list", JSON.stringify(data))
            })
            .catch(error => {
                console.log(error);
            });



        courseInterested.length === 0 && fetch(`${BASE_URL}custom_settings/course_interested`)
            .then(response => {
                if (!response.ok) throw new Error('Failed to fetch data');
                return response.json();
            })
            .then(data => {
                setCourseInterested(data)
                localStorage.setItem("course_interested", JSON.stringify(data))
            })
            .catch(error => {
                console.log(error);
            });




        leadStatusList.length === 0 && fetch(`${BASE_URL}custom_settings/lead_status`)
            .then(response => {
                if (!response.ok) throw new Error('Failed to fetch data');
                return response.json();
            })
            .then(data => {
                setLeadStatusList(data)
                localStorage.setItem("lead_status", JSON.stringify(data))
            })
            .catch(error => {
                console.log(error);
            })


        leadSourceList.length === 0 && fetch(`${BASE_URL}custom_settings/lead_source`)
            .then(response => {
                if (!response.ok) throw new Error('Failed to fetch data');
                return response.json();
            })
            .then(data => {
                setLeadSourceList(data)
                localStorage.setItem("lead_source", JSON.stringify(data))
            })
            .catch(error => {
                console.log(error);
            });



        campaignList.length === 0 && fetch(`${BASE_URL}custom_settings/campaign`)
            .then(response => {
                if (!response.ok) throw new Error('Failed to fetch data');
                return response.json();
            })
            .then(data => {
                setCampaignList(data)
                localStorage.setItem("campaign", JSON.stringify(data))
            })
            .catch(error => {
                console.log(error);
            });

        emailTemplateList.length === 0 && fetch(`${BASE_URL}templates/email_template/`)
            .then(response => {
                if (!response.ok) throw new Error('Failed to fetch data');
                return response.json();
            })
            .then(data => {
                // console.log(data);
                setEmailTemplateList(data)
            })
            .catch(error => {
                console.log(error);
            });


        whatsappTemplateList.length === 0 && fetch(`${BASE_URL}templates/whatsapp_template/`)
            .then(response => {
                if (!response.ok) throw new Error('Failed to fetch data');
                return response.json();
            })
            .then(data => {
                // console.log(data);
                setWhatsappTemplateList(data)
            })
            .catch(error => {
                console.log(error);
            });
        // eslint-disable-next-line  
    }, [])

    function uploadImage() {
        setIsImageLoading(true)
        const formData = new FormData();
        formData.append('uploaded_file', templateImage);
        fetch(`${BASE_URL}whatsapp_integration/upload_image/`, {
            method: "POST",
            body: formData
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                setIsImageLoading(false)
                if (data) {
                    setImageLink(data)
                    toast.success("Image uploaded Successfully")
                }
                else {
                    toast.error("Failed to upload")
                }
                setTemplateImage(null)
            })
            .catch(error => {
                console.log(error);
                setIsImageLoading(false)
                toast.error('Server error. Please try again later');
            });
    }

    function retrieveImages() {
        setIsImageRetrieving(true)
        fetch(`${BASE_URL}whatsapp_integration/get_image_url/`, {
            method: "GET",
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                setIsImageRetrieving(false)
                if (data.files) {
                    setImageURLs(data.files.reverse())
                }
                else {
                    toast.error('Error Occured')
                }
            })
            .catch((err) => {
                setIsImageRetrieving(false)
                toast.error('Error Occured')
            })
    }









    return (
        <div style={{ height: '100vh', overflowY: 'scroll' }}>
            <Header />

            <div>
                <p className='page_heading' style={{ marginTop: "110px" }}>Lead Settings</p>

                <div className='all_settings'>
                    <div className='each_setting' style={{ marginBottom: '-20px' }}>
                        <p className='settings_heading'>WhatsApp Template List</p>
                        <div className='each_setting_list'>
                            {
                                whatsappTemplateList.length > 0 && whatsappTemplateList.map((e, id) => {
                                    return (
                                        <div style={{ display: 'flex', justifyContent: 'space-around' }} key={id}>
                                            <span className='template_item template_name'>{e.template_name}</span>
                                            {/* <span className='template_item' onClick={() => {
                                                localStorage.setItem("whatsapp_template_name", e.template_name)
                                                localStorage.setItem("whatsapp_template_url", e.url)
                                                localStorage.setItem("whatsapp_template_id", e.id)
                                                setopenSettingsModal(true)
                                                setSelectedSettingOption("Edit WhatsApp Template")
                                            }} style={{ marginLeft: '10px', fontSize: '20px', cursor: 'pointer' }}><MdEdit /></span> */}

                                            <span className='template_item' onClick={() => {
                                                localStorage.setItem("whatsapp_template_id", e.id)
                                                setopenSettingsModal(true)
                                                setSelectedSettingOption("Delete WhatsApp Template")
                                            }} style={{ marginLeft: '10px', fontSize: '20px', cursor: 'pointer' }}><MdDelete /></span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/* <p className='update_button' onClick={() => {
                            setopenSettingsModal(true)
                            setSelectedSettingOption("Add WhatsApp Template")
                            localStorage.removeItem("whatsapp_template_name");
                            localStorage.removeItem("whatsapp_template_url");
                            localStorage.removeItem("whatsapp_template_id");
                        }}>Add Template</p> */}
                        <p className='update_button' style={{ marginTop: '10px' }} onClick={() => {
                            navigate('/whatsapp-integration')
                        }}>WhatsApp integration</p>
                    </div>


                    <div className='each_setting' style={{ marginBottom: '20px' }}>
                        <p className='settings_heading'>Email Template List</p>
                        <div className='each_setting_list'>
                            {
                                emailTemplateList.length > 0 && emailTemplateList.map((e, id) => {
                                    return (
                                        <div style={{ display: 'flex', justifyContent: 'space-around' }} key={id}>
                                            <span className='template_item template_name'>{e.template_name}</span>
                                            <span className='template_item' onClick={() => {
                                                localStorage.setItem("email_template_name", e.template_name)
                                                localStorage.setItem("email_template_subject", e.subject)
                                                localStorage.setItem("email_template_url", e.url)
                                                localStorage.setItem("email_template_id", e.id)
                                                setopenSettingsModal(true)
                                                setSelectedSettingOption("Edit Email Template")
                                            }} style={{ marginLeft: '10px', fontSize: '20px', cursor: 'pointer' }}><MdEdit /></span>

                                            <span className='template_item' onClick={() => {
                                                localStorage.setItem("email_template_id", e.id)
                                                setopenSettingsModal(true)
                                                setSelectedSettingOption("Delete Email Template")
                                            }} style={{ marginLeft: '10px', fontSize: '20px', cursor: 'pointer' }}><MdDelete /></span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <p className='update_button' onClick={() => {
                            setopenSettingsModal(true)
                            setSelectedSettingOption("Add Email Template")
                            localStorage.removeItem("email_template_name");
                            localStorage.removeItem("email_template_subject");
                            localStorage.removeItem("email_template_url");
                            localStorage.removeItem("email_template_id");
                        }}>Add Template</p>
                    </div>


                    <div className='each_setting'>
                        <p className='settings_heading'>Gender List</p>
                        <div className='each_setting_list'>
                            {
                                genderList && genderList?.map((e, id) => {
                                    return (
                                        <p key={id} className='list_item'>{e.gender}</p>
                                    )
                                })
                            }
                        </div>
                        <p className='update_button' onClick={() => {
                            setopenSettingsModal(true)
                            setSelectedSettingOption("Update Gender")
                        }}>Update List</p>
                    </div>


                    <div className='each_setting'>
                        <p className='settings_heading'>Age List</p>
                        <div className='each_setting_list'>
                            {
                                ageList && ageList?.map((e, id) => {
                                    return (
                                        <p key={id} className='list_item'>{e.age}</p>
                                    )
                                })
                            }
                        </div>
                        <p className='update_button' onClick={() => {
                            setopenSettingsModal(true)
                            setSelectedSettingOption("Update Age")
                        }}>Update List</p>

                    </div>


                    <div className='each_setting'>
                        <p className='settings_heading'>Courses Interested</p>
                        <div className='each_setting_list'>
                            {
                                courseInterested && courseInterested?.map((e, id) => {
                                    return (
                                        <p key={id} className='list_item'>{e.course_interested}</p>
                                    )
                                })
                            }
                        </div>
                        <p className='update_button' onClick={() => {
                            setopenSettingsModal(true)
                            setSelectedSettingOption("Update Course Interested")
                        }}>Update List</p>
                    </div>


                    <div className='each_setting'>
                        <p className='settings_heading'>Lead Status List</p>
                        <div className='each_setting_list'>
                            {
                                leadStatusList && leadStatusList?.map((e, id) => {
                                    return (
                                        <p key={id} className='list_item'>{e.lead_status}</p>
                                    )
                                })
                            }
                        </div>
                        <p className='update_button' onClick={() => {
                            setopenSettingsModal(true)
                            setSelectedSettingOption("Update Lead Status")
                        }}>Update List</p>
                    </div>


                    <div className='each_setting'>
                        <p className='settings_heading'>Lead Source List</p>
                        <div className='each_setting_list'>
                            {
                                leadSourceList && leadSourceList?.map((e, id) => {
                                    return (
                                        <p key={id} className='list_item'>{e.lead_source}</p>
                                    )
                                })
                            }
                        </div>
                        <p className='update_button' onClick={() => {
                            setopenSettingsModal(true)
                            setSelectedSettingOption("Update Lead Source")
                        }}>Update List</p>
                    </div>


                    <div className='each_setting'>
                        <p className='settings_heading'>Campaign List</p>
                        <div className='each_setting_list'>
                            {
                                campaignList && campaignList?.map((e, id) => {
                                    return (
                                        <p key={id} className='list_item'>{e.campaign}</p>
                                    )
                                })
                            }
                        </div>
                        <p className='update_button' onClick={() => {
                            setopenSettingsModal(true)
                            setSelectedSettingOption("Update Campaign")
                        }}>Update List</p>
                    </div>



                </div>
                <div className='each_setting'>
                    <p className='testApiHeading'>Upload image for the template</p>
                    <input type="file" style={{backgroundColor:'#eee'}} placeholder='fee receipt' accept=".png, .jpg, .jpeg" onChange={(e) => setTemplateImage(e.target.files[0])} />
                    <div className='testApiButton' onClick={() => {
                        uploadImage()
                    }}>
                        {
                            !isImageLoading ?
                                <span>Upload</span> :
                                <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                        }
                    </div>
                    {imageLink && <p>Image URL : {imageLink}</p>}

                </div>
                <div className='each_setting'>

                    <div className='testApiButton' style={{ padding: '10px',marginBottom:'10px' }} onClick={() => {
                        retrieveImages()
                    }}>
                        {
                            !isImageretrieving ?
                                <span>Click here to Retrieve images links</span> :
                                <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                        }
                    </div>

                    {imageURLs.length > 0 && <div style={{marginTop:'20px'}} className='allImagesLinks'>
                        {
                            imageURLs.length > 0 && imageURLs.map((e, id) => {
                                return (
                                    <div key={id} className='imageandURL'>
                                        <p><span className='imagenameHeading'>Image Name:</span> <span>{e.file_name}</span></p>
                                        <p><span className='imagenameHeading'>Image Link:</span> <span>{e.url}</span></p>
                                    </div>
                                )
                            })
                        }
                    </div>}

                </div>


            </div>

            <div style={{ marginTop: '0px', width: "100%", overflowX: 'hidden' }}>
                <Footer />
            </div>

            <SettingsModal />

            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: '',
                    duration: 2500,
                    style: {
                        background: 'white',
                        color: '#000',
                    },

                    success: {
                        duration: 2500,
                        theme: {
                            primary: 'green',
                            secondary: 'white',
                        },
                    },
                }}
            />
        </div>
    )
}
