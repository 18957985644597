import React, { useState, useContext, useEffect } from 'react'
import './ConsultantSettings.css'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import MenuBar from '../../Components/MenuBar/MenuBar';
import Header from '../../Components/Header/Header';
import ReactLoading from 'react-loading';
import { MainContext } from '../../ContextStore/MainContext';
import toast, { Toaster } from 'react-hot-toast';
import Footer from '../../Components/FooterComponent/Footer';



export default function ConsultantSettings() {

    const [date, setDate] = useState('')
    const [endDate,setEndDate]=useState('')
    const [reason, setReason] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isLoading1, setIsLoading1] = useState(false)

    const [leaveList, setLeaveList] = useState([])



    const { BASE_URL } = useContext(MainContext)

    const handleDateChange = (date) => {
        const selectedDate = new Date(date);
        const timezoneOffsetInMs = selectedDate.getTimezoneOffset() * 60000;
        const adjustedDate = new Date(selectedDate.getTime() - timezoneOffsetInMs);
        const formattedDate = adjustedDate.toISOString().substring(0, 10);
        return formattedDate;
        

    };

    function submitLeave() {
        setIsLoading(true)
        fetch(`${BASE_URL}consultant_management/leave_calendar?consultant_id=${localStorage.getItem('consultantId')}&consultant_name=${localStorage.getItem('consultantName')}&consultant_email=${localStorage.getItem('consultantEmail')}&start_date=${date}&end_date=${endDate}&notes=${reason}`, {
            method: 'POST'
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data) {
                    setIsLoading(false)
                    setReason('')
                    setDate('')
                    toast.success('Leave Requested Successfully')
                    window.location.reload()
                }
            })
            .catch(error => {
                setIsLoading(true)
                toast.error('Server error. Please try again later...');
            });
    }

    useEffect(() => {
        fetch(`${BASE_URL}consultant_management/leave_calendar?consultant_id=${localStorage.getItem('consultantId')}`, {
            method: 'GET'
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data) {
                    if (data.length === 0) {
                        toast.success('No leaves found')
                    }
                    setIsLoading1(false)
                    setLeaveList(data)
                }

            })
            .catch(error => {
                setIsLoading1(true)
                toast.error('Server error. Please try again later...');
            });
    }, [])



    return (
        <div>
            <Header />
            <div style={{ marginLeft: '2vw', marginTop: '110px', display: 'flex',justifyContent:'space-between' }} >
                <div className='date_and_reason'>
                    <p className='apply_leave_heading'>Apply for Leave</p>
                    <DatePicker
                        selected={date}
                        onChange={(date) => setDate(handleDateChange(date))}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="select Start Date"
                        className='date_input'
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                    />
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(handleDateChange(date))}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="select End Date"
                        className='date_input'
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                    />
                    <input placeholder='Mention reason' value={reason} onChange={(e) => setReason(e.target.value)} className='reason_input'></input>
                    <div className='form_submit_button' onClick={submitLeave} style={{backgroundColor:"var(--mainColor)"}}>
                        {
                            !isLoading ?
                                <span>Submit</span> :
                                <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                        }
                    </div>


                </div>

                {leaveList.length > 0 && <div className='leavesList'>
                    <p className='apply_leave_heading'>Leave Activity</p>
                    {
                        leaveList && leaveList.map((e, id) => {
                            return (
                                <div key={id} className='each_leave'>
                                    <p><span className='leave_sub_heading'>Start Date :</span> <span className='leave_sub_data'>{e.start_date}</span></p>
                                    <p><span className='leave_sub_heading'>End Date :</span> <span className='leave_sub_data'>{e.end_date}</span></p>
                                    <p><span className='leave_sub_heading'>Reason :</span> <span className='leave_sub_data'>{e.notes}</span></p>
                                </div>
                            )
                        })
                    }
                </div>}


            </div>

            <div style={{ marginTop: '90px', width: "100%", overflowX: 'hidden' }}>
                <Footer />
            </div>

            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: '',
                    duration: 2500,
                    style: {
                        background: 'white',
                        color: '#000',
                    },

                    success: {
                        duration: 2500,
                        theme: {
                            primary: 'green',
                            secondary: 'white',
                        },
                    },
                }}
            />

        </div>
    )
}
