import React, { useEffect, useContext, useState } from 'react'
import './LeadDetails.css'
import MenuBar from '../../Components/MenuBar/MenuBar'
import { MainContext } from '../../ContextStore/MainContext'
import ModalForm from '../../Components/ModalForm/ModalForm';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/FooterComponent/Footer'


import toast, { Toaster } from 'react-hot-toast';

import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';

export default function LeadDetails() {

    const { role, setRole, leadEditedData, setLeadEditedData, BASE_URL, setIsContactLeadClicked, setOpenModal, setSelectedSubOption, } = useContext(MainContext)
    const [leadActivity, setLeadActivity] = useState([])
    const [isLeadDetailsFound, setIsLeadDetailsFound] = useState(true)
    const navigate = useNavigate();


    useEffect(() => {
        const storedLeadData = localStorage.getItem("leadData");
        if (storedLeadData!=="undefined"  && storedLeadData!==null) {
            setLeadEditedData(JSON.parse(localStorage.getItem("leadData")));
        }
        else{
            // navigate('/lead-management')
            // toast.error('Please choose only one action either select or click')
        }
    }, []);

    useEffect(() => {
        if (leadEditedData.email === "") {
            navigate('/lead-management')
        }
    }, [])

    


    useEffect(() => {
        const id =localStorage.getItem("leadData")!=="undefined" && JSON.parse(localStorage.getItem("leadData"))?.lead_id
        id && fetch(`${BASE_URL}lead/activity_history?lead_id=${id}`)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 404) {
                        throw new Error("No lead history found");
                    } else {
                        throw new Error("Server error. Please try again later.");
                    }
                }
                if(response.ok){ 
                    return response.json();
                }
            })
            .then(data => {
                if (data.detail === "No lead history found") {
                    setIsLeadDetailsFound(false)
                }
                else {
                    setLeadActivity(data.sort((a, b) => new Date(b.modified_date) - new Date(a.modified_date)))
                }
            })
            .catch(error => {
                console.log(error);
            });
        
    }, [])


    useEffect(() => {
        const decryptedRole = Cookies.get("role") ? CryptoJS.AES.decrypt(Cookies.get("role"), 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString(CryptoJS.enc.Utf8) : null;
        setRole(decryptedRole)
        if (decryptedRole !== "admin" && decryptedRole !== "consultant") {
            navigate('/')
        }
    }, [])

    function submitFeeRecipt() {

    }

    return (
        <div >
            <Header />
            {/* <MenuBar /> */}
            <div className='lead_details_component'>
                <div className='lead_details_and_buttons'>
                    {leadEditedData &&
                        <div className='lead_details_section' style={{ marginTop: "100px" }}>
                            <p className='page_heading' style={{marginTop:'0px'}}>Lead Details</p>
                            <div><p>First Name:</p> <p>{leadEditedData?.firstname}</p></div>
                            <div><p>Last Name: </p> <p>{leadEditedData?.lastname}</p></div>
                            <div><p>Email: </p> <p>{leadEditedData?.email}</p></div>
                            <div><p>Mobile: </p> <p>{leadEditedData?.mobile}</p></div>
                            <div><p>Primary Consult: </p><p>{leadEditedData?.primary_consultant}</p></div>
                            <div><p>FollowUp Date: </p><p>{leadEditedData?.followup_date}</p></div>
                            <div><p>Notes: </p><p>{leadEditedData?.notes}</p></div>
                            <div><p>Incorrect Lead: </p><p>{leadEditedData?.incorrect_lead_flag}</p></div>
                            <div><p>Incorrect Email: </p><p>{leadEditedData?.incorrect_email_flag}</p></div>
                            <div><p>Incorrect Mobile: </p><p>{leadEditedData?.incorrect_mobile_flag}</p></div>
                            <div><p>Lead Status: </p><p>{leadEditedData?.lead_status}</p></div>
                            <div><p>Lead Source: </p><p>{leadEditedData?.lead_source}</p></div>
                            <div><p>Course Interested: </p><p>{leadEditedData?.course_interested}</p></div>
                            <div><p>State: </p> <p>{leadEditedData?.state}</p></div>
                            <div><p>City: </p> <p>{leadEditedData?.city}</p></div>
                            <div><p>Gender: </p><p>{leadEditedData?.gender}</p></div>
                            <div><p>Age: </p><p>{leadEditedData?.age}</p></div>
                            <div><p>Highest Qualification: </p><p>{leadEditedData?.highest_qualification}</p></div>
                            <div><p>Year of Passing: </p><p>{leadEditedData?.year_of_passing}</p></div>
                            <div><p>Reason for taking course: </p><p>{leadEditedData?.reason_for_taking_this_course}</p></div>
                            <div><p>Occupation: </p><p>{leadEditedData?.occupation}</p></div>
                            <div><p>Date Enrolled: </p><p>{leadEditedData?.date_enrolled?.substring(0, 10)}</p></div>
                            {/* <div><p>Registered Date: </p><p>{leadEditedData?.enquiry_date?.substring(0, 10)}</p></div> */}
                            <div><p>Device: </p><p>{leadEditedData?.device}</p></div>
                            <div><p>Batch: </p><p>{leadEditedData?.batch}</p></div>
                            <div><p>Batch Start Date: </p><p>{leadEditedData?.batch_start_date?.substring(0, 10)}</p></div>
                            <div><p>Campaign: </p><p>{leadEditedData?.campaign}</p></div>
                            <div><p>Source URL: </p><p>{leadEditedData?.source_url}</p></div>
                            <div><p>Lead Medium: </p><p>{leadEditedData?.lead_medium}</p></div>
                            <div><p>Personal Info: </p><p>{leadEditedData?.level_1} - {leadEditedData?.level_2} - {leadEditedData?.level_3}</p></div>
                            <div><p>Secondary Assigned: </p><p>{leadEditedData?.secondary_consultant}</p></div>
                            <div><p>Escalation Consultant: </p><p>{leadEditedData?.escalation_consultant}</p></div>
                            <div><p>Escalation Notes: </p><p>{leadEditedData?.escalation_notes}</p></div>



                        </div>}


                    <div className='consultant_management_buttons' style={{ marginLeft: "3.5vw", marginTop: "10px",width:"100%" }}>
                        <p className='consultant_management_button'
                            onClick={() => {
                                navigate('/contact-lead')
                                setIsContactLeadClicked(true)
                            }}>Contact Lead</p>

                        {<p className='consultant_management_button'
                            onClick={() => {
                                setSelectedSubOption("Edit Lead1")
                                setOpenModal(true)
                                setLeadEditedData(JSON.parse(localStorage.getItem("leadData")));
                            }}>Edit Lead</p>
                        }
                        <p className='consultant_management_button'
                            onClick={() => {
                                setSelectedSubOption("Add Notes")
                                setOpenModal(true)
                            }}>Add Notes</p>

                        <p className='consultant_management_button'
                            onClick={() => {
                                navigate('/lead-payment-details')
                            }}>Payment Details</p>
                    </div>
                </div>

                <div className='lead_activity_section'>
                    <p className='lead_activity_heading'>Lead Activity</p>
                    {isLeadDetailsFound ? <div>

                        {
                            leadActivity && leadActivity?.map((e, id) => {
                                return (
                                    <div key={id} className='date_and_activity'>
                                        <p className='activity_date'><span>Date : </span> {e.modified_date?.substring(0, 10)}</p>
                                        <p className='activity_name'><span>Activity : </span> {e.activity}</p>
                                        <p className='activity_name'><span>Changes made by : </span> {e.consultant_name!=="undefined" ? e.consultant_name:e.consultant_email}</p>

                                    </div>
                                )
                            })
                        }
                    </div> : <p>No Lead Activity Found !</p>}
                </div>
            </div>

            <div style={{ marginTop: '50px', width: "100%", overflowX: 'hidden' }}>
                <Footer />
            </div>


            <ModalForm />
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: '',
                    duration: 2500,
                    style: {
                        background: 'white',
                        color: '#000',
                    },

                    success: {
                        duration: 2500,
                        theme: {
                            primary: 'green',
                            secondary: 'white',
                        },
                    },
                }}
            />

        </div>
    )
}
