import React from 'react'
import './MenuBar.css'
import { useNavigate } from 'react-router-dom'
export default function Header() {
    const navigate=useNavigate()
  return (
    <div className='header_section'>
        <p className='logout' onClick={()=>navigate('/')}>Logout</p>
    </div>
  )
}
