import React from 'react';

const PageNotFound = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px',width:"100vw" }}>
      <h1>Page Not Found</h1>
      <p>Sorry, the page you are looking for might have been removed or is temporarily unavailable.</p>
      {/* You can add a link to a home page or any other relevant page */}
      <a href="/">Go to Home Page</a>
    </div>
  );
};

export default PageNotFound;
