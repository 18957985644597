// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing_page_header{
    display: flex;justify-content: space-between;padding:10px 50px 10px 50px;
    box-shadow: 0px 5px 15px rgba(0,0,0,0.05);align-items: center;
}
.landing_page_logo{
    width: 8vw;height: 10vh;
}
.landing_page_header_items{
    background-color: var(--mainColor);color: white;padding: 10px 25px;border-radius: 5px ;cursor: pointer;
    height: -moz-fit-content;
    height: fit-content;
}
.landing_page_header_items:hover{
    border: 1px solid var(--mainColor);background-color: white;color: var(--mainColor);transition-duration: 0.75s;
}
.landing_page_intro{
    display: flex;justify-content: space-around;align-items: center;
}
.landing_page_heading{
    font-size: 30px;font-weight: bold;width: 30vw;color: var(--mainColor);opacity: 0.9;
}
.landing_page_description{
    font-size: 18px;color: #555;width: 40vw;line-height: 1.5;
}
.landing_page_intro_image{
    width: 40vw;height: auto;
}`, "",{"version":3,"sources":["webpack://./src/Screens/LandingPage/LandingPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa,CAAC,8BAA8B,CAAC,2BAA2B;IACxE,yCAAyC,CAAC,mBAAmB;AACjE;AACA;IACI,UAAU,CAAC,YAAY;AAC3B;AACA;IACI,kCAAkC,CAAC,YAAY,CAAC,kBAAkB,CAAC,mBAAmB,CAAC,eAAe;IACtG,wBAAmB;IAAnB,mBAAmB;AACvB;AACA;IACI,kCAAkC,CAAC,uBAAuB,CAAC,uBAAuB,CAAC,0BAA0B;AACjH;AACA;IACI,aAAa,CAAC,6BAA6B,CAAC,mBAAmB;AACnE;AACA;IACI,eAAe,CAAC,iBAAiB,CAAC,WAAW,CAAC,uBAAuB,CAAC,YAAY;AACtF;AACA;IACI,eAAe,CAAC,WAAW,CAAC,WAAW,CAAC,gBAAgB;AAC5D;AACA;IACI,WAAW,CAAC,YAAY;AAC5B","sourcesContent":[".landing_page_header{\n    display: flex;justify-content: space-between;padding:10px 50px 10px 50px;\n    box-shadow: 0px 5px 15px rgba(0,0,0,0.05);align-items: center;\n}\n.landing_page_logo{\n    width: 8vw;height: 10vh;\n}\n.landing_page_header_items{\n    background-color: var(--mainColor);color: white;padding: 10px 25px;border-radius: 5px ;cursor: pointer;\n    height: fit-content;\n}\n.landing_page_header_items:hover{\n    border: 1px solid var(--mainColor);background-color: white;color: var(--mainColor);transition-duration: 0.75s;\n}\n.landing_page_intro{\n    display: flex;justify-content: space-around;align-items: center;\n}\n.landing_page_heading{\n    font-size: 30px;font-weight: bold;width: 30vw;color: var(--mainColor);opacity: 0.9;\n}\n.landing_page_description{\n    font-size: 18px;color: #555;width: 40vw;line-height: 1.5;\n}\n.landing_page_intro_image{\n    width: 40vw;height: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
