import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import '../../Components/ModalForm/ModalForm.css'
import { MainContext } from '../../ContextStore/MainContext';
import toast, { Toaster } from 'react-hot-toast';
import ReactLoading from 'react-loading';
import TextField from '@mui/material/TextField';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';



export default function PaymentModal() {
    const { openPaymentModal, setOpenPaymentModal, BASE_URL, selectedSubOption, paymentDetails, setPaymentDetails } = useContext(MainContext)
    const handleClose = () => setOpenPaymentModal(false);
    const [isLoading, setIsLoading] = useState(false)
    // console.log(paymentDetails[0]);
    const [editedPaymentDetails,setEditedPaymentDetails]=useState([])

    // JSON.parse(localStorage.getItem('leadData')).lead_id
    useEffect(()=>{
        paymentDetails && setEditedPaymentDetails(paymentDetails[0])
    },[paymentDetails])


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "90vw",
        bgcolor: '#eee',
        border: 'none !important',
        outline: "none !important",
        boxShadow: 24,
        borderRadius: 2,
        p: 2,
    };

    const [paymentData, setPaymentData] = useState({
        lead_id:localStorage.getItem('leadData') && JSON.parse(localStorage.getItem('leadData')).lead_id,
        total_fees: 0,
        discount: 0,
        payment_reminder_date: "",
        installment_1: 0,
        installment_1_date: "",
        installment_2: 0,
        installment_2_date: "",
        installment_3: 0,
        installment_3_date: "",
        installment_4: 0,
        installment_4_date: "",
        notes: ""

    });

    const handleInputChange = (field, value) => {
        setPaymentData(prevPaymentData => ({ ...prevPaymentData, [field]: value }));
    };

    const handleEditInputChange = (field, value) => {
        setEditedPaymentDetails(prevPaymentData => ({ ...prevPaymentData, [field]: value }));
    };

    const handleDateChange = (field, date) => {
        const selectedDate = new Date(date);

        const timezoneOffsetInMs = selectedDate.getTimezoneOffset() * 60000;
        const adjustedDate = new Date(selectedDate.getTime() - timezoneOffsetInMs);

        const formattedDate = adjustedDate.toISOString().substring(0, 10);

        setPaymentData(prevPaymentData => ({ ...prevPaymentData, [field]: formattedDate }));

    };

    const handleEditDateChange = (field, date) => {
        const selectedDate = new Date(date);

        const timezoneOffsetInMs = selectedDate.getTimezoneOffset() * 60000;
        const adjustedDate = new Date(selectedDate.getTime() - timezoneOffsetInMs);

        const formattedDate = adjustedDate.toISOString().substring(0, 10);

        setEditedPaymentDetails(prevPaymentData => ({ ...prevPaymentData, [field]: formattedDate }));

    };


    function AddPaymnet() {
        setIsLoading(true)

        fetch(`${BASE_URL}payment?consultant_name=${localStorage.getItem('consultantName')}&consultant_email=${localStorage.getItem('consultantEmail')}`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(paymentData)
        })
            .then(response => {
                return response.json;
            })
            .then(data => {
                setIsLoading(false)
                if (data) {
                    toast.success("Added Successfully")
                }

                window.location.reload()
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false)
                toast.error('Server error. Please try again later...');
            });

    }



    function UpdatePaymnet() {
        setIsLoading(true)

        fetch(`${BASE_URL}payment/payments/{lead_id?consultant_name=${localStorage.getItem('consultantName')}&consultant_email=${localStorage.getItem('consultantEmail')}`, {
            method: "PUT",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(editedPaymentDetails)
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                setIsLoading(false)
                if (data.lead_id) {
                    // console.log(data.lead_id);
                    toast.success("Updated Successfully")
                    setPaymentDetails([data])
                    setOpenPaymentModal(false)
                }
                else{
                    toast.error("Failed to update")
                }
               
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false)
                toast.error('Server error. Please try again later...');
            });
    }


    return (
        <div>
            <Modal
                open={openPaymentModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='modalform_component'
            >
                <Box sx={style}>
                    {
                        selectedSubOption === "Add Payment" &&
                        <div>
                            <p className='settingUpdateBoxheading'>Add Payment</p>
                            <div className='paymentBox'>

                                <TextField variant="outlined" id="outlined-basic" label="Total Fees" className='form_input'
                                    onChange={(e) => handleInputChange('total_fees', e.target.value)}
                                />

                                <TextField variant="outlined" id="outlined-basic" label="Discount" className='form_input'
                                    onChange={(e) => handleInputChange('discount', e.target.value)}
                                />

                                <DatePicker
                                    selected={paymentData.payment_reminder_date}
                                    onChange={(date) => handleDateChange('payment_reminder_date', date)}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Payment Reminder Date"
                                    className='form_date_iput'
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    style={{ zIndex: '1000' }}

                                />

                                <TextField variant="outlined" id="outlined-basic" label="Notes" className='form_input'
                                    onChange={(e) => handleInputChange('notes', e.target.value)}
                                />


                                <TextField variant="outlined" id="outlined-basic" label="Installment 1" className='form_input'
                                    onChange={(e) => handleInputChange('installment_1', e.target.value)}
                                />


                                <TextField variant="outlined" id="outlined-basic" label="Installment 2" className='form_input'
                                    onChange={(e) => handleInputChange('installment_2', e.target.value)}
                                />

                                <TextField variant="outlined" id="outlined-basic" label="Installment 3" className='form_input'
                                    onChange={(e) => handleInputChange('installment_3', e.target.value)}
                                />



                                <TextField variant="outlined" id="outlined-basic" label="Installment 4" className='form_input'
                                    onChange={(e) => handleInputChange('installment_4', e.target.value)}
                                />

                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ marginLeft: '10px', marginTop: '10px' }}>Installment 1 Date</span>
                                    <DatePicker
                                        selected={paymentData.installment_1_date}
                                        onChange={(date) => handleDateChange('installment_1_date', date)}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Installment 1 Date"
                                        className='form_date_iput'
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        style={{ zIndex: '100' }}
                                    />
                                </div>


                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ marginLeft: '10px', marginTop: '10px' }}>Installment 2 Date</span>
                                    <DatePicker
                                        selected={paymentData.installment_2_date}
                                        onChange={(date) => handleDateChange('installment_2_date', date)}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Installment 2 Date"
                                        className='form_date_iput'
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        style={{ zIndex: '100' }}
                                    />
                                </div>




                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ marginLeft: '10px', marginTop: '10px' }}>Installment 3 Date</span>
                                    <DatePicker
                                        selected={paymentData.installment_3_date}
                                        onChange={(date) => handleDateChange('installment_3_date', date)}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Installment 3 Date"
                                        className='form_date_iput'
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        style={{ zIndex: '100' }}

                                    />
                                </div>


                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ marginLeft: '10px', marginTop: '10px' }}>Installment 4 Date</span>
                                    <DatePicker
                                        selected={paymentData.installment_4_date}
                                        onChange={(date) => handleDateChange('installment_4_date', date)}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Installment 4 Date"
                                        className='form_date_iput'
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        style={{ zIndex: '100' }}
                                    />
                                </div>



                            </div>
                            <div type='submit' style={{ textDecoration: "none", marginTop: "10px", width: 'fit-content',backgroundColor:'var(--mainColor)' }} onClick={AddPaymnet} className='settingUpdateBoxButton'>
                                {
                                    !isLoading ?
                                        <span>Add</span> :
                                        <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                                }
                            </div>
                        </div>

                    }


                    {
                        selectedSubOption === "Update Payment" &&
                        <div>
                            <p className='settingUpdateBoxheading'>Update Payment</p>
                            <div className='paymentBox'>

                                <TextField variant="outlined" value={editedPaymentDetails?.total_fees} id="outlined-basic" label="Total Fees" className='form_input'
                                    onChange={(e) => handleEditInputChange('total_fees', e.target.value)}
                                />

                                <TextField variant="outlined" value={editedPaymentDetails?.discount} id="outlined-basic" label="Discount" className='form_input'
                                    onChange={(e) => handleEditInputChange('discount', e.target.value)}
                                />

                                <DatePicker
                                    selected={editedPaymentDetails.payment_reminder_date && editedPaymentDetails.payment_reminder_date}
                                    onChange={(date) => handleEditDateChange('payment_reminder_date', date)}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Payment Reminder Date"
                                    className='form_date_iput'
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    style={{ zIndex: '1000' }}

                                />

                                <TextField variant="outlined" value={editedPaymentDetails?.notes} id="outlined-basic" label="Notes" className='form_input'
                                    onChange={(e) => handleEditInputChange('notes', e.target.value)}
                                />


                                <TextField variant="outlined" value={editedPaymentDetails?.installment_1} id="outlined-basic" label="Installment 1" className='form_input'
                                    onChange={(e) => handleEditInputChange('installment_1', e.target.value)}
                                />


                                <TextField variant="outlined" value={editedPaymentDetails?.installment_2} id="outlined-basic" label="Installment 2" className='form_input'
                                    onChange={(e) => handleEditInputChange('installment_2', e.target.value)}
                                />

                                <TextField variant="outlined" value={editedPaymentDetails?.installment_3} id="outlined-basic" label="Installment 3" className='form_input'
                                    onChange={(e) => handleEditInputChange('installment_3', e.target.value)}
                                />



                                <TextField variant="outlined" value={editedPaymentDetails?.installment_4} id="outlined-basic" label="Installment 4" className='form_input'
                                    onChange={(e) => handleEditInputChange('installment_4', e.target.value)}
                                />

                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ marginLeft: '10px', marginTop: '10px' }}>Installment 1 Date</span>
                                    <DatePicker
                                        selected={editedPaymentDetails.installment_1_date && editedPaymentDetails.installment_1_date}
                                        onChange={(date) => handleEditDateChange('installment_1_date', date)}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Installment 1 Date"
                                        className='form_date_iput'
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        style={{ zIndex: '100' }}
                                    />
                                </div>


                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ marginLeft: '10px', marginTop: '10px' }}>Installment 2 Date</span>
                                    <DatePicker
                                        selected={editedPaymentDetails.installment_2_date && editedPaymentDetails.installment_2_date}
                                        onChange={(date) => handleEditDateChange('installment_2_date', date)}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Installment 2 Date"
                                        className='form_date_iput'
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        style={{ zIndex: '100' }}
                                    />
                                </div>




                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ marginLeft: '10px', marginTop: '10px' }}>Installment 3 Date</span>
                                    <DatePicker
                                        selected={editedPaymentDetails.installment_3_date && editedPaymentDetails.installment_3_date}
                                        onChange={(date) => handleEditDateChange('installment_3_date', date)}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Installment 3 Date"
                                        className='form_date_iput'
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        style={{ zIndex: '100' }}

                                    />
                                </div>


                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ marginLeft: '10px', marginTop: '10px' }}>Installment 4 Date</span>
                                    <DatePicker
                                        selected={editedPaymentDetails.installment_4_date && editedPaymentDetails.installment_4_date}
                                        onChange={(date) => handleEditDateChange('installment_4_date', date)}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Installment 4 Date"
                                        className='form_date_iput'
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        style={{ zIndex: '100' }}
                                    />
                                </div>



                            </div>
                            <div type='submit' style={{ textDecoration: "none", marginTop: "10px", width: 'fit-content' }} onClick={UpdatePaymnet} className='settingUpdateBoxButton'>
                                {
                                    !isLoading ?
                                        <span>Update</span> :
                                        <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                                }
                            </div>
                        </div>

                    }



                </Box>
            </Modal>

            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: "",
                    duration: 2500,
                    style: {
                        background: 'white',
                        color: '#000',
                    },

                    success: {
                        duration: 2500,
                        theme: {
                            primary: 'green',
                            secondary: 'white',
                        },
                    },
                }}
            />
        </div>
    );
}
