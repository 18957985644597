// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment_details_heading{
    text-align: center;font-size: 20px;font-weight: bold;color: var(--mainColor);
}
.total_payment_details{
    display: grid;grid-template-columns: repeat(4,1fr);
}
.each_payment_detail{
    padding-left: 1px;line-height: 1;margin-top: 0px;font-size: 14px;
}
.payment_details_sub_heading{
    font-size: 14px;font-weight: bold;
}

.paymentBox{
    display: flex;flex-direction: row;flex-wrap: wrap;
}
.fee_receipts_list{
    border: 2px solid white;border-bottom-color: #777;
}
.each_receipt{
    color: var(--mainColor);cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Screens/LeadPaymentDetails/LeadPaymentDetails.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB,CAAC,eAAe,CAAC,iBAAiB,CAAC,uBAAuB;AAChF;AACA;IACI,aAAa,CAAC,oCAAoC;AACtD;AACA;IACI,iBAAiB,CAAC,cAAc,CAAC,eAAe,CAAC,eAAe;AACpE;AACA;IACI,eAAe,CAAC,iBAAiB;AACrC;;AAEA;IACI,aAAa,CAAC,mBAAmB,CAAC,eAAe;AACrD;AACA;IACI,uBAAuB,CAAC,yBAAyB;AACrD;AACA;IACI,uBAAuB,CAAC,eAAe;AAC3C","sourcesContent":[".payment_details_heading{\n    text-align: center;font-size: 20px;font-weight: bold;color: var(--mainColor);\n}\n.total_payment_details{\n    display: grid;grid-template-columns: repeat(4,1fr);\n}\n.each_payment_detail{\n    padding-left: 1px;line-height: 1;margin-top: 0px;font-size: 14px;\n}\n.payment_details_sub_heading{\n    font-size: 14px;font-weight: bold;\n}\n\n.paymentBox{\n    display: flex;flex-direction: row;flex-wrap: wrap;\n}\n.fee_receipts_list{\n    border: 2px solid white;border-bottom-color: #777;\n}\n.each_receipt{\n    color: var(--mainColor);cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
