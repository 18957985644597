import  React,{useContext} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import './ModalForm.css'
import { MainContext } from '../../ContextStore/MainContext';
import Form from '../Form/Form';


export default function ModalForm() {
  const {openModal, setOpenModal}=useContext(MainContext)
  const handleClose = () => setOpenModal(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "fit-content",
    bgcolor: '#eee',
    border: 'none !important',
    outline:"none !important",
    boxShadow: 24,
    borderRadius:2,
    p: 2,
  };

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='modalform_component'
      >
        <Box sx={style}>
          <Form/>
        </Box>
      </Modal>
    </div>
  );
}
