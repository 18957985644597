import React, { useState, useEffect, useContext } from 'react'
import '../ConsultantDashboard/ConsultantDashboard.css'
import MenuBar from '../../Components/MenuBar/MenuBar'
import Header from '../../Components/MenuBar/Header'
import { MainContext } from '../../ContextStore/MainContext'
import toast, { Toaster } from 'react-hot-toast';
import { Chart } from "react-google-charts";




export default function LeadDashboard() {
    const [leadStatusCount, setLeadStatusCount] = useState([])
    const [leadSourceCount, setLeadSourceCount] = useState([])
    const [leadStatesCount, setLeadStatesCount] = useState([])

    const { BASE_URL } = useContext(MainContext)



    useEffect(() => {
        fetch(`${BASE_URL}lead/leads`)
            .then(response => {
                return response.json();
            })
            .then(data => {
                const statusCount = {};
                const sourcesCount={};
                const statesCount={};



                data.forEach(lead => {
                    const status = lead.lead_status;
                    statusCount[status] = (statusCount[status] || 0) + 1;

                    const sources = lead.lead_source;
                    sourcesCount[sources] = (sourcesCount[sources] || 0) + 1;

                    const states = lead.state;
                    statesCount[states] = (statesCount[states] || 0) + 1;


                });
                const formattedStatusData = Object.entries(statusCount).map(([status, count]) => [status, count]);
                setLeadStatusCount(formattedStatusData);

                const formattedSourcesData = Object.entries(sourcesCount).map(([sources, count]) => [sources, count]);
                setLeadSourceCount(formattedSourcesData);

                const formattedStatesData = Object.entries(statesCount).map(([states, count]) => [states, count]);
                setLeadStatesCount(formattedStatesData);


            })
            .catch(error => {
                toast.error('Server error. Please try again later...');
            });
    }, []);

    const statusCountData = [
        ["Status", "Count"],
        ...leadStatusCount
    ];

    const sourcesCountData = [
        ["Sources", "Count"],
        ...leadSourceCount
    ];

    const statesCountData = [
        ["States", "Count"],
        ...leadStatesCount
    ];



    const LeadStatusCountOptions = {
        title: "Count of Each Lead Status",
        titlePosition: "out",
        titleTextStyle: { fontSize: 18, bold: true, color: '#15c3f3', paddingLeft: 25 },
        legend: {
            position: "right",
            textStyle: {
                color: "#666",
                fontSize: 14,
            },
        },
        tooltip: { trigger: "visible", isHtml: true },
        chartArea: { width: '90%', height: '15vh' },
        backgroundColor: '#f5f5f5',
        pieSliceText: 'value',
        is3D: true,
    };

    const LeadSourcesCountOptions = {
        title: "Count of Each Lead Sources",
        titlePosition: "out",
        titleTextStyle: { fontSize: 18, bold: true, color: '#15c3f3', paddingLeft: 25 },
        legend: {
            position: "right",
            textStyle: {
                color: "#666",
                fontSize: 14,
            },
        },
        tooltip: { trigger: "visible", isHtml: true },
        chartArea: { width: '90%', height: '15vh' },
        backgroundColor: '#f5f5f5',
        pieSliceText: 'value',
        is3D: true,
    };

    const LeadStatesCountOptions = {
        title: "Count of Leads from each State",
        titlePosition: "out",
        titleTextStyle: { fontSize: 18, bold: true, color: '#15c3f3', paddingLeft: 25 },
        legend: {
            position: "right",
            textStyle: {
                color: "#666",
                fontSize: 14,
            },
        },
        tooltip: { trigger: "visible", isHtml: true },
        chartArea: { width: '90%', height: '15vh' },
        backgroundColor: '#f5f5f5',
        pieSliceText: 'value',
        is3D: true,
    };



    return (
        <div className='consultant_dashboard_page'>
            <MenuBar />
            <Header />
            <div>
                <p style={{ marginTop: "70px" }} className='page_heading'>Lead Dashboard</p>
                <div style={{ width: "75vw" }} className='charts_list'>
                    {statusCountData && <div className='chart1'>
                        <Chart
                            chartType="PieChart"
                            data={statusCountData}
                            options={LeadStatusCountOptions}
                            title="kjbwhsn"
                            width={"32vw"}
                            height={"32vh"}
                        /> </div>
                    }

                    {sourcesCountData && <div className='chart1'>
                        <Chart
                            chartType="PieChart"
                            data={sourcesCountData}
                            options={LeadSourcesCountOptions}
                            width={"32vw"}
                            height={"32vh"}
                        /> </div>
                    }

                    {statesCountData && <div className='chart1'>
                        <Chart
                            chartType="PieChart"
                            data={statesCountData}
                            options={LeadStatesCountOptions}
                            width={"32vw"}
                            height={"32vh"}
                        /> </div>
                    }



                </div>
            </div>

            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: '',
                    duration: 2500,
                    style: {
                        background: 'white',
                        color: '#000',
                    },

                    success: {
                        duration: 2500,
                        theme: {
                            primary: 'green',
                            secondary: 'white',
                        },
                    },
                }}
            />
        </div>
    )
}
