import React, { useState, useEffect, useContext } from 'react'
import './AdminHomePage.css'
import { MainContext } from '../../ContextStore/MainContext';
import { useNavigate } from 'react-router-dom';
import StatsBox from '../../Components/StatsBox/StatsBox'
import MonthChart from '../../Components/AdminCharts/MonthChart';
import SourceChart from '../../Components/AdminCharts/SourceCharts';
import LocationChart from '../../Components/AdminCharts/LocationChart';
import BatchesBox from '../../Components/BatchesBox/BatchesBox';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/FooterComponent/Footer';

import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

export default function AdminHomePage() {


  const { BASE_URL, role, selectedYear, setSelectedYear, selectedMonth, setSelectedMonth, selectedConsultant,
    setSelectedConsultant, selectedCourse, setSelectedCourse, startDate, setStartDate,leadData1, setLeadData1,adminLeadData, setAdminLeadData,
    genderList, setGenderList,
    ageList, setAgeList,
    courseInterested, setCourseInterested,
    leadStatusList, setLeadStatusList,
    leadSourceList, setLeadSourceList,
    campaignList, setCampaignList,
    consultantList, setConsultantList
   } = useContext(MainContext);
  const [leadData, setLeadData] = useState([])
  // const [leadData1, setLeadData1] = useState([])
  const [totalRevenue, setTotalRevenue] = useState(0)
  const [totalDiscount, setTotalDiscount] = useState(0)
  const [totalCollect, setTotalCollect] = useState(0)
  const [endDate, setEndDate] = useState('')
  // const [adminLeadData, setAdminLeadData] = useState([])


  const [selectedMonth1, setSelectedMonth1] = useState('')
  const [totalLeads, setTotalLeads] = useState(0)
  const [newLeads, setNewLeads] = useState(0)
  const [lostLeads, setLostLeads] = useState(0)
  const [follwupLeads, setFollowupLeads] = useState(0)
  const [consultantList1, setConsultantList1] = useState([])

  const navigate = useNavigate()


  const getGreeting = () => {
    const currentTime = new Date().getHours();
    if (currentTime < 12) {
      return 'Good Morning';
    } else if (currentTime >= 12 && currentTime < 18) {
      return 'Good Afternoon';
    } else {
      return 'Good Evening';
    }
  };

  useEffect(() => {
    const decryptedRole = Cookies.get("role") ? CryptoJS.AES.decrypt(Cookies.get("role"), 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString(CryptoJS.enc.Utf8) : null;

    if (decryptedRole !== "admin") {
      navigate('/page-not-found')
    }
  }, []);
  
  useEffect(() => {
    consultantList.length===0 && fetch(`${BASE_URL}consultant/consultants`)
         .then(response => {
             if (!response.ok) throw new Error('Failed to fetch data');
             return response.json();
         })
         .then(data => {
             const sortedData = [...data].sort((a, b) => parseInt(b.lead_id) - parseInt(a.lead_id));
             setConsultantList(sortedData)
             let consultantArray = sortedData.map(item => ({ [`${item.firstname} ${item.lastname}`]: item.email }));
             localStorage.setItem('consultant_list', JSON.stringify(consultantArray));
         })
         .catch(error => {
             alert('Server error. Please try again later...');
         });
 }, []);

  useEffect(() => {
    genderList.length === 0 && fetch(`${BASE_URL}custom_settings/gender`)
      .then(response => {
        if (!response.ok) throw new Error('Failed to fetch data');
        return response.json();
      })
      .then(data => {
        setGenderList(data)
        localStorage.setItem("gender_list", JSON.stringify(data))
      })
      .catch(error => {
        console.log(error);
      });



    ageList.length === 0 && fetch(`${BASE_URL}custom_settings/age`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        setAgeList(data)
        localStorage.setItem("age_list", JSON.stringify(data))
      })
      .catch(error => {
        console.log(error);
      });



    courseInterested.length === 0 && fetch(`${BASE_URL}custom_settings/course_interested`)
      .then(response => {
        if (!response.ok) throw new Error('Failed to fetch data');
        return response.json();
      })
      .then(data => {
        setCourseInterested(data)
        localStorage.setItem("course_interested", JSON.stringify(data))
      })
      .catch(error => {
        console.log(error);
      });




    leadStatusList.length === 0 && fetch(`${BASE_URL}custom_settings/lead_status`)
      .then(response => {
        if (!response.ok) throw new Error('Failed to fetch data');
        return response.json();
      })
      .then(data => {
        setLeadStatusList(data)
        localStorage.setItem("lead_status", JSON.stringify(data))
      })
      .catch(error => {
        console.log(error);
      })


    leadSourceList.length === 0 && fetch(`${BASE_URL}custom_settings/lead_source`)
      .then(response => {
        if (!response.ok) throw new Error('Failed to fetch data');
        return response.json();
      })
      .then(data => {
        setLeadSourceList(data)
        localStorage.setItem("lead_source", JSON.stringify(data))
      })
      .catch(error => {
        console.log(error);
      });



    campaignList.length === 0 && fetch(`${BASE_URL}custom_settings/campaign`)
      .then(response => {
        if (!response.ok) throw new Error('Failed to fetch data');
        return response.json();
      })
      .then(data => {
        setCampaignList(data)
        localStorage.setItem("campaign", JSON.stringify(data))
      })
      .catch(error => {
        console.log(error);
      });

  }, [])


  useEffect(() => {
    // fetch(`${BASE_URL}lead/leads`)

  adminLeadData.length<=0 &&  fetch(`${BASE_URL}lead/leads`)
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        setAdminLeadData(data)
      })
      .catch((err) => {
        console.log(err);
      })

  }, [])





  useEffect(() => {


    let filteredData = adminLeadData && adminLeadData
    // .filter(lead=>(lead.lead_status!=='Lost' && lead.lead_status!=='Inactive' && lead.lead_status!=='Closed'));
    if (selectedConsultant) {
      filteredData = filteredData.filter(lead => lead?.primary_consultant === selectedConsultant);
    }
    if (selectedCourse) {
      filteredData = filteredData.filter(lead => lead?.course_interested === selectedCourse);
    }

    if (startDate) {
      
      filteredData = filteredData.filter(lead => {
        const leadDate = new Date(lead?.followup_date.split(" ")[0]);
        const startDateObj = new Date(startDate);
        return leadDate?.toDateString() === startDateObj.toDateString();
      });
    }

    if (selectedMonth) {
      setStartDate('')
      filteredData = filteredData.filter(lead => {
        const leadDateMonth = new Date(lead?.followup_date.split(" ")[0]).getMonth() + 1;
        return leadDateMonth === selectedMonth;
      });
    }
    if (selectedYear) {
      setStartDate('')
      filteredData = filteredData.filter(lead => {
        const leadDateYear = new Date(lead?.followup_date.split(" ")[0]).getFullYear();
        return leadDateYear === selectedYear;
      });
    }
    // console.log(filteredData);
    setLeadData1(filteredData);
    setLeadData(adminLeadData)


    let Revenue = 0; let Discount = 0; let FeesPaid = 0; let total_lead_count = 0; let new_leads = 0;
    let lost_leads = 0; let followup_leads = 0

    filteredData?.forEach(lead => {
      total_lead_count++;


      const totalFees = parseFloat(lead.total_fees) || 0;
      Revenue += totalFees;

      const discountGiven = parseFloat(lead.discount) || 0;
      Discount += discountGiven

      const fees_paid = parseFloat(lead.fees_paid) || 0;
      FeesPaid += fees_paid

      if (lead.lead_status === "New") {
        new_leads++
      }
      if (lead.lead_status === "Lost") {
        lost_leads++
      }
      // if ((new Date(lead.followup_date).toDateString() === (startDate!=='' ? new Date(startDate).toDateString() : new Date().toDateString()))  && lead.lead_status==="Follow Up") {
      //   followup_leads++
      // }

      const leadDate = new Date(lead.followup_date);
      const compareDate = startDate !== '' ? new Date(startDate) : new Date();
      const leadMonth = leadDate.getMonth() + 1;
      const leadYear = leadDate.getFullYear();
  
      if ((leadDate.toDateString() === compareDate.toDateString() 
        || (selectedMonth && leadMonth === selectedMonth) 
        || (selectedYear && leadYear === selectedYear))
        && lead.lead_status === "Follow Up") {
        followup_leads++;
      }

    }); 

    setTotalRevenue(Revenue)
    setTotalDiscount(Discount)
    setTotalCollect(FeesPaid)
    setTotalLeads(total_lead_count)
    setNewLeads(new_leads)
    setLostLeads(lost_leads)
    setFollowupLeads(followup_leads)


  }, [selectedCourse, selectedConsultant, startDate, selectedMonth, selectedYear,adminLeadData])



  const months = [
    { number: 1, name: 'January' },
    { number: 2, name: 'February' },
    { number: 3, name: 'March' },
    { number: 4, name: 'April' },
    { number: 5, name: 'May' },
    { number: 6, name: 'June' },
    { number: 7, name: 'July' },
    { number: 8, name: 'August' },
    { number: 9, name: 'September' },
    { number: 10, name: 'October' },
    { number: 11, name: 'November' },
    { number: 12, name: 'December' }
  ];

  const years = [2022, 2023, 2024, 2025]
  return (
    <div>
      <Header />

      <div className='admin_homepage_main_section'>

        <div className='data_and_batches'>
          <div>
            <div className='welcome_section'>
              <p>Welcome {localStorage.getItem('adminName') !== "undefined" ? localStorage.getItem('adminName') : 'Admin'}; {getGreeting()}</p>

              <div className='admin_page_filters_section'>
                <FormControl className='admin_homepage_dropdown'>
                  <InputLabel id="demo-simple-select-label" className='admin_homepage_dropdown_label'>Consultant</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedConsultant}
                    label="Consultant"
                    onChange={(e) => setSelectedConsultant(e.target.value)}
                  >
                    {

                      localStorage.getItem('consultant_list') &&  JSON.parse(localStorage.getItem('consultant_list')).map((consultant, id) => {
                        const name = Object.keys(consultant)[0];
                        const email = consultant[name];
                        return (
                          <MenuItem value={email} key={id}>{name}</MenuItem>
                        );
                      })
                    }

                  </Select>
                </FormControl>

                <FormControl className='admin_homepage_dropdown' >
                  <InputLabel id="demo-simple-select-label" className='admin_homepage_dropdown_label'>Course</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedCourse}
                    label="Course"
                    onChange={(e) => setSelectedCourse(e.target.value)}
                  >
                    {
                      localStorage.getItem('course_interested') && JSON.parse(localStorage.getItem('course_interested')).map((e, id) => {
                        return (
                          <MenuItem value={e.course_interested} key={id}>{e.course_interested}</MenuItem>
                        )
                      })
                    }

                  </Select>
                </FormControl>

                <FormControl className='admin_homepage_dropdown'>
                  <InputLabel id="demo-simple-select-label" className='admin_homepage_dropdown_label'>Month</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedMonth}
                    label="Course"
                    onChange={(e) => {setSelectedMonth(e.target.value);setStartDate('')}}
                  >
                    {
                      months.map((e, id) => {
                        return (
                          <MenuItem value={e.number} key={id}>{e.name}</MenuItem>
                        )
                      })
                    }

                  </Select>
                </FormControl>

                <FormControl className='admin_homepage_dropdown'>
                  <InputLabel id="demo-simple-select-label" className='admin_homepage_dropdown_label'>Year</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedYear}
                    label="Course"
                    onChange={(e) =>{ setSelectedYear(e.target.value);setStartDate('')}}
                  >
                    {
                      years.map((e, id) => {
                        return (
                          <MenuItem value={e} key={id}>{e}</MenuItem>
                        )
                      })
                    }

                  </Select>
                </FormControl>


                <DatePicker
                  selected={startDate}
                  onChange={(date) => { setStartDate(date);setSelectedMonth(''); setSelectedYear('') }}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select Date"
                  className='search_input admin_page_datepicker'
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />

                {/* <DatePicker
                  selected={endDate}
                  onChange={(date) => { setEndDate(date) }}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="End Date"
                  className='search_input admin_page_datepicker'
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                /> */}

              </div>

              <div className='clear_filters_section'>
                {selectedConsultant && <p className='clear_filters' onClick={() => { setSelectedConsultant('') }}>Clear Consultant</p>}
                {selectedCourse && <p className='clear_filters' onClick={() => { setSelectedCourse('') }}>Clear Course</p>}
                {selectedMonth && <p className='clear_filters' onClick={() => { setSelectedMonth('') }}>Clear Month</p>}
                {selectedYear && <p className='clear_filters' onClick={() => { setSelectedYear('') }}>Clear Year</p>}
                {startDate && <p className='clear_filters' onClick={() => { setStartDate('') }}>Clear Date</p>}
                {(selectedConsultant || selectedCourse || selectedMonth || selectedYear || startDate) && <p className='clear_filters' onClick={() => {
                  setSelectedConsultant(''); setSelectedCourse(''); setSelectedMonth(''); setStartDate(''); setSelectedYear('')
                }}>Clear All Filters</p>}
              </div>

            </div>


            <div className='statsBoxSection'>
              <StatsBox statName="Total Leads" field="Total Leads" value={totalLeads} backgroundColor="#FF5733" cursor="pointer" />
              <StatsBox statName="New Leads" field="New" value={newLeads} backgroundColor="#FFC300" cursor="pointer" />
              <StatsBox statName="Followup" field="Followup" startDate={startDate} selectedMonth={selectedMonth} selectedYear={selectedYear} value={follwupLeads} backgroundColor="#3399FF" cursor="pointer" />
              {/* <StatsBox statName="Lost Leads" field="Closed" value={lostLeads} backgroundColor="#66FF33" cursor="pointer" /> */}
              <StatsBox statName="Total Revenue" field="Others" value={totalRevenue} backgroundColor="#87CEEB" cursor="default" />
              {/* <StatsBox statName="Discount" field="Others" value={totalDiscount} backgroundColor="#9966FF" cursor="default" /> */}
              <StatsBox statName="Collected" field="Others" value={totalCollect} backgroundColor="#FF69B4" cursor="default" />
              <StatsBox statName="Due" field="Others" value={totalRevenue - totalDiscount - totalCollect} backgroundColor="#00CED1" cursor="default" />
            </div>
          </div>

          <div className='batches_section'>
            <div className='upcoming_batches' style={{ height: '54vh' }}>
              <p>Up-Coming Batches</p>
              <BatchesBox Batchname="AWS Data Engineering with Snowflake(From Python)" Date="25/04/2024" Time="6:30 AM" Instructor="Santhosh" />
              <BatchesBox Batchname="AWS Data Engineering with Snowflake(From SQL) " Date="15/05/2024" Time="8:00 AM" Instructor="Santhosh" />
              <BatchesBox Batchname="Full Stack Power BI with MS fabric Tools" Date="09/05/2024" Time="8:00 AM" Instructor="Ritesh" />
              <BatchesBox Batchname="DevOps" Date="09/05/2024" Time="7:00 AM" Instructor="Arjun" />
              <BatchesBox Batchname="Full Stack Power BI with MS fabric Tools" Date="06/06/2024" Time="7:00 PM" Instructor="Santhosh" />
              <BatchesBox Batchname="Python for Data Science & Data Analytics" Date="09/05/2024" Time="8:00 PM" Instructor="Koti" />


            </div>

          </div>


        </div>


        {leadData.length > 0 && <div className='charts_section'>
          <div className='charts_filter_section'>

            {/* <FormControl className='admin_homepage_dropdown'>
              <InputLabel id="demo-simple-select-label">Month</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedMonth}
                label="Course"
                onChange={(e) => setSelectedMonth(e.target.value)}
              >
                {
                  months.map((e, id) => {
                    return (
                      <MenuItem value={e.name} key={id}>{e.name}</MenuItem>
                    )
                  })
                }

              </Select>
            </FormControl>

            <FormControl className='admin_homepage_dropdown'>
              <InputLabel id="demo-simple-select-label">Year</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedYear}
                label="Course"
                onChange={(e) => setSelectedYear(e.target.value)}
              >
                {
                  years.map((e, id) => {
                    return (
                      <MenuItem value={e} key={id}>{e}</MenuItem>
                    )
                  })
                }

              </Select>
            </FormControl> */}

          </div>
          <MonthChart leadData={leadData} />
          <div className='two_charts'>
            <SourceChart leadData={leadData} />
            <LocationChart leadData={leadData} />
          </div>
        </div>}

      </div>

      <div style={{ marginTop: '50px', width: "100%", overflowX: 'hidden' }}>
        <Footer />
      </div>


    </div>
  )
}
