import './App.css';
import { useState,useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MainContext } from './ContextStore/MainContext';

import Login from './Screens/Login/Login';
import Home from './Screens/Home/Home';
import LandingPage from './Screens/LandingPage/LandingPage';
import ConsultantManagement from './Screens/ConsultantManagement/ConsultantManagement';
import LeadManagement from './Screens/LeadManagement/LeadManagement';
import LeadDetails from './Screens/LeadDetails/LeadDetails';
import ContactLead from './Screens/ContactLead/ContactLead';
import Resetpassword from './Screens/Resetpassword/Resetpassword';
import ResetEmail from './Screens/Resetpassword/ResetEmail';
import Settings from './Screens/Settings/Settings';
import ConsultantDashboard from './Screens/ConsultantDashboard/ConsultantDashboard';
import LeadDashboard from './Screens/LeadDashboard/LeadDashboard';
import CustomDataGrid from './Screens/Login/CustomDataGrid';
import ConsultantSettings from './Screens/ConsultantSettings/ConsultantSettings';
import LeadPaymentDetails from './Screens/LeadPaymentDetails/LeadPaymentDetails';
import AdminHomePage from './Screens/AdminHomePage/AdminHomePage';
import ConsultantHomePage from './Screens/ConsultantHomePage/ConsultantHomePage';
import TemlateForm from './Screens/TemplateForm/TemplateForm'
import TemplateForm from './Screens/TemplateForm/TemplateForm';
import PageNotFound from './Screens/PageNotFound';
import Profile from './Screens/Profile/Profile';
import WhatsAppIntegration from './Screens/WhatsAppIntegration/WhatsAppIntegration';
// import Test from './Components/Test';

import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

function App() {
  const date = new Date();
  const leadYear = date.getFullYear();

  const BASE_URL="https://ksrcrmbackend.aiinterviewhub.com/"
  // const BASE_URL="http://localhost:8000/"

  const [email, setEmail] = useState("")
  const [role, setRole] = useState("")
  const [selectedOption, setSelectedOption] = useState("Consultant Management")
  const [selectedSubOption, setSelectedSubOption] = useState("")
  const [leadDetails, setLeadDetails] = useState()
  const [rowDetails, setRowDetails] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [openSettingsModal, setopenSettingsModal] = useState(false)
  const [selectedSettingOption, setSelectedSettingOption] = useState("")
  const [isContactLeadClicked, setIsContactLeadClicked] = useState(false)
  const [openPaymentModal, setOpenPaymentModal] = useState(false)
  const [leadEmailsList, setLeadEmailsList] = useState([])
  const [leadMobilesList, setLeadMobilesList] = useState([])
  const [paymentDetails, setPaymentDetails] = useState([])
  const [selectedConsultant, setSelectedConsultant] = useState('')
  const [selectedCourse, setSelectedCourse] = useState('')
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('')
  const [startDate, setStartDate] = useState('')
  const [selectedStat, setSelectedStat] = useState('')
  const [selectedChartSource, setSelectedChartSource] = useState('')
  const [selectedChartLocation, setSelectedChartLocation] = useState('')
  const [leadData1, setLeadData1] = useState([])
  const [openCustomNotesModal, setOpenCustomNotesModal] = useState(false)
  const [customNotes, setCustomNotes] = useState('')
  const [openTemplateModal, setOpenTemplateModal] = useState(false)

  const [consultantList, setConsultantList] = useState([])
  const [adminLeadData, setAdminLeadData] = useState([])

  const [emailTemplateList, setEmailTemplateList] = useState([])
  const [whatsappTemplateList, setWhatsappTemplateList] = useState([])
  const [genderList, setGenderList] = useState([])
  const [ageList, setAgeList] = useState([])
  const [courseInterested, setCourseInterested] = useState([])
  const [leadStatusList, setLeadStatusList] = useState([])
  const [leadSourceList, setLeadSourceList] = useState([])
  const [campaignList, setCampaignList] = useState([])

  const [consultantLeadData, setConsultantLeadData] = useState([])








  const [consultantEditedData, setConsultantEditedData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    mobile: '',
    role: 'consultant',
  });
  

  const [leadEditedData, setLeadEditedData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    incorrect_email_flag: '',
    mobile: '',
    incorrect_mobile_flag: '',
    org_id: '1',
    state: '',
    city: '',
    gender: '',
    age: '',
    highest_qualification: '',
    year_of_passing: '',
    course_interested: '',
    reason_for_taking_this_course: '',
    occupation: '',
    level_1: '',
    level_2: '',
    level_3: '',
    lead_status: '',
    source_url: '',
    lead_source: '',
    lead_medium: '',
    device: '',
    campaign: '',
    primary_consultant: '',
    secondary_consultant: '',
    escalation_consultant: '',
    escalation_notes: '',
    followup_date: '',
    priority: '',
    batch: '',
    batch_start_date: '',
    date_enrolled: '',
    total_fees: '',
    fees_paid: '',
    fees_due: '',
    payment_done_date: '',
    payment_reminder_date: '',
    notes: '',
    custom_1: '',
    custom_2: '',
    custom_3: '',
    custom_4: '',
    custom_5: '',
    custom_6: '',
    custom_7: '',
    custom_8: '',
    custom_9: '',
    custom_10: '',
    custom_11: '',
    incorrect_lead_flag: ''
  });

  useEffect(() => {
    const decryptedRole = Cookies.get("role") ? CryptoJS.AES.decrypt(Cookies.get("role"), 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString(CryptoJS.enc.Utf8) : null;

    setRole(decryptedRole)
    // console.log("role",decryptedRole)
  }, []);



  return (
    <MainContext.Provider
      value={{
        email, setEmail,
        role, setRole,
        selectedOption, setSelectedOption,
        openModal, setOpenModal,
        selectedSubOption, setSelectedSubOption,
        leadDetails, setLeadDetails,
        rowDetails, setRowDetails,
        consultantEditedData, setConsultantEditedData,
        leadEditedData, setLeadEditedData,
        openSettingsModal, setopenSettingsModal,
        selectedSettingOption, setSelectedSettingOption,
        isContactLeadClicked, setIsContactLeadClicked,
        leadEmailsList, setLeadEmailsList,
        leadMobilesList, setLeadMobilesList,
        openPaymentModal, setOpenPaymentModal,
        paymentDetails, setPaymentDetails,
        selectedMonth, setSelectedMonth,
        selectedYear, setSelectedYear,
        selectedConsultant, setSelectedConsultant,
        selectedCourse, setSelectedCourse,
        startDate, setStartDate,
        selectedStat, setSelectedStat,
        selectedChartSource, setSelectedChartSource,
        selectedChartLocation, setSelectedChartLocation,
        leadData1, setLeadData1,
        openCustomNotesModal, setOpenCustomNotesModal,
        customNotes, setCustomNotes,
        consultantList, setConsultantList,
        adminLeadData, setAdminLeadData,
        openTemplateModal, setOpenTemplateModal,
        consultantLeadData, setConsultantLeadData,

        BASE_URL,
        emailTemplateList, setEmailTemplateList,
        whatsappTemplateList, setWhatsappTemplateList,
        genderList, setGenderList,
        ageList, setAgeList,
        courseInterested, setCourseInterested,
        leadStatusList, setLeadStatusList,
        leadSourceList, setLeadSourceList,
        campaignList, setCampaignList
      }}>

      <Router>
        <Routes>
          <Route path="/" exact element={<LandingPage />} />
          <Route path="/whatsapp-integration" exact element={<WhatsAppIntegration />} />

          <Route path="/login" exact element={<Login />} />
          <Route path="/profile" exact element={<Profile />} />


          <Route path="/admin-home" element={<AdminHomePage />} />
          <Route path="/consultant-home" element={<ConsultantHomePage />} />

          <Route path="/consultant-management" element={<ConsultantManagement />} />
          <Route path="/lead-management" element={<LeadManagement />} />
          <Route path="/lead-details" element={<LeadDetails />} />
          <Route path="/contact-lead" element={<ContactLead />} />
          <Route path="/lead-settings" element={<Settings />} />
          <Route path="/reset-password" element={<Resetpassword />} />
          <Route path="/reset-email" element={<ResetEmail />} />
          {/* <Route path="/consultant-dashboard" element={<ConsultantDashboard />} /> */}
          <Route path="/lead-dashboard" element={<LeadDashboard />} />
          <Route path="/consultant-settings" element={<ConsultantSettings />} />
          <Route path="/lead-payment-details" element={<LeadPaymentDetails />} />
          <Route path="/template-form" element={<TemplateForm />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/page-not-found" element={<PageNotFound />} />








        </Routes>
      </Router>
    </MainContext.Provider>

  );
}

export default App;
