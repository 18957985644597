import React from 'react'
import './Footer.css'
import { AiOutlineMail, AiOutlineWhatsApp, AiFillPhone, AiFillFacebook, AiOutlineInstagram, AiFillYoutube } from 'react-icons/ai'
import { FaTelegram } from 'react-icons/fa'
import { AiFillLinkedin } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'




export default function Footer() {
    const navigate = useNavigate()
    return (
        <div>
            <div className='footer_section'>
                <div className='download_app_section'>
                    <p className='footer_headings'>Find Us On</p>
                    <div className='storeimages'>
                        <img src='/playstore.png' alt='playstore' className='playstore_image' onClick={() => window.open('https://play.google.com/store/apps/details?id=com.datavizon.courses&hl=en-IN', '_blank')}></img>
                        <img src='/appstore.png' alt='appstore' className='playstore_image' onClick={() => window.open('https://apps.apple.com/in/app/ksr-datavizon/id1611034268', '_blank')}></img>
                    </div>
                </div>

                <div className='contactus_section'>
                    <p className='footer_headings'>Contact Us</p>
                    <p className='footer_text' style={{marginTop:'35px'}}>RJ Gardens, Marathahalli</p>
                    <p className='footer_text'>Bangalore, Karnataka</p>
                    <p>Call : +91 8951796123</p>
                    <p>Call : +91 9916961234</p>
                    <p>support@datavizon.com</p>
                </div>

                <div>
                    <p className='footer_headings courses-header-footer'>Courses</p>
                    <div className='footer_courses_list'>
                        <a className='footer_text1' target='_blank' href='https://ksrdatavision.com/course/Data Science With Generative AI'>Data Science With Generative AI</a>
                        <a className='footer_text1' target='_blank' href='https://ksrdatavision.com/course/AWS Data Engineering with Snowflake'>AWS Data Engineering with Snowflake</a>
                        <a className='footer_text1' target='_blank' href='https://ksrdatavision.com/course/Power Platform Developer'>Power Platform Developer</a>
                        <a className='footer_text1' target='_blank' href='https://ksrdatavision.com/course/Azure Data Engineering'>Azure Data Engineering</a>
                        <a className='footer_text1' target='_blank' href='https://ksrdatavision.com/course/Full Stack PowerBI with MS Fabric Tools'>Full Stack Power Bi with MS Fabric Tools</a>
                        <a className='footer_text1' target='_blank' href='https://ksrdatavision.com/course/DevOps'>DevOps</a>

                    </div>
                </div>

                <div className='social_media_section'>
                    <p className='footer_headings'>Follow Us</p>
                    <p className='footer_text'>Social Links</p>
                    <div className='social_media_icons'>

                        <a href='https://twitter.com/ksrdatavizon' target='_blank' rel="noreferrer"><img src='/twitter.png' className='footer_icons_image' alt='x'></img></a>
                        <a href='https://www.facebook.com/KSRConsultingServices/' target='_blank' rel="noreferrer"><AiFillFacebook className='footer_icons' /></a>
                        <a href='https://www.instagram.com/ksr_datavision/' target='_blank' rel="noreferrer"> <AiOutlineInstagram className='footer_icons' /></a>
                        <a href='https://wa.me/918951796123' target='_blank' rel="noreferrer"><AiOutlineWhatsApp className='footer_icons' /></a>
                        <a href='https://t.me/ksrdatavizon' target='_blank' rel="noreferrer"><FaTelegram className='footer_icons' /></a>
                        <a href='https://www.youtube.com/c/KSRDatavizon' target='_blank' rel="noreferrer"><AiFillYoutube className='footer_icons' /></a>
                        <a href='https://www.linkedin.com/company/datavizon/' target='_blank' rel="noreferrer" style={{ paddingLeft: "5px" }}><AiFillLinkedin className='footer_icons' /></a>



                    </div>

                </div>

            </div>

            <div>
                <p className='sub_footer'>© 2024 KSR DataVizon | All Rights Reserved </p>
            </div>
        </div>

    )
}
