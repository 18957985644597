import React, { useState, useContext, useEffect } from 'react';
import './Login.css'
import { useNavigate } from 'react-router-dom';
import { MdEmail } from "react-icons/md";
import { FaLock } from "react-icons/fa";
import ReactLoading from 'react-loading';
import toast, { Toaster } from 'react-hot-toast';
import { MainContext } from '../../ContextStore/MainContext';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import Footer from '../../Components/FooterComponent/Footer'

import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';




export default function Login() {

    const { email, setEmail, setRole, BASE_URL } = useContext(MainContext)
    const [password, setPassword] = useState('')
    const [isLoading, setisLoading] = useState(false)



    const navigate = useNavigate()

    // useEffect(()=>{
    //     const sendToPhoneNumber='916305333768'
    //     fetch(`https://botsailor.com/api/v1/whatsapp/send/template?apiToken=4764%7ChgJZoDg4Vbe8DIq3V2sWF6XhyIUv8e6ux8JpHp2B&phoneNumberID=241749175682154&botTemplateID=55463&sendToPhoneNumber=${sendToPhoneNumber}`,{method:'GET'})
    //     .then(response => {
    //         return response.json()
    //     })
    //     .then(data=>{
    //         console.log(data);
    //     })
    //     .catch(err=>{
    //         console.log(err);
    //     })
    // },[])

    useEffect(() => {
        // Fetch templates when the component mounts
        // fetchTemplates()
    }, []);

    const fetchTemplates = async () => {
        const apiKey = '4764|hgJZoDg4Vbe8DIq3V2sWF6XhyIUv8e6ux8JpHp2B'
        await fetch('https://botsailor.com/api/v1/list/template', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${apiKey}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                console.log(response);
                return response.json()
            })
            .then(data => {
                console.log(data);
            })
            .catch(err => {
                console.log(err);
            })
    }



    const signIn = (event) => {
        event?.preventDefault();
        setisLoading(true)
        const lowerEmail=email.toLowerCase()
        if (email === '' || password === '') {
            toast.error("Please enter all the credentials")
            setisLoading(false)
            return
        }

        let formData = new FormData();
        formData.append('username', lowerEmail);
        formData.append('password', password);


        const requestOptions = {
            method: 'POST',
            body: formData
        }
        fetch(`${BASE_URL}login`, requestOptions)
            .then(response => {
                setisLoading(false)
                return response.json()
            })
            .then(async data => {

                // console.log(data);

                if (data.detail === "Incorrect password") {
                    toast.error(data.detail)

                }
                else if (data.detail === "Invalid credentials") {
                    toast.error(data.detail)
                }
                else {
                    setRole(data.role);
                    const encryptedRole = CryptoJS.AES.encrypt(data.role, 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString();
                    const encryptedToken = CryptoJS.AES.encrypt(data.access_token, 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString();
                    const encryptedEmail = CryptoJS.AES.encrypt(data.email, 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString();


                    Cookies.set('role', encryptedRole, { expires: 7 })
                    Cookies.set('token', encryptedToken, { expires: 7 })
                    Cookies.set('email', encryptedEmail, { expires: 7 })
                    Cookies.set('id', data.user_id, { expires: 7 })
                    // console.log(data);

                    if (data.role === "admin") {
                        const encryptedSelectedOption = CryptoJS.AES.encrypt("Consultant Management", 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString();
                        Cookies.set('selectedOption', encryptedSelectedOption, { expires: 7 })
                        navigate("/admin-home")
                        localStorage.setItem('adminName', data.firstName);

                        fetch(`${BASE_URL}consultant/consultants`)
                            .then(response => {
                                if (!response.ok) throw new Error('Failed to fetch data');
                                return response.json();
                            })
                            .then(data1 => {
                                const userIdToFind = data.user_id;
                                const user = data1.find(user => user.id === userIdToFind);

                                if (user) {
                                    const fullName = `${user.firstname} ${user.lastname}`;
                                    localStorage.setItem('consultantName', fullName);
                                    localStorage.setItem('consultantMobile', user.mobile);
                                    localStorage.setItem('consultantEmail', email);
                                    localStorage.setItem('consultantId', data.user_id);
                                } else {
                                    console.error('User not found');
                                }
                            })
                            .catch(error => {
                                alert('Server error. Please try again later...');
                            });
                    }
                    else if (data.role === "consultant") {

                        fetch(`${BASE_URL}consultant/consultants`)
                            .then(response => {
                                if (!response.ok) throw new Error('Failed to fetch data');
                                return response.json();
                            })
                            .then(data1 => {
                                const userIdToFind = data.user_id;
                                const user = data1.find(user => user.id === userIdToFind);

                                if (user) {
                                    const fullName = `${user.firstname} ${user.lastname}`;
                                    localStorage.setItem('consultantName', fullName);
                                    localStorage.setItem('consultantEmail', email);
                                    localStorage.setItem('consultantMobile', user.mobile);
                                    localStorage.setItem('consultantId', data.user_id);
                                } else {
                                    console.error('User not found');
                                }
                            })
                            .catch(error => {
                                alert('Server error. Please try again later...');
                            });
                        const encryptedSelectedOption = CryptoJS.AES.encrypt("Lead Management", 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString();
                        Cookies.set('selectedOption', encryptedSelectedOption, { expires: 7 })
                        navigate("/consultant-home")
                    }

                }

            })
            .catch(error => {
                console.log(error);
                setisLoading(false)
                toast.error('Server error. Please try again later.')
            })


    }

    const [selectedTime, setSelectedTime] = useState(null);

    const handleTimeChange = (value) => {
        setSelectedTime(value);
    };

    return (
        <div className='CentralPart1'>
            <div className='login_header_section' style={{ width: "98.5%", overflowX: 'hidden' }}>
                <img src='/ksr_main_logo.png' alt='ksr datavizon' className='header_logo_login'></img>
            </div>
            <div className='LoginSection'>
                <div className='image_section_login'>
                    <img src='/ksr_main_logo.png' alt='ksr datavizon'></img>
                </div>
                <p className='LoginHeading1'>Welcome</p>
                <p className='LoginHeading2'>Please enter your login details</p>
                <div className='LoginPart'>
                    {/* <p className='username1'>Email</p> */}
                    <div className='InputsWithIcons1'>
                        <MdEmail className='InputIcon' />
                        <input type="text" placeholder="Email" style={{ border: "none", outline: "none" }} className='Inputs5' value={email} onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>

                    {/* <p className='Password1'>Password</p> */}
                    <div className='InputsWithIcons1'>
                        <FaLock className='InputIcon' />
                        <input placeholder="Password" type="Password" style={{ border: "none", outline: "none" }} className='Inputs5' value={password} onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    signIn();
                                }
                            }}
                        />
                    </div>

                    <div type='submit' style={{ textDecoration: "none" }} onClick={signIn} className='LoginButton'>
                        {
                            !isLoading ?
                                <span>Login</span> :
                                <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                        }
                    </div>
                    <div className='forgot_credentials_section'>
                        <p className='forgot_password' onClick={() => navigate('/reset-password')}>Forgot Password?</p>
                        <p className='forgot_password' onClick={() => navigate('/reset-email')}>Forgot Email?</p>
                    </div>
                </div>

            </div>
            
            <div style={{ marginTop: '50px', width: "100%", overflowX: 'hidden' }}>
                <Footer />
            </div>

            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: '',
                    duration: 5000,
                    style: {
                        background: 'white',
                        color: '#000',
                    },

                    success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'white',
                        },
                    },
                }}
            />

        </div >
    )
}

