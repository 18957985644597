// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menubar_component{
    background-color: black; width: 18vw;height: 100vh
}
.menubar_ksr_logo{
    width: 18vw;height: 100px;object-fit: contain;
}
.header_section{
    background-color: black;width: 81.5vw;position: absolute;top: 0px;left: 18vw;
}
.logout{
color: white;margin-left: 75vw;cursor: pointer;
}
.menubar_list{
   padding-top: 10px;padding-left: 10px;
}
.menubar_option{
    color: white;padding: 10px;cursor: pointer;padding-top: 0px;font-size: 16px;
}
.menubar_option:hover{
    background-color: #333;
}`, "",{"version":3,"sources":["webpack://./src/Components/MenuBar/MenuBar.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB,EAAE,WAAW,CAAC;AACzC;AACA;IACI,WAAW,CAAC,aAAa,CAAC,mBAAmB;AACjD;AACA;IACI,uBAAuB,CAAC,aAAa,CAAC,kBAAkB,CAAC,QAAQ,CAAC,UAAU;AAChF;AACA;AACA,YAAY,CAAC,iBAAiB,CAAC,eAAe;AAC9C;AACA;GACG,iBAAiB,CAAC,kBAAkB;AACvC;AACA;IACI,YAAY,CAAC,aAAa,CAAC,eAAe,CAAC,gBAAgB,CAAC,eAAe;AAC/E;AACA;IACI,sBAAsB;AAC1B","sourcesContent":[".menubar_component{\n    background-color: black; width: 18vw;height: 100vh\n}\n.menubar_ksr_logo{\n    width: 18vw;height: 100px;object-fit: contain;\n}\n.header_section{\n    background-color: black;width: 81.5vw;position: absolute;top: 0px;left: 18vw;\n}\n.logout{\ncolor: white;margin-left: 75vw;cursor: pointer;\n}\n.menubar_list{\n   padding-top: 10px;padding-left: 10px;\n}\n.menubar_option{\n    color: white;padding: 10px;cursor: pointer;padding-top: 0px;font-size: 16px;\n}\n.menubar_option:hover{\n    background-color: #333;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
