import React, { useState, useEffect, useContext } from 'react'
import { MainContext } from '../../ContextStore/MainContext'
import './Form.css'
import { useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import ReactLoading from 'react-loading';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import {Chip, Box} from '@mui/material'

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

// import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { IoIosArrowDown,IoIosArrowUp } from "react-icons/io";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


export default function Form() {
    const { BASE_URL, consultantEditedData, leadEditedData, setIsContactLeadClicked, selectedSubOption, leadEmailsList,
         leadMobilesList, setOpenModal,setConsultantList,role,setLeadData1 ,leadData1} = useContext(MainContext)
    const [consultantEdit, setConsultantEdit] = useState(consultantEditedData[0])
    const [leadEdit, setLeadEdit] = useState((selectedSubOption === "Add Notes" || selectedSubOption === "Add Notes Call" || selectedSubOption === "Edit Lead1") ? leadEditedData : leadEditedData[0])
    const [isLoading, setIsloading] = useState(false)
    const [emailOTP, setEmailOTP] = useState("")
    const [isOTPSent, setIsOTPSent] = useState(false)
    const [selectedTime, setSelectedTime] = useState('');
    const [callStartTime, setCallStartTime] = useState(null)
    const [callEndTime, setCallEndTime] = useState(null)
    const [isTimeSubmit, setIsTimeSubmit] = useState(false)
    const [leadNotes, setLeadNotes] = useState('')
    const [isPriorityChanged, setIsPriorityChanged] = useState(false)

    const [curreuntLeadStatus, setCurrentLeadStatus] = useState('')
    const [isDateSelected, setIsDateSelected] = useState(false);

    const [viewMoreFields,setViewMoreFields]=useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        leadEdit && localStorage.setItem('notes', leadEdit.notes)
        leadEdit?.followup_date && localStorage.setItem('followup_time', leadEdit?.followup_date.split(' ')[1])
    }, [])




    const timeArray = [
        '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30',
        '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30',
        '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30',
        '00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30',
        '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30',
        '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30'
    ];


    useEffect(() => {
        
        consultantEditedData && setConsultantEdit(consultantEditedData[0]);
        (leadEditedData && (selectedSubOption === "Add Notes" || selectedSubOption === "Edit Lead1")) ? setLeadEdit({ ...leadEditedData, org_id: "KSR" }) : setLeadEdit({ ...leadEditedData[0], org_id: "KSR" });
        leadEdit && setCurrentLeadStatus(leadEdit.lead_status)

    }, [consultantEditedData, leadEditedData]);

    function addTimetoDate() {
        setIsTimeSubmit(true)
        const datePart = leadEdit.followup_date.split(' ')[0];

        const combinedDateTimeString = `${datePart} ${selectedTime}`;

        setLeadEdit(prevLeadData => ({
            ...prevLeadData,
            followup_date: combinedDateTimeString
        }));
    }

    const formatTime = (date) => {
        const hours = padZero(date.getHours());
        const minutes = padZero(date.getMinutes());
        return `${hours}:${minutes}`;

    };

    const padZero = (num) => {
        return num < 10 ? '0' + num : num;
    };



    const [leadData, setLeadData] = useState({
        firstname: 'NA',
        lastname: 'NA',
        email: 'NA',
        incorrect_email_flag: 'false',
        mobile: 'NA',
        incorrect_mobile_flag: 'false',
        org_id: '1',
        state: 'NA',
        city: 'NA',
        gender: 'NA',
        age: '0',
        highest_qualification: 'NA',
        year_of_passing: 'NA',
        course_interested: 'NA',
        reason_for_taking_this_course: 'NA',
        occupation: 'NA',
        level_1: 'NA',
        level_2: 'NA',
        level_3: 'NA',
        lead_status: 'Active',
        source_url: 'NA',
        lead_source: 'NA',
        lead_medium: 'NA',
        device: 'NA',
        campaign: 'NA',
        primary_consultant: 'NA',
        secondary_consultant: 'NA',
        escalation_consultant: 'NA',
        escalation_notes: 'NA',
        followup_date: '',
        priority: 'High',
        batch: 'NA',
        batch_start_date: '',
        date_enrolled: '',
        total_fees: '0',
        fees_paid: '0',
        fees_due: '0',
        payment_done_date: '',
        payment_reminder_date: '',
        notes: 'NA',
        custom_1: '',
        custom_2: '',
        custom_3: '',
        custom_4: '',
        custom_5: '',
        custom_6: '',
        custom_7: '',
        custom_8: '',
        custom_9: '',
        custom_10: '',
        custom_11: '',
        incorrect_lead_flag: 'false'
    });

    const [consultantData, setConsultantData] = useState({
        email: '',
        password: 'Ksr@2024',
        firstname: '',
        lastname: '',
        mobile: '',
        role: '',
        org_id: 'KSR'

    });

    const handleConsultantInputChange = (field, value) => {
        if(field==="email"){
            const lowerEmail=value.toLowerCase()
            setConsultantData({ ...consultantData, [field]: lowerEmail });
        }
        else{
            setConsultantData({ ...consultantData, [field]: value });
        }
    };

    const handleConsultantEditInputChange = (field, value) => {
        setConsultantEdit({ ...consultantEdit, [field]: value });
    };

    const handleInputChange = (field, value) => {
        setLeadData(prevLeadData => ({ ...prevLeadData, [field]: value }));
        if (field === "lead_status") {
            setCurrentLeadStatus(value)
        }

        if (field === "mobile") {
            const now = new Date();
            now.setMinutes(now.getMinutes());
            const formattedTime = formatTime(now)

            const year = now.getFullYear();
            const month = (now.getMonth() + 1);
            const day = now.getDate() + 1;

            const formattedDate = `${year}-${month}-${day}`;

            setLeadData(prevLeadData => ({ ...prevLeadData, followup_date: `${formattedDate} ${formattedTime}` }));
        }

        if (field === "lead_status" && value === "Lost") {
            const nextYear = addOneYearToCurrentDate();
            setLeadData(prevLeadData => ({ ...prevLeadData, followup_date: `${nextYear}` }));
            setLeadData(prevLeadData => ({ ...prevLeadData, priority: 'Very Low' }));
        }
        if (field === "mobile" && role==="consultant") {
            setLeadData(prevLeadData => ({ ...prevLeadData, primary_consultant: localStorage.getItem('consultantEmail') }));
            // fetch(`${BASE_URL}consultant_management/assign_leads`, {
            //     method: "GET"
            // })
            //     .then(response => {
            //         return response.json();
            //     })
            //     .then(data => {
            //         console.log(data);
            //         // setLeadData(prevLeadData => ({ ...prevLeadData, primary_consultant: data }));
            //     })
            //     .catch((err) => {
            //         console.log(err);
            //     })
        }
    };

    const handleLeadEditInputChange = (field, value) => {
        setLeadEdit({ ...leadEdit, [field]: value });
        if (field === "lead_status" && value === "Closed") {
            setCurrentLeadStatus(value)
            const nextYear = addOneYearToCurrentDate();
            setLeadEdit(prevLeadData => ({ ...prevLeadData, followup_date: `${nextYear}` }));
            setLeadEdit(prevLeadData => ({ ...prevLeadData, priority: 'Very Low' }));
        }

        if (field === "priority") {
            setIsPriorityChanged(true)
            toast.error('Please add Notes ...')
        }
        if (field === "lead_status") {
            setCurrentLeadStatus(value)
        }

        if (field === "lead_status" && value == "Lost") {
            toast.success('Please Add Notes ...')
        }

        else if (field === "lead_status" && value == "Paid") {
            toast.success('Please Add Payment Details')
        }
       
        else if (field === "lead_status" && value == "Escalation") {
            toast.success('Please Add Escalation Details')
        }


    };

    function addOneYearToCurrentDate() {
        const currentDate = new Date();

        // Add 1 year to the current date
        const nextYearDate = new Date(currentDate);
        nextYearDate.setFullYear(currentDate.getFullYear() + 1);

        // Format the date as yyyy-mm-dd
        const year = nextYearDate.getFullYear();
        const month = String(nextYearDate.getMonth() + 1).padStart(2, '0');
        const day = String(nextYearDate.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const handleDateChange = (field, date) => {
        const selectedDate = new Date(date);

        const timezoneOffsetInMs = selectedDate.getTimezoneOffset() * 60000;
        const adjustedDate = new Date(selectedDate.getTime() - timezoneOffsetInMs);

        const formattedDate = adjustedDate.toISOString().substring(0, 10);

        setLeadData(prevLeadData => ({ ...prevLeadData, [field]: formattedDate }));

        // if (field === "followup_date") {
        //     if (selectedTime === "") {
        //         setLeadData({ ...leadData, followup_date: `${formattedDate} ${localStorage.getItem('followup_time')}` })
        //     }
        // }
        // else {

        //     setLeadEdit({ ...leadEdit, [field]: formattedDate });
        // }

    };

    const handleLeadEditDateChange = (field, date) => {
        const selectedDate = new Date(date);

        const timezoneOffsetInMs = selectedDate.getTimezoneOffset() * 60000;
        const adjustedDate = new Date(selectedDate.getTime() - timezoneOffsetInMs);

        const formattedDate = adjustedDate.toISOString().substring(0, 10);

        if (field === "followup_date") {
            setIsDateSelected(true)
            if (selectedTime === "") {
                const followuptime=isValidTime(localStorage.getItem('followup_time')) ? localStorage.getItem('followup_time'):"12:00"
                setLeadEdit({ ...leadEdit, followup_date: `${formattedDate} ${followuptime}` })
            }
        }
        else {
            setLeadEdit({ ...leadEdit, [field]: formattedDate });
        }


    };

    const isValidTime = (time) => {
        const timeRegex = /^(?:[01]?\d|2[0-3])[:\-][0-5]?\d$/;
        return timeRegex.test(time);
      };
      


    const isValidEmail = (email) => {
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(email);
    };


    function sendConsultantOtp(email) {
        setIsloading(true)
        if (email === "") {
            toast.error("Please Enter Email")
            setIsloading(false)
            return
        }

        fetch(`${BASE_URL}send_otp?email=${email}`, {
            method: "GET"
        })
            .then(response => {
                // console.log(response);
                return response.json();
            })
            .then(data => {
                // console.log(data);
                if (data) {
                    setIsOTPSent(true)
                    setEmailOTP(data.otp)
                    setIsloading(false)
                }
            })
            .catch((err) => {
                console.log(err);
                setIsloading(false)
                toast.error('Server error. Please try again later...')
            });

    }


    function verifyOTP({ otp, email }) {
        setIsloading(true)
        fetch(`${BASE_URL}verify_otp?otp=${otp}&email=${email}`, {
            method: "GET"
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data === "Incorrect OTP") {
                    toast.error("Incorrect OTP")
                }
                else {

                    fetch(`${BASE_URL}consultant`, {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },

                        body: JSON.stringify(consultantData)
                    })
                        .then(response => {
                            return response.json();
                        })
                        .then(data => {
                            setIsloading(false)
                            if (data.email) {
                                toast.success("Added Successfully")
                                window.location.reload()
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            setIsloading(false)
                            toast.error('Error Adding Data1');
                        });
                }
                setIsloading(false);
            })
            .catch((err) => {

                setIsloading(false)
                toast.error('Server error. Please try again later...')
            });



    }

    function checkEmail(data) {
        
        const exists = leadEmailsList.includes(data?.email);
        if (exists && data?.email!=="" && data?.email!=="NA") {
            toast.error("Email already exists")
            setIsloading(false)
            return true
        }
        return false
    }

    function checkMobile(leadData) {
        let exist=false
        console.log(leadData.mobile);
        fetch(`http://localhost:8000/user/verify_email_mobile?mobile=${leadData?.mobile}&user_email=na&user=lead`, {
            method: "GET",
        })
        .then(response => {
                return response.json();
        })
        .then(data => {
            console.log(data);
            if (data==="Success") {
                exist=true
            }
            else{
                 exist=false
            }
        })
        .catch(error => {
            toast.error('Server error. Please try again later...')
        });
        return exist
    }

    const submitLead = async () => {

        setIsloading(true)

       
        if ((leadData.mobile === "NA")) {
            toast.error("Please fill Mobile")
            setIsloading(false)
            return
        }
        // if (!isValidEmail(leadData.email)) {
        //     toast.error("Invalid Email")
        //     setIsloading(false)
        //     return;
        // }

        if (leadData.mobile.length < 10) {
            toast.error("Invalid Mobile")
            setIsloading(false)
            return;
        }
        if (role==="admin" && leadData.primary_consultant==="NA") {
            toast.error("Invalid Primary Consultant")
            setIsloading(false)
            return;
        }

        if((leadData.lead_status==="Escalation" && (leadData.escalation_consultant!=="Mahesh" || leadData.escalation_notes==="" || leadData.escalation_notes==="NA"))){
            toast.error("Please fill  Escalation Details")
            setIsloading(false)
            return
        }
 
        fetch(`${BASE_URL}user/verify_email_mobile?mobile=${leadData?.mobile}&user_email=na&user=lead`, {
            method: "GET",
        })
        .then(response => {
                return response.json();
        })
        .then(data => {
           
            if (data==="Success") {
                fetch(`${BASE_URL}lead`, {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },

                        body: JSON.stringify(leadData)
                    })
                        .then(response => {
                            setIsloading(false)
                            return response.json();
                        })
                        .then(data => {
                            if (data.email) {
                                toast.success("Added Successfully")
                                setIsloading(false)
                                window.location.reload()
                            }
                        })
                        .catch(error => {
                            setIsloading(false)
                            toast.error('Server error. Please try again later...')
                        });
    
            }
            else{
                toast.error('Mobile already exists')
                setIsloading(false)
            }
        })
        .catch(error => {
            toast.error('Server error. Please try again later...')
        });

        //  if (!isEmailExists && !isMobileExist) {
       


      



    };




    const submitConsultant = () => {
        setIsloading(true)
        const emptyFields = Object.entries(consultantData).filter(([key, value]) => {
            return value === '' || value === null;
        });

        if (emptyFields.length > 0) {
            setIsloading(false)
            const emptyFieldNames = emptyFields.map(([key, value]) => key).join(', ');
            toast.error(`Please fill the following fields: ${emptyFieldNames}`);
            return;
        }

        if (!isValidEmail(consultantData.email)) {
            toast.error("Invalid Email")
            setIsloading(false)
            return;
        }

        if (consultantData.mobile.length < 10) {
            toast.error("Invalid Mobile")
            setIsloading(false)
            return;
        }





        fetch(`${BASE_URL}user/verify_email_mobile?mobile=${consultantData.mobile}&user_email=${consultantData.email}`, {
            method: "GET"
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data.detail === `Email ${consultantData.email} already exists`) {
                    setIsloading(false)
                    toast.error(`Email ${consultantData.email} already exists`)
                }
                else if (data.detail === `Mobile number ${consultantData.mobile} already exists`) {
                    setIsloading(false)
                    toast.error(`Mobile number ${consultantData.mobile} already exists`)
                }
                else if (data === "Success") {

                    fetch(`${BASE_URL}consultant`, {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },

                        body: JSON.stringify(consultantData)
                    })
                        .then(response => {
                            return response.json();
                        })
                        .then(data => {
                            setIsloading(false)
                            if (data.id) {
                                toast.success("Added Successfully")
                                setConsultantList(prevList => [...prevList, data]);
                                setTimeout(()=>{
                                    setOpenModal(false)
                                },1000)
                            }
                            else{
                                toast.error('Error Occured')
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            setIsloading(false)
                            toast.error('Failed to add Consultant');
                        });


                }
                else {
                    setIsloading(false)
                    toast.error("Error Occured2")
                }
            })
            .catch(error => {
                setIsloading(false)
                toast.error('Server error. Please try again later...');
            });


    };

    const EditConsultant = () => {

        setIsloading(true)
        const emptyFields = Object.entries(consultantEdit).filter(([key, value]) => {
            return value === '' || value === null;
        });

        if (emptyFields.length > 0) {
            setIsloading(false)
            const emptyFieldNames = emptyFields.map(([key, value]) => key).join(', ');
            toast.error(`Please fill the following fields: ${emptyFieldNames}`);
            return;
        }

        if (!isValidEmail(consultantEdit.email)) {
            toast.error("Invalid Email")
            setIsloading(false)
            return;
        }

        if (consultantEdit.mobile.length < 10) {
            toast.error("Invalid Mobile")
            setIsloading(false)
            return;
        }

        fetch(`${BASE_URL}consultant/consultants/{user_id}?consultant_id=${consultantEdit.id}`, {
            method: "PUT",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },

            body: JSON.stringify(consultantEdit)
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data.id) {
                    toast.success("Updated ")
                    setIsloading(false)
                    setConsultantList(prevList =>
                        prevList.map(consultant =>
                          consultant.id === data.id ? data : consultant
                    ))
                    setTimeout(()=>{
                        setOpenModal(false)
                    },1000)
                }
                else{
                    toast.error("Error occured ")
                }

            })
            .catch(error => {
                setIsloading(false)
                toast.error('Error Occured');
            });

    };

    const EditLead = async () => {
        // console.log(selectedTime);


        setIsloading(true)

        if (isPriorityChanged && localStorage.getItem('notes') === leadEdit.notes) {
            toast.error('Please add the notes as well ...')
            setIsloading(false)
            return
        }


        
        if (( leadEdit.mobile === "NA")) {
            toast.error("Please fill Mobile")
            setIsloading(false)
            return
        }
        // if (!isValidEmail(leadEdit.email)) {
        //     toast.error("Invalid Email")
        //     setIsloading(false)
        //     return;
        // }

        if (leadEdit.mobile.length < 10) {
            toast.error("Invalid Mobile")
            setIsloading(false)
            return;
        }

        // if (leadEdit.lead_source === "NA") {
        //     toast.error("Please fill  Lead Source")
        //     setIsloading(false)
        //     return
        // }

        if((leadEdit.lead_status==="Escalation" && (leadEdit.escalation_consultant!=="Mahesh" || leadEdit.escalation_notes==="" || leadEdit.escalation_notes==="NA"))){
            toast.error("Please fill  Escalation Details")
            setIsloading(false)
            return
        }


        // const isEmailExists=checkEmail(leadEdit)
        // const isMobileExist=checkMobile(leadEdit)

        //  if (!isEmailExists && !isMobileExist) {
        // }
        submitEditedLeadData()

    };



    const submitEditedLeadData = async () => {
        const { lead_id, ...newLeadEdit } = leadEdit;
        const decryptedEmail = Cookies.get("email") ? CryptoJS.AES.decrypt(Cookies.get("email"), 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString(CryptoJS.enc.Utf8) : null;

        decryptedEmail && newLeadEdit && await fetch(`${BASE_URL}lead/leads/{consultant_id}?lead_id=${leadEdit.lead_id}&consultant_name=${leadEdit.consultant_assigned}&consultant_email=${decryptedEmail}`, {
            method: "PUT",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },

            body: JSON.stringify(leadEdit)
        })
            .then((response, err) => {
                if (!response.ok) {
                    toast.error("Error Occured")
                }
                if (response.ok)
                    return response.json();
            })
            .then(data => {
                if (data) {
                    console.log(data);
                    toast.success("Updated")
                    setIsloading(false)
                    localStorage.setItem("leadData", JSON.stringify(data))
                    window.location.reload()
                    // setLeadData1((prevLeads) => 
                    //     {console.log(prevLeads)
                    //     prevLeads.map((lead) => 
                    //         lead.lead_id === leadData1.lead_id ? { ...lead, ...leadData1 } : lead
                    //     )}
                    // );
                }
            })
            .catch(error => {
                setIsloading(false)
                toast.error("Error Occured")
            });
    }


    const EditNotes = () => {
        setIsloading(true)
        if (leadEdit.notes === "NA" || leadEdit.notes === "") {
            toast.error("Please fill Notes")
            setIsloading(false)
            return
        }


        const { lead_id, ...newLeadEdit } = leadEdit;
        // console.log(leadEdit);
        const decryptedEmail = Cookies.get("email") ? CryptoJS.AES.decrypt(Cookies.get("email"), 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString(CryptoJS.enc.Utf8) : null;

        decryptedEmail && newLeadEdit && fetch(`${BASE_URL}lead/add_notes/{consultant_id}?lead_id=${leadEdit.lead_id}&consultant_name=${leadEdit.consultant_assigned}&consultant_email=${decryptedEmail}`, {
            method: "PUT",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },

            body: JSON.stringify(leadEdit)
        })
            .then((response, err) => {
                return response.json();
            })
            .then(data => {
                console.log(data);
                if (data) {
                    toast.success("Updated")
                    setIsloading(false)
                    localStorage.setItem("leadData", JSON.stringify(data))
                    window.location.reload()

                }
            })
            .catch(error => {
                console.log(error);
                setIsloading(false)
                toast.error("Error Occured")
                setIsloading(false)
            });

    };

    const AddNotes = () => {
        setIsloading(true)
        if (leadNotes === "") {
            toast.error("Please fill Notes")
            setIsloading(false)
            return
        }

        const decryptedEmail = Cookies.get("email") ? CryptoJS.AES.decrypt(Cookies.get("email"), 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString(CryptoJS.enc.Utf8) : null;

        decryptedEmail && fetch(`${BASE_URL}lead/add_notes/{consultant_id}?lead_id=${leadEdit.lead_id}&consultant_name=${leadEdit.primary_consultant}&consultant_email=${decryptedEmail}&notes=${leadNotes}`, {
            method: "PUT",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },

        })
            .then((response, err) => {
                return response.json();
            })
            .then(data => {
                console.log(data);
                if (data) {
                    toast.success("Updated")
                    setIsloading(false)
                    // localStorage.setItem("leadData", JSON.stringify(data))
                    window.location.reload()

                }
            })
            .catch(error => {
                console.log(error);
                setIsloading(false)
                toast.error("Error Occured")
                setIsloading(false)
            });

    };

    const AddCallNotes = () => {
        setIsloading(true)
        if (leadNotes === "") {
            toast.error("Please fill Notes")
            setIsloading(false)
            return
        }

        const decryptedEmail = Cookies.get("email") ? CryptoJS.AES.decrypt(Cookies.get("email"), 'dGhpc2lzcmVldGtleXMhJCR6ZXJnZXQlr1a1v1i1').toString(CryptoJS.enc.Utf8) : null;

        decryptedEmail && fetch(`${BASE_URL}lead/add_call_details/{consultant_id}?lead_id=${leadEdit.lead_id ? leadEdit.lead_id : JSON.parse(localStorage.getItem('leadData'))['lead_id']}
        &consultant_name=${localStorage.getItem('consultantName')}&consultant_email=${localStorage.getItem('consultantEmail')}&from_time=${callStartTime}&to_time=${callEndTime}&notes=${leadNotes}`, {
            method: "PUT",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },

        })
            .then((response, err) => {
                return response.json();
            })
            .then(data => {
                console.log(data);
                if (data) {
                    toast.success("Updated")
                    setIsloading(false)
                    window.location.reload()

                }
            })
            .catch(error => {
                console.log(error);
                setIsloading(false)
                toast.error("Error Occured")
                setIsloading(false)
            });

    };

    const getSelectedValues = (str) => {
        return str ? str.split(', ') : [];
      };
    
      const handleSelectChange = (event) => {
        let valueArray = event.target.value;
    
        if (valueArray.length === 0) {
            valueArray = ["NA"];
        } else {
            valueArray = valueArray.filter(value => value !== "NA");
        }
    
        const valueString = valueArray.join(', ');
        handleInputChange('course_interested', valueString);
    };

    const handleSelectChange1 = (event) => {
        let valueArray = event.target.value;
    
        if (valueArray.length === 0) {
            valueArray = ["NA"];
        } else {
            valueArray = valueArray.filter(value => value !== "NA");
        }
    
        const valueString = valueArray.join(', ');
        handleLeadEditInputChange('course_interested', valueString);
    }

     

      const getCurrentTime = () => {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`
      };



    const StatesList = [
        "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa",
        "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala",
        "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland",
        "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana",
        "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal"
    ];


    return (
        <div className='form_componant'>
            {
                selectedSubOption === "Add New Consultant" &&
                <div>
                    <p className='form_heading'>Add New Consultant</p>

                    <div>
                        <TextField variant="outlined" id="outlined-basic" label="First Name" className='form_input'
                            onChange={(e) => handleConsultantInputChange('firstname', e.target.value)}
                        />

                        <TextField variant="outlined" id="outlined-basic" type='text' label='Last Name' className='form_input'
                            onChange={(e) => handleConsultantInputChange('lastname', e.target.value)}
                        />

                        <TextField variant="outlined" id="outlined-basic" type='text' label='Email' className='form_input'
                            onChange={(e) => handleConsultantInputChange('email', e.target.value)}
                        />

                        <TextField variant="outlined" id="outlined-basic" type='text' label='Contact Number' className='form_input'
                            onChange={(e) => handleConsultantInputChange('mobile', e.target.value)}
                        />

                        <FormControl className='form_input dropdown_input'>
                            <InputLabel id="demo-simple-select-label">Role</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={consultantData.role}
                                label="Role"
                                onChange={(e) => handleConsultantInputChange('role', e.target.value)}
                            >
                                <MenuItem value="consultant">consultant</MenuItem>
                                <MenuItem value="admin">admin</MenuItem>

                            </Select>
                        </FormControl>

                        <div className='form_submit_button' style={{ backgroundColor: "var(--mainColor)" }} onClick={() => {
                            submitConsultant()
                        }}>
                            {
                                !isLoading ?
                                    <span>Submit</span> :
                                    <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                            }
                        </div>
                    </div>




                </div>
            }


            {
                selectedSubOption === "Edit Consultant" &&
                <div>
                    <p className='form_heading'>Edit Consultant</p>
                    <TextField variant="outlined" id="outlined-basic" type='text' value={consultantEdit?.firstname} label='First Name' className='form_input'
                        onChange={(e) => handleConsultantEditInputChange('firstname', e.target.value)}
                    />

                    <TextField variant="outlined" id="outlined-basic" type='text' value={consultantEdit?.lastname} label='Last Name' className='form_input'
                        onChange={(e) => handleConsultantEditInputChange('lastname', e.target.value)}
                    />

                    <TextField variant="outlined" id="outlined-basic" type='text' value={consultantEdit?.email} label='Email' className='form_input'
                        onChange={(e) => handleConsultantEditInputChange('email', e.target.value)}
                    />

                    <TextField variant="outlined" id="outlined-basic" type='text' value={consultantEdit?.mobile} label='Contact Number' className='form_input'
                        onChange={(e) => handleConsultantEditInputChange('mobile', e.target.value)}
                    />



                    <FormControl className='form_input dropdown_input'>
                        <InputLabel id="demo-simple-select-label">Role</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={consultantEdit?.role}
                            label="Role"
                            onChange={(e) => handleConsultantEditInputChange('role', e.target.value)}
                        >
                            <MenuItem value="consultant">consultant</MenuItem>
                            <MenuItem value="admin">admin</MenuItem>

                        </Select>
                    </FormControl>
                    <div className='form_submit_button' style={{ backgroundColor: "var(--mainColor)" }} onClick={EditConsultant}>
                        {
                            !isLoading ?
                                <span>Submit</span> :
                                <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                        }
                    </div>
                </div>
            }


            {
                selectedSubOption === "Add New Lead" &&
                <div style={{ width: "65vw" }}>
                    <p className='form_heading'>Add New Lead1</p>

                    <TextField variant="outlined" id="outlined-basic" type='text' label='First Name' className='form_input'
                        onChange={(e) => handleInputChange('firstname', e.target.value)}
                    />

                    

                    {/* <TextField variant="outlined" id="outlined-basic" type='text' label='Mobile' className='form_input'
                        onChange={(e) => handleInputChange('mobile', e.target.value)}
                        // onBlur={() => checkMobile()}
                    /> */}


                <div style={{width:"20vw",display:"inline-block",marginTop:"5px"}}>
                    <PhoneInput
                    country={'in'}
                    style={{height:"55px"}}
                    inputClass='phoneNumberInput'
                    // value={this.state.phone}
                    onChange={phone =>  handleInputChange('mobile', phone)}
                    />
              </div>

                    <TextField variant="outlined" id="outlined-basic" type='text' value={leadData?.notes} label='Notes' className='form_input'
                                onChange={(e) => handleInputChange('notes', e.target.value)}
                    />

                      <FormControl className='form_input dropdown_input'>
                        <InputLabel id="demo-simple-select-label">Lead Status</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={leadData.lead_status}
                            label="Lead Status"
                            onChange={(e) => handleInputChange('lead_status', e.target.value)}
                        >
                            {
                                localStorage.getItem("lead_status") && JSON.parse(localStorage.getItem("lead_status"))?.map((e, id) => {
                                    return (
                                        <MenuItem key={id} value={e.lead_status}>{e.lead_status}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>

                  
                    
                    <FormControl className='form_input dropdown_input'>
                        <InputLabel id="demo-simple-select-label">Course Interested</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={leadData.course_interested}
                            label="Course Interested"
                            // multiple
                            // renderValue={(selected) => (
                            //     <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                            //       {selected.map((value) => (
                            //         <Chip key={value} label={value} />
                            //       ))}
                            //     </Box>
                            //   )}
                            //   MenuProps={{
                            //     PaperProps: {
                            //         sx: {
                            //             '& .MuiMenuItem-root.Mui-selected': {
                            //                 backgroundColor: 'rgba(0, 0, 255, 0.2)', 
                            //                 '&:hover': {
                            //                     backgroundColor: 'rgba(0, 0, 255, 0.15)',
                            //                 },
                            //             },
                            //         },
                            //     },
                            // }}
                            // onChange={handleSelectChange}
                            onChange={(e) => handleInputChange('course_interested', e.target.value)}
                        >
                            {
                                localStorage.getItem("course_interested") && JSON.parse(localStorage.getItem("course_interested"))?.map((e, id) => {
                                    return (
                                        <MenuItem key={id} value={e.course_interested}>{e.course_interested}</MenuItem>
                                    )
                                })
                            }
                             <MenuItem key="NA" value="NA">NA</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl className='form_input dropdown_input'>
                        <InputLabel id="demo-simple-select-label">Lead Source</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={leadData.lead_source}
                            label="Lead Source"
                            onChange={(e) => handleInputChange('lead_source', e.target.value)}
                        >
                            {
                                localStorage.getItem("lead_source") && JSON.parse(localStorage.getItem("lead_source"))?.map((e, id) => {
                                    return (
                                        <MenuItem key={id} value={e.lead_source}>{e.lead_source}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>

                    {role==="admin" && <FormControl className='form_input dropdown_input' >
                            <InputLabel id="demo-simple-select-label">Primary Consultant</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={leadData?.primary_consultant}
                                label="Primary Consultant"
                                onChange={(e) => handleInputChange('primary_consultant', e.target.value)}
                            >
                                {
                                    localStorage.getItem("consultant_list") && JSON.parse(localStorage.getItem("consultant_list"))?.map((consultant, id) => {
                                        const name = Object.keys(consultant)[0];
                                        const email = consultant[name];
                                        return (
                                            <MenuItem value={email} key={id}>{name}</MenuItem>
                                        );
                                    })
                                }

                            </Select>
                        </FormControl>}


                       {leadData?.lead_status==="Follow Up" && <DatePicker
                            selected={leadData?.followup_date}
                            onChange={(date) => handleDateChange('followup_date', date)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="FollowUp Date"
                            className='form_date_iput'
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                        />}
                    
                      



                        {/* <FormControl className='form_input dropdown_input'>
                            <InputLabel id="demo-simple-select-label">Followup Time</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedTime}
                                label="Followup Time"
                                onChange={(e) => {
                                    console.log(leadData.followup_date.substring(0, 10));
                                    setSelectedTime(e.target.value)
                                    // setLeadEdit({...leadEdit, followup_date: `${leadEdit.followup_date} ${e.target.value}`})
                                    setLeadData({ ...leadData, followup_date: `${leadData.followup_date.split(' ')[0]} ${e.target.value}` })
                                }}
                            >
                                {
                                    timeArray.map((e, id) => {
                                        return (
                                            <MenuItem value={e} key={id}>{e}</MenuItem>
                                        )
                                    })
                                }

                            </Select>
                        </FormControl> */}

                      
                <div className='viewMoreFields' onClick={()=>setViewMoreFields(!viewMoreFields)}>
                    {!viewMoreFields && <p className='viewMoreFieldsText'>View More Fields </p>}
                    {viewMoreFields && <p className='viewMoreFieldsText'>View Less Fields </p>}
                    {!viewMoreFields && <IoIosArrowDown color='white' className='viewMoreIcon'/>}
                    {viewMoreFields && <IoIosArrowUp color='white' className='viewMoreIcon'/>}
                </div>
                    
                  {viewMoreFields &&  <div>

                    <TextField variant="outlined" id="outlined-basic" type='text' label='Email' className='form_input'
                        onChange={(e) => handleInputChange('email', e.target.value)}
                        // onBlur={() => checkEmail()}
                    />
                    <TextField variant="outlined" id="outlined-basic" type='text' label='Last Name' className='form_input'
                        onChange={(e) => handleInputChange('lastname', e.target.value)}
                    />
                    <FormControl className='form_input dropdown_input'>
                        <InputLabel id="demo-simple-select-label">Incorrect Email Flag</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={leadData.incorrect_email_flag}
                            label="Incorrect Email Flag"
                            onChange={(e) => handleInputChange('incorrect_email_flag', e.target.value)}
                        >
                            <MenuItem value='false'>false</MenuItem>
                            <MenuItem value='true'>true</MenuItem>

                        </Select>
                    </FormControl>

                    <FormControl className='form_input dropdown_input'>
                        <InputLabel id="demo-simple-select-label">Incorrect Mobile Flag</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={leadData.incorrect_mobile_flag}
                            label="Incorrect Mobile Flag"
                            onChange={(e) => handleInputChange('incorrect_mobile_flag', e.target.value)}
                        >
                            <MenuItem value='false'>false</MenuItem>
                            <MenuItem value='true'>true</MenuItem>
                        </Select>
                    </FormControl>


                    <FormControl className='form_input dropdown_input'>
                        <InputLabel id="demo-simple-select-label">State</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={leadData.state}
                            label="State"
                            onChange={(e) => handleInputChange('state', e.target.value)}
                        >
                            {
                                StatesList.map((e, id) => {
                                    return (
                                        <MenuItem value={e} key={id}>{e}</MenuItem>
                                    )
                                })
                            }

                        </Select>
                    </FormControl>

                    <TextField variant="outlined" id="outlined-basic" type='text' label='City' className='form_input'
                        onChange={(e) => handleInputChange('city', e.target.value)}
                    />

                    <FormControl className='form_input dropdown_input'>
                        <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={leadData.gender}
                            label="Gender"
                            onChange={(e) => handleInputChange('gender', e.target.value)}
                        >
                            {
                                localStorage.getItem("gender_list") && JSON.parse(localStorage.getItem("gender_list"))?.map((e, id) => {
                                    return (
                                        <MenuItem key={id} value={e.gender}>{e.gender}</MenuItem>
                                    )
                                })
                            }


                        </Select>
                    </FormControl>

                    <FormControl className='form_input dropdown_input'>
                        <InputLabel id="demo-simple-select-label">Age</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={leadData.age}
                            label="Age"
                            onChange={(e) => handleInputChange('age', e.target.value)}
                        >
                            {
                                localStorage.getItem("age_list") && JSON.parse(localStorage.getItem("age_list"))?.map((e, id) => {
                                    return (
                                        <MenuItem key={id} value={e.age}>{e.age}</MenuItem>
                                    )
                                })
                            }


                        </Select>
                    </FormControl>


                    <TextField variant="outlined" id="outlined-basic" type='text' label='Highest Qualification' className='form_input'
                        onChange={(e) => handleInputChange('highest_qualification', e.target.value)}
                    />

                    <TextField variant="outlined" id="outlined-basic" type='text' label='Year of Passing' className='form_input'
                        onChange={(e) => handleInputChange('year_of_passing', e.target.value)}
                    />

                    <TextField variant="outlined" id="outlined-basic" type='text' label='Reason for taking Course' className='form_input'
                        onChange={(e) => handleInputChange('reason_for_taking_this_course', e.target.value)}
                    />

                    <TextField variant="outlined" id="outlined-basic" type='text' label='Occupation' className='form_input'
                        onChange={(e) => handleInputChange('occupation', e.target.value)}
                    />



                  

                  

                    <TextField variant="outlined" id="outlined-basic" type='text' label='Source URL' className='form_input'
                        onChange={(e) => handleInputChange('source_url', e.target.value)}
                    />


                    <TextField variant="outlined" id="outlined-basic" type='text' label='Lead Medium' className='form_input'
                        onChange={(e) => handleInputChange('lead_medium', e.target.value)}
                    />







                    <FormControl className='form_input dropdown_input'>
                        <InputLabel id="demo-simple-select-label">Device</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={leadData.device}
                            label="Device"
                            onChange={(e) => handleInputChange('device', e.target.value)}
                        >
                            <MenuItem value="Laptop">Laptop</MenuItem>
                            <MenuItem value="Mobile">Mobile</MenuItem>

                        </Select>
                    </FormControl>

                    <FormControl className='form_input dropdown_input'>
                        <InputLabel id="demo-simple-select-label">Campaign</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={leadData.campaign}
                            label="Campaign"
                            onChange={(e) => handleInputChange('campaign', e.target.value)}
                        >
                            {
                                localStorage.getItem("campaign") && JSON.parse(localStorage.getItem("campaign"))?.map((e, id) => {
                                    return (
                                        <MenuItem key={id} value={e.campaign}>{e.campaign}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>


                    <FormControl className='form_input dropdown_input'>
                        <InputLabel id="demo-simple-select-label">Priority</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={leadData.priority}
                            label="Consultant Assigned"
                            onChange={(e) => handleInputChange('priority', e.target.value)}
                        >
                            <MenuItem value='Very High'>Very High</MenuItem>
                            <MenuItem value='High'>High</MenuItem>
                            <MenuItem value='Medium'>Medium</MenuItem>
                            <MenuItem value='Low'>Low</MenuItem>
                            <MenuItem value='Very Low'>Very Low</MenuItem>


                        </Select>
                    </FormControl>




                    <FormControl className='form_input dropdown_input'>
                        <InputLabel id="demo-simple-select-label">Secondary Consultant</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={leadData.secondary_consultant}
                            label="Secondary Consultant"
                            onChange={(e) => handleInputChange('secondary_consultant', e.target.value)}
                        >
                            {
                                localStorage.getItem("consultant_list") && JSON.parse(localStorage.getItem("consultant_list"))?.map((consultant, id) => {
                                    const name = Object.keys(consultant)[0];
                                    const email = consultant[name];
                                    return (
                                        <MenuItem value={email} key={id}>{name}</MenuItem>
                                    );
                                })
                            }

                        </Select>
                    </FormControl>

                    <TextField variant="outlined" id="outlined-basic" type='text' label='batch' className='form_input'
                        onChange={(e) => handleInputChange('batch', e.target.value)}
                    />


                    <DatePicker
                        selected={leadData.batch_start_date}
                        onChange={(date) => handleDateChange('batch_start_date', date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Batch Start Date"
                        className='form_date_iput'
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                    />

                    <DatePicker
                        selected={leadData.date_enrolled}
                        onChange={(date) => handleDateChange('date_enrolled', date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Date Enrolled"
                        className='form_date_iput'
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                    />


                    <FormControl className='form_input dropdown_input'>
                        <InputLabel id="demo-simple-select-label">Incorrect Lead Flag</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={leadData.incorrect_lead_flag}
                            label="Incorrect Lead Flag"
                            onChange={(e) => handleInputChange('incorrect_lead_flag', e.target.value)}
                        >
                            <MenuItem value='false'>false</MenuItem>
                            <MenuItem value='true'>true</MenuItem>

                        </Select>
                    </FormControl>



                    <TextField variant="outlined" id="outlined-basic" type='text' label='notes' className='form_input'
                        onChange={(e) => handleInputChange('notes', e.target.value)}
                    />

                    </div>}

                  
                       
                    


                    {
                        curreuntLeadStatus === "Escalation" &&
                        <div style={{ display: 'flex', alignItems: 'center' }}>


                            <FormControl className='form_input dropdown_input'>
                                <InputLabel id="demo-simple-select-label">Escalation Consultant</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={leadData?.escalation_consultant}
                                    label="Escalation Consultant"
                                    onChange={(e) => {
                                        handleInputChange('escalation_consultant', e.target.value)
                                    }}
                                >
                                    <MenuItem value="Mahesh">Mahesh</MenuItem>
                                </Select>
                            </FormControl>


                            <TextField variant="outlined" id="outlined-basic" type='text' value={leadData?.escalation_notes} label='Escalation Notes' className='form_input'
                                onChange={(e) => handleInputChange('escalation_notes', e.target.value)}
                            />

                        </div>
                    }


                    

                    {
                        curreuntLeadStatus === "Paid" &&
                        <span style={{marginLeft:'15px'}}>Please add Payment Details after Submitting</span>
                        // <div>
                        //     <div className='form_submit_button' style={{ backgroundColor: "var(--mainColor)" }} onClick={() => { navigate('/lead-payment-details'); setOpenModal(false) }}>
                        //     </div>
                        // </div>
                    }




                    <div className='form_submit_button' style={{ backgroundColor: "var(--mainColor)" }} onClick={submitLead}>
                        {
                            !isLoading ?
                                <span>Submit</span> :
                                <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                        }
                    </div>



                </div>
            }


            {
                (selectedSubOption === "Edit Lead" || selectedSubOption === "Edit Lead1") &&
                <div>
                    <div style={{ width: "68vw" }}>
                        <p className='form_heading'>Edit Lead</p>


                        <TextField variant="outlined" id="outlined-basic" type='text' value={leadEdit?.firstname} label='First Name' className='form_input'
                            onChange={(e) => handleLeadEditInputChange('firstname', e.target.value)}
                        />

                        
                    

                        <div style={{width:"20vw",display:"inline-block",marginTop:"5px"}}>
                                <PhoneInput
                                country={'in'}
                                style={{height:"55px"}}
                                inputClass='phoneNumberInput'
                                value={leadEdit?.mobile}
                                onChange={phone =>  handleLeadEditInputChange('mobile', phone)}
                                />
                        </div>

                        <FormControl className='form_input dropdown_input'>
                            <InputLabel id="demo-simple-select-label">Lead Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={leadEdit?.lead_status}
                                label="Lead Status"
                                onChange={(e) => handleLeadEditInputChange('lead_status', e.target.value)}
                            >
                                {
                                    localStorage.getItem("lead_status") && JSON.parse(localStorage.getItem("lead_status"))?.map((e, id) => {
                                        return (
                                            <MenuItem key={id} value={e.lead_status}>{
                                                e.lead_status!=="Escalation" ? e.lead_status :role==="admin"?e.lead_status:null
                                            }</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>

                        <TextField variant="outlined" id="outlined-basic" type='text' value={leadEdit?.notes} label='Notes' className='form_input'
                                onChange={(e) => handleLeadEditInputChange('notes', e.target.value)}
                            />

                        <FormControl className='form_input dropdown_input'>
                            <InputLabel id="demo-simple-select-label">Course Interested</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={leadEdit?.course_interested}
                                label="Course Interested"
                                // multiple
                                // renderValue={(selected) => (
                                //     <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                //         {selected.map((value) => (
                                //             <Chip key={value} label={value} />
                                //         ))}
                                //     </Box>
                                // )}
                                // MenuProps={{
                                //     PaperProps: {
                                //         sx: {
                                //             '& .MuiMenuItem-root.Mui-selected': {
                                //                 backgroundColor: 'rgba(0, 0, 255, 0.2)', 
                                //                 '&:hover': {
                                //                     backgroundColor: 'rgba(0, 0, 255, 0.15)',
                                //                 },
                                //             },
                                //         },
                                //     },
                                // }}
                                // onChange={handleSelectChange1}
                                onChange={(e) => handleLeadEditInputChange('course_interested', e.target.value)}
                            >
                                {
                                    localStorage.getItem("course_interested") && JSON.parse(localStorage.getItem("course_interested"))?.map((e, id) => {
                                        return (
                                            <MenuItem key={id} value={e.course_interested}>{e.course_interested}</MenuItem>
                                        )
                                    })
                                }
                                <MenuItem key="NA" value="NA">NA</MenuItem>
                            </Select>
                        </FormControl>

                        {role==="admin" && <FormControl className='form_input dropdown_input' >
                            <InputLabel id="demo-simple-select-label">Primary Consultant</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={leadEdit?.primary_consultant}
                                label="Primary Consultant"
                                onChange={(e) => handleLeadEditInputChange('primary_consultant', e.target.value)}
                            >
                                {
                                    localStorage.getItem("consultant_list") && JSON.parse(localStorage.getItem("consultant_list"))?.map((consultant, id) => {
                                        const name = Object.keys(consultant)[0];
                                        const email = consultant[name];
                                        return (
                                            <MenuItem value={email} key={id}>{name}</MenuItem>
                                        );
                                    })
                                }

                            </Select>
                        </FormControl>}


                       {leadEdit?.lead_status==="Follow Up" && <div style={{}}>
                            <p style={{marginLeft:'15px',marginBottom:'0px',marginTop:'0px'}}>FollowUp Date</p>
                            <DatePicker
                                selected={leadEdit?.followup_date}
                                onChange={(date) => handleLeadEditDateChange('followup_date', date)}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="FollowUp Date"
                                className='form_date_iput'
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                        </div>}



                            {/* <FormControl className='form_input dropdown_input' disabled={!isDateSelected} onClick={() => {
                                if (!isDateSelected) {
                                    toast.error('Select new or same followup date first')
                                }
                            }}>
                                <InputLabel id="demo-simple-select-label">Followup Time</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedTime}
                                    label="Followup Time"
                                    onChange={(e) => {
                                        setSelectedTime(e.target.value)
                                        // console.log(leadEdit.followup_date);
                                        // setLeadEdit({...leadEdit, followup_date: `${leadEdit.followup_date} ${e.target.value}`})
                                        setLeadEdit({ ...leadEdit, followup_date: `${leadEdit.followup_date.split(' ')[0]} ${e.target.value}` })
                                    }}
                                >
                                    {
                                        timeArray.map((e, id) => {
                                            return (
                                                <MenuItem value={e} key={id}>{e}</MenuItem>
                                            )
                                        })
                                    }

                                </Select>
                            </FormControl> */}

                    <div className='viewMoreFields' onClick={()=>setViewMoreFields(!viewMoreFields)}>
                        {!viewMoreFields && <p className='viewMoreFieldsText'>View More Fields </p>}
                        {viewMoreFields && <p className='viewMoreFieldsText'>View Less Fields </p>}
                        {!viewMoreFields && <IoIosArrowDown color='white' className='viewMoreIcon'/>}
                        {viewMoreFields && <IoIosArrowUp color='white' className='viewMoreIcon'/>}
                    </div>



                   {viewMoreFields && <div>
                    <TextField variant="outlined" id="outlined-basic" type='text' value={leadEdit?.email} label='Email' className='form_input'
                            onChange={(e) => handleLeadEditInputChange('email', e.target.value)}
                        />

                    <TextField variant="outlined" id="outlined-basic" type='text' value={leadEdit?.lastname} label='Last Name' className='form_input'
                            onChange={(e) => handleLeadEditInputChange('lastname', e.target.value)}
                        />

                        <FormControl className='form_input dropdown_input'>
                            <InputLabel id="demo-simple-select-label">Incorrect Email Flag</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={leadEdit?.incorrect_email_flag}
                                label="Incorrect Email Flag"
                                onChange={(e) => handleLeadEditInputChange('incorrect_email_flag', e.target.value)}
                            >
                                <MenuItem value='false'>false</MenuItem>
                                <MenuItem value='true'>true</MenuItem>

                            </Select>
                        </FormControl>

                        <FormControl className='form_input dropdown_input'>
                            <InputLabel id="demo-simple-select-label">Incorrect Mobile Flag</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={leadEdit?.incorrect_mobile_flag}
                                label="Incorrect Email Flag"
                                onChange={(e) => handleLeadEditInputChange('incorrect_mobile_flag', e.target.value)}
                            >
                                <MenuItem value='false'>false</MenuItem>
                                <MenuItem value='true'>true</MenuItem>

                            </Select>
                        </FormControl>


                        <FormControl className='form_input dropdown_input'>
                            <InputLabel id="demo-simple-select-label">State</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={leadEdit?.state}
                                label="State"
                                onChange={(e) => handleLeadEditInputChange('state', e.target.value)}
                            >
                                {
                                    StatesList.map((e, id) => {
                                        return (
                                            <MenuItem value={e} key={id}>{e}</MenuItem>
                                        )
                                    })
                                }

                            </Select>
                        </FormControl>

                        <TextField variant="outlined" id="outlined-basic" type='text' value={leadEdit?.city} label='City' className='form_input'
                            onChange={(e) => handleLeadEditInputChange('city', e.target.value)}
                        />

                        <FormControl className='form_input dropdown_input'>
                            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={leadEdit?.gender}
                                label="Gender"
                                onChange={(e) => handleLeadEditInputChange('gender', e.target.value)}
                            >
                                {
                                    localStorage.getItem("gender_list") && JSON.parse(localStorage.getItem("gender_list"))?.map((e, id) => {
                                        return (
                                            <MenuItem key={id} value={e.gender}>{e.gender}</MenuItem>
                                        )
                                    })
                                }


                            </Select>
                        </FormControl>

                        <FormControl className='form_input dropdown_input'>
                            <InputLabel id="demo-simple-select-label">Age</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={leadEdit?.age}
                                label="Age"
                                onChange={(e) => handleLeadEditInputChange('age', e.target.value)}
                            >
                                {
                                    localStorage.getItem("age_list") && JSON.parse(localStorage.getItem("age_list"))?.map((e, id) => {
                                        return (
                                            <MenuItem key={id} value={e.age}>{e.age}</MenuItem>
                                        )
                                    })
                                }

                            </Select>
                        </FormControl>


                        <TextField variant="outlined" id="outlined-basic" type='text' value={leadEdit?.highest_qualification} label='Highest Qualification' className='form_input'
                            onChange={(e) => handleLeadEditInputChange('highest_qualification', e.target.value)}
                        />

                        <TextField variant="outlined" id="outlined-basic" type='text' value={leadEdit?.year_of_passing} label='Year of Passing' className='form_input'
                            onChange={(e) => handleLeadEditInputChange('year_of_passing', e.target.value)}
                        />

                        <TextField variant="outlined" id="outlined-basic" type='text' value={leadEdit?.reason_for_taking_this_course} label='Reason for taking Course' className='form_input'
                            onChange={(e) => handleLeadEditInputChange('reason_for_taking_this_course', e.target.value)}
                        />

                        <TextField variant="outlined" id="outlined-basic" type='text' value={leadEdit?.occupation} label='Occupation' className='form_input'
                            onChange={(e) => handleLeadEditInputChange('occupation', e.target.value)}
                        />


                        <TextField variant="outlined" id="outlined-basic" type='text' value={leadEdit?.level_1} label='Level 1' className='form_input'
                            onChange={(e) => handleLeadEditInputChange('level_1', e.target.value)}
                        />

                        <TextField variant="outlined" id="outlined-basic" type='text' value={leadEdit?.level_2} label='Level 2' className='form_input'
                            onChange={(e) => handleLeadEditInputChange('level_2', e.target.value)}
                        />

                        <TextField variant="outlined" id="outlined-basic" type='text' value={leadEdit?.level_3} label='Level 3' className='form_input'
                            onChange={(e) => handleLeadEditInputChange('level_3', e.target.value)}
                        />




                    
                        

                        <FormControl className='form_input dropdown_input'>
                            <InputLabel id="demo-simple-select-label">Lead Source</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={leadEdit?.lead_source}
                                label="Lead Source"
                                onChange={(e) => handleLeadEditInputChange('lead_source', e.target.value)}
                            >
                                {
                                    localStorage.getItem("lead_source") && JSON.parse(localStorage.getItem("lead_source"))?.map((e, id) => {
                                        return (
                                            <MenuItem key={id} value={e.lead_source}>{e.lead_source}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>

                        <TextField variant="outlined" id="outlined-basic" type='text' value={leadEdit?.source_url} label='Source URL' className='form_input'
                            onChange={(e) => handleLeadEditInputChange('source_url', e.target.value)}
                        />


                        <TextField variant="outlined" id="outlined-basic" type='text' value={leadEdit?.lead_medium} label='Lead Medium' className='form_input'
                            onChange={(e) => handleLeadEditInputChange('lead_medium', e.target.value)}
                        />



                        <FormControl className='form_input dropdown_input'>
                            <InputLabel id="demo-simple-select-label">Device</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={leadEdit?.device}
                                label="Device"
                                onChange={(e) => handleLeadEditInputChange('device', e.target.value)}
                            >
                                <MenuItem value="Laptop">Laptop</MenuItem>
                                <MenuItem value="Mobile">Mobile</MenuItem>

                            </Select>
                        </FormControl>

                        <FormControl className='form_input dropdown_input'>
                            <InputLabel id="demo-simple-select-label">Campaign</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={leadEdit?.campaign}
                                label="Campaign"
                                onChange={(e) => handleLeadEditInputChange('campaign', e.target.value)}
                            >
                                {
                                    localStorage.getItem("campaign") && JSON.parse(localStorage.getItem("campaign"))?.map((e, id) => {
                                        return (
                                            <MenuItem key={id} value={e.campaign}>{e.campaign}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>

                        <FormControl className='form_input dropdown_input'>
                            <InputLabel id="demo-simple-select-label">Priority</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={leadEdit?.priority}
                                label="Priority"
                                onChange={(e) => handleLeadEditInputChange('priority', e.target.value)}
                            >
                                <MenuItem value='Very High'>Very High</MenuItem>
                                <MenuItem value='High'>High</MenuItem>
                                <MenuItem value='Medium'>Medium</MenuItem>
                                <MenuItem value='Low'>Low</MenuItem>
                                <MenuItem value='Very Low'>Very Low</MenuItem>

                            </Select>
                        </FormControl>

                        <TextField variant="outlined" id="outlined-basic" type='text' value={leadEdit?.batch} label='batch' className='form_input'
                            onChange={(e) => handleLeadEditInputChange('batch', e.target.value)}
                        />






                      




                        <FormControl className='form_input dropdown_input'>
                            <InputLabel id="demo-simple-select-label">Secondary Consultant</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={leadEdit?.secondary_consultant}
                                label="Secondary Consultant"
                                onChange={(e) => handleLeadEditInputChange('secondary_consultant', e.target.value)}
                            >
                                {
                                    localStorage.getItem("consultant_list") && JSON.parse(localStorage.getItem("consultant_list"))?.map((consultant, id) => {
                                        const name = Object.keys(consultant)[0];
                                        const email = consultant[name];
                                        return (
                                            <MenuItem value={email} key={id}>{name}</MenuItem>
                                        );
                                    })
                                }

                            </Select>
                        </FormControl>





                        <FormControl className='form_input dropdown_input'>
                            <InputLabel id="demo-simple-select-label">Incorrect Lead Flag</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={leadEdit?.incorrect_lead_flag}
                                label="Incorrect Lead Flag"
                                onChange={(e) => handleLeadEditInputChange('incorrect_lead_flag', e.target.value)}
                            >
                                <MenuItem value='false'>false</MenuItem>
                                <MenuItem value='true'>true</MenuItem>

                            </Select>
                        </FormControl>

                        <div>

                            <div className='date_and_label'>
                                <p className='date_label'>Batch Start Date</p>
                                <DatePicker
                                    selected={leadEdit?.batch_start_date}
                                    onChange={(date) => handleLeadEditDateChange('batch_start_date', date)}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Batch Start Date"
                                    className='form_date_iput'
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                // locale="en-IN"
                                />
                            </div>

                            <div className='date_and_label'>
                                <p className='date_label'>Date Enrolled</p>
                                <DatePicker
                                    selected={leadEdit?.date_enrolled}
                                    onChange={(date) => handleLeadEditDateChange('date_enrolled', (date))}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Date Enrolled"
                                    className='form_date_iput'
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                // locale="en-IN"
                                />
                            </div>
                        </div>




                    </div>}


                        {
                            curreuntLeadStatus === "Escalation" &&
                            <div style={{ display: 'flex', alignItems: 'center' }}>


                                <FormControl className='form_input dropdown_input'>
                                    <InputLabel id="demo-simple-select-label">Escalation Consultant</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={leadEdit?.escalation_consultant}
                                        label="Escalation Consultant"
                                        onChange={(e) => {
                                            handleLeadEditInputChange('escalation_consultant', e.target.value)
                                        }}
                                    >
                                        <MenuItem value="Mahesh">Mahesh</MenuItem>
                                    </Select>
                                </FormControl>


                                <TextField variant="outlined" id="outlined-basic" type='text' value={leadEdit?.escalation_notes} label='Escalation Notes' className='form_input'
                                    onChange={(e) => handleLeadEditInputChange('escalation_notes', e.target.value)}
                                />

                            </div>
                        }


              

                        {
                            curreuntLeadStatus === "Paid" &&
                            <div>
                                <div className='form_submit_button' style={{ backgroundColor: "var(--mainColor)" }} onClick={() => { navigate('/lead-payment-details'); setOpenModal(false) }}>
                                    <span>Click here to add Payment Details</span>
                                </div>
                            </div>
                        }







                        <div className='form_submit_button' onClick={EditLead} style={{ backgroundColor: "var(--mainColor)" }}>
                            {
                                !isLoading ?
                                    <span>Submit</span> :
                                    <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                            }
                        </div>

                        <div className='form_submit_button' style={{ backgroundColor: "var(--mainColor)" }} onClick={() => { navigate('/contact-lead'); setOpenModal(false); setIsContactLeadClicked(true) }}>
                            <span>Send Details</span>

                        </div>


                    </div>
                </div>
            }


            {
                selectedSubOption === "Add Notes" &&
                <div>
                    <p className='form_heading'>Add Notes</p>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <textarea placeholder="Add Notes" className='form_input' style={{ width: "25vw", height: "10vh", resize: "none", color: "black" }}
                            value={leadNotes}
                            onChange={(e) => setLeadNotes(e.target.value)}

                        ></textarea>

                        <div className='form_submit_button' onClick={AddNotes} style={{ backgroundColor: "var(--mainColor)" }}>
                            {
                                !isLoading ?
                                    <span>Submit</span> :
                                    <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                            }
                        </div>
                    </div>

                </div>
            }


            {
                selectedSubOption === "Add Notes Call" &&
                <div>
                    <p className='form_heading'>Add Notes</p>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <textarea placeholder="Add Notes" className='form_input' style={{ width: "25vw", height: "10vh", resize: "none", color: "black" }}
                            value={leadNotes}
                            onChange={(e) => setLeadNotes(e.target.value)}

                        ></textarea>

                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['TimePicker']}>
                                <TimePicker label="Call Start Time"
                                    value={callStartTime}
                                    onChange={(time) => {
                                        const formattedTime = time.format('HH:mm');
                                        setCallStartTime(formattedTime)
                                    }}
                                />
                            </DemoContainer>

                        </LocalizationProvider> */}
                        {/* <input type='time' placeholder='call' className='callTime' onChange={(e)=>setCallStartTime(e.target.value)}></input> */}
                        <p><span style={{color:"red"}}>* </span> Click buttons to get/change call time</p>
                        <p className='form_submit_button' onClick={()=>setCallStartTime(getCurrentTime())}>Call Start time</p>
                        {callStartTime && <p className='callLabel'>{callStartTime}</p>}
                        <p className='form_submit_button' onClick={()=>setCallEndTime(getCurrentTime())}>Call End time</p>
                        {callEndTime && <p className='callLabel'>{callEndTime}</p>}

                            

                        <div className='form_submit_button' onClick={AddCallNotes} style={{ backgroundColor: "var(--mainColor)" }}>
                            {
                                !isLoading ?
                                    <span>Submit</span> :
                                    <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                            }
                        </div>
                    </div>

                </div>
            }



            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: '',
                    duration: 2500,
                    style: {
                        background: 'white',
                        color: '#000',
                    },

                    success: {
                        duration: 2500,
                        theme: {
                            primary: 'green',
                            secondary: 'white',
                        },
                    },
                }}
            />

        </div>
    )
}




// await fetch(`${BASE_URL}user/verify_email_mobile?mobile=${leadData.mobile}&user_email=${leadData.email}`, {
//     method: "GET"
// })
//     .then(response => {
//         console.log(response);
//         return response.json();
//     })
//     .then(data => {
//         console.log(data);
//         if (data.detail === `Email ${leadData.email} already exists`) {
//             setIsloading(false)
//             console.log("hii1");
//             toast.error(`Email ${leadData.email} already exists`)
//         }
//         else if (data.detail === `Mobile number ${leadData.mobile} already exists`) {
//             setIsloading(false)
//             console.log("hii2");
//             toast.error(`Mobile number ${leadData.mobile} already exists`)
//         }
        

        
//         else {
//             setIsloading(false)
//             toast.error("Error Occured")
//         }
//     })
//     .catch(error => {
//         setIsloading(false)
//         toast.error('Server error. Please try again later...');
//     });