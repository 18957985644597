import React,{useEffect} from 'react'
import './LandingPage.css'
import Footer from '../../Components/FooterComponent/Footer'
import { useNavigate } from 'react-router-dom'

export default function LandingPage() {
    useEffect(()=>{
        localStorage.clear()
    },[])
    const navigate=useNavigate()
    return (
        <div>
            <div className='landing_page_header'>
                <img src='/ksr_main_logo.png' alt='ksr datavizon' className='landing_page_logo'></img>
                <p className='landing_page_header_items' onClick={()=>navigate('/login')}>Login</p>
            </div>

            <div className='landing_page_intro'>
                <div>
                <p className='landing_page_heading'>Bring all your leads into a single platform</p>
                <p className='landing_page_description'>Integrate your online lead channels and partners such as Google Ads, FB, Instagram, Linkedin, 
                    and 3rd party vendors seamlessly with our Edtech CRM. With no lead leakage you can make sure 
                    that your team focuses more on conversions than any other manual tasks.</p>
                </div>
                <img src='/crm_landing_page_img.jpg' alt='ksr datavizon' className='landing_page_intro_image'></img>
            </div>
            <Footer/>
        </div>
    )
}
