import React, { useContext, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import '../../Components/ModalForm/ModalForm.css'
import { MainContext } from '../../ContextStore/MainContext';
import './Settings.css'
import toast, { Toaster } from 'react-hot-toast';
import ReactLoading from 'react-loading';



export default function SettingsModal() {
    const { openSettingsModal, BASE_URL, setopenSettingsModal, selectedSettingOption } = useContext(MainContext)
    const handleClose = () => setopenSettingsModal(false);
    const [isLoading, setIsLoading] = useState(false)
    const [age_list, setAge_list] = useState("")
    const [gender_list, setGender_list] = useState("")
    const [courses_list, setCourses_list] = useState("")
    const [lead_status_list, setLead_Status_list] = useState("")
    const [lead_source_list, setLead_Source_list] = useState("")
    const [campaign_list, setCampaign_list] = useState("")

    const [emailTemplateName, setEmailTemplateName] = useState("")
    const [emailTemplateSubject, setEmailTemplateSubject] = useState("")
    const [emailTemplateUrl, setEmailTemplateUrl] = useState("")
    const [whatsAppTemplateName,setWhatsAppTemplateName]=useState("")
    const [whatsAppTemplateUrl,setWhatsAppTemplateUrl]=useState("")


    useEffect(() => {
        if (selectedSettingOption === "Add Email Template") {
            setEmailTemplateName("")
            setEmailTemplateSubject("")
            setEmailTemplateSubject("")
        }
        else if (selectedSettingOption === "Add WhatsApp Template") {
            setWhatsAppTemplateName("")
            setWhatsAppTemplateUrl("")
        }
        else if (selectedSettingOption === "Edit Email Template") {
            localStorage.getItem("email_template_name") !== "" && localStorage.getItem("email_template_name") !== "undefined" &&
                setEmailTemplateName(localStorage.getItem("email_template_name"))

            localStorage.getItem("email_template_subject") !== "" && localStorage.getItem("email_template_subject") !== "undefined" &&
                setEmailTemplateSubject(localStorage.getItem("email_template_subject"))

            localStorage.getItem("email_template_url") !== "" && localStorage.getItem("email_template_url") !== "undefined" &&
                setEmailTemplateUrl(localStorage.getItem("email_template_url"))
        }
        else if (selectedSettingOption === "Edit WhatsApp Template") {
            localStorage.getItem("whatsapp_template_name") !== "" && localStorage.getItem("whatsapp_template_name") !== "undefined" &&
                setWhatsAppTemplateName(localStorage.getItem("whatsapp_template_name"))

            localStorage.getItem("whatsapp_template_url") !== "" && localStorage.getItem("whatsapp_template_url") !== "undefined" &&
                setWhatsAppTemplateUrl(localStorage.getItem("whatsapp_template_url"))
        }
    }, [selectedSettingOption])


    const [file, setFile] = useState(null)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "fit-content",
        bgcolor: '#eee',
        border: 'none !important',
        outline: "none !important",
        boxShadow: 24,
        borderRadius: 2,
        p: 2,
    };

    function AddAge() {
        setIsLoading(true)
        if (age_list === "") {
            setIsLoading(false)
            toast.error("Enter Age")
            return
        }
        else {
            fetch(`${BASE_URL}custom_settings/age?age=${age_list}`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setIsLoading(false)
                    window.location.reload()
                    if (data) {
                        toast.success("Added Successfully")
                    }
                })
                .catch(error => {
                    setIsLoading(false)
                    toast.error('Server error. Please try again later...');
                });
        }
    }

    function AddGender() {
        setIsLoading(true)
        if (gender_list === "") {
            setIsLoading(false)
            toast.error("Enter Gender")
            return
        }
        else {
            fetch(`${BASE_URL}custom_settings/gender?gender=${gender_list}`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    window.location.reload()
                    setIsLoading(false)
                    if (data) {
                        toast.success("Added Successfully")
                    }
                })
                .catch(error => {
                    setIsLoading(false)
                    toast.error('Server error. Please try again later...');
                });
        }
    }

    function AddCourse() {
        setIsLoading(true)
        if (courses_list === "") {
            toast.error("Enter Course")
            setIsLoading(false)
            return
        }
        else {
            fetch(`${BASE_URL}custom_settings/course_interested?course_interested=${courses_list}`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {

                    return response.json();
                })
                .then(data => {
                    window.location.reload()
                    if (data) {
                        setIsLoading(false)
                        toast.success("Added Successfully")
                    }
                })
                .catch(error => {
                    setIsLoading(false)
                    toast.error('Server error. Please try again later...');
                });
        }
    }

    function AddLead_Status() {
        setIsLoading(true)
        if (lead_status_list === "") {
            setIsLoading(false)
            toast.error("Enter Lead Status")
            return
        }
        else {

            fetch(`${BASE_URL}custom_settings/lead_status?lead_status=${lead_status_list}`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {

                    return response.json();
                })
                .then(data => {
                    setIsLoading(false)
                    window.location.reload()
                    if (data) {
                        toast.success("Added Successfully")
                    }
                })
                .catch(error => {
                    setIsLoading(false)
                    toast.error('Server error. Please try again later...');
                });
        }
    }

    function AddLead_Source() {
        setIsLoading(true)
        if (lead_source_list === "") {
            setIsLoading(false)
            toast.error("Enter Lead Source")
            return
        }
        else {
            fetch(`${BASE_URL}custom_settings/lead_source?lead_source=${lead_source_list}`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setIsLoading(false)
                    window.location.reload()
                    if (data) {
                        toast.success("Added Successfully")
                    }
                })
                .catch(error => {
                    setIsLoading(false)
                    toast.error('Server error. Please try again later...');
                });
        }
    }

    function AddCampaign() {
        setIsLoading(true)
        if (campaign_list === "") {
            toast.error("Enter Campaign")
            setIsLoading(false)
            return
        }
        else {
            fetch(`${BASE_URL}custom_settings/campaign?campaign=${campaign_list}`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setIsLoading(false)
                    window.location.reload()
                    if (data) {
                        toast.success("Added Successfully")
                    }
                })
                .catch(error => {
                    setIsLoading(false)
                    toast.error('Server error. Please try again later...');
                });
        }
    }

    function AddEmailTemplate() {
        setIsLoading(true)
        if (emailTemplateName === "") {
            toast.error("Please Enter Template Name")
            setIsLoading(false)
            return
        }
        if (emailTemplateSubject === "") {
            toast.error("Please Enter Template Subject")
            setIsLoading(false)
            return
        }
        if (emailTemplateUrl === "") {
            toast.error("Please Enter Template Url")
            setIsLoading(false)
            return
        }
        else {
           
            fetch(`${BASE_URL}templates/email_template/?template_name=${emailTemplateName}&subject=${emailTemplateSubject}&url=${emailTemplateUrl}`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setIsLoading(false)
                    if (data) {
                        toast.success("Added Successfully")
                    }
                    window.location.reload()
                })
                .catch(error => {
                    setIsLoading(false)
                    toast.error('Server error. Please try again later...');
                });
        }
    }

    function EditEmailTemplate() {
        setIsLoading(true)
        if (emailTemplateName === "") {
            toast.error("Please Enter Template Name")
            setIsLoading(false)
            return
        }
        if (emailTemplateSubject === "") {
            toast.error("Please Enter Template Subject")
            setIsLoading(false)
            return
        }
        if (emailTemplateUrl === "") {
            toast.error("Please Enter Template Url")
            setIsLoading(false)
            return
        }
        else {

            fetch(`${BASE_URL}templates/email_template/${localStorage.getItem("email_template_id")}?template_name=${emailTemplateName}&subject=${emailTemplateSubject}&url=${emailTemplateUrl}`, {
                method: "PUT",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setIsLoading(false)
                    // console.log(data);
                    if (data.id) {
                        localStorage.setItem("email_template_name", data.template_name)
                        localStorage.setItem("email_template_subject", data.subject)
                        localStorage.setItem("email_template_url", data.url)
                        localStorage.setItem("email_template_id", data.id)
                        toast.success("Updated Successfully")
                    }
                    else {
                        toast.error('Failed to update.Try Again...')
                    }
                    window.location.reload()
                })
                .catch(error => {
                    // console.log(error);
                    setIsLoading(false)
                    toast.error('Server error. Please try again later...');
                });
        }
    }

    function DeleteEmailTemplate() {
        setIsLoading(true)

        fetch(`${BASE_URL}templates/email_template/${localStorage.getItem("email_template_id")}`, {
            method: "DELETE",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                setIsLoading(false)
                if (data) {
                    toast.success("Deleted Successfully")
                }
                window.location.reload()
            })
            .catch(error => {
                setIsLoading(false)
                toast.error('Server error. Please try again later...');
            });

    }


    function AddWhatsAppTemplate() {
        setIsLoading(true)
        if (whatsAppTemplateName === "") {
            toast.error("Please Enter Template Name")
            setIsLoading(false)
            return
        }
        
        if (whatsAppTemplateUrl === "") {
            toast.error("Please Enter Template Url")
            setIsLoading(false)
            return
        }
        else {
        

            fetch(`${BASE_URL}templates/whatsapp_template/?template_name=${whatsAppTemplateName}&url=${whatsAppTemplateUrl}`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/jsona',
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setIsLoading(false)
                    if (data) {
                        toast.success("Added Successfully")
                    }
                    window.location.reload()
                })
                .catch(error => {
                    setIsLoading(false)
                    toast.error('Server error. Please try again later...');
                });
        }
    }

    function EditWhatsAppTemplate() {
        setIsLoading(true)
        if (whatsAppTemplateName === "") {
            toast.error("Please Enter Template Name")
            setIsLoading(false)
            return
        }
        
        if (whatsAppTemplateUrl === "") {
            toast.error("Please Enter Template Url")
            setIsLoading(false)
            return
        }
        else {
            const parsedTemplateCode = JSON.parse(whatsAppTemplateUrl); 
            fetch(`${BASE_URL}templates/whatsapp_template/${localStorage.getItem("whatsapp_template_id")}?template_name=${whatsAppTemplateName}`, {
                method: "PUT",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(parsedTemplateCode)
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setIsLoading(false)
                    console.log(data);
                    if (data.id) {
                        localStorage.setItem("whatsapp_template_name", data.template_name)
                        localStorage.setItem("whatsapp_template_url", data.url)
                        localStorage.setItem("whatsapp_template_id", data.id)
                        toast.success("Updated Successfully")
                    }
                    else {
                        toast.error('Failed to update.Try Again...')
                    }
                    // window.location.reload()
                })
                .catch(error => {
                    // console.log(error);
                    setIsLoading(false)
                    toast.error('Server error. Please try again later...');
                });
        }
    }

    function DeleteWhatsAppTemplate() {
        setIsLoading(true)

        fetch(`${BASE_URL}templates/whatsapp_template/${localStorage.getItem("whatsapp_template_id")}`, {
            method: "DELETE",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                setIsLoading(false)
                if (data) {
                    toast.success("Deleted Successfully")
                }
                window.location.reload()
            })
            .catch(error => {
                setIsLoading(false)
                toast.error('Server error. Please try again later...');
            });

    }


    return (
        <div>
            <Modal
                open={openSettingsModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='modalform_component'
            >
                <Box sx={style}>
                    {
                        selectedSettingOption === "Update Age" &&
                        <div className='settingUpdateBox'>
                            <p className='settingUpdateBoxheading'>Add Age</p>
                            <input placeholder='Add Age Group' className='settingUpdateBoxInput'
                                onChange={(e) => setAge_list(e.target.value)}></input>
                            <div type='submit' style={{ textDecoration: "none", marginTop: "10px" }} onClick={AddAge} className='settingUpdateBoxButton'>
                                {
                                    !isLoading ?
                                        <span>Add</span> :
                                        <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                                }
                            </div>
                        </div>
                    }



                    {
                        selectedSettingOption === "Update Gender" &&
                        <div className='settingUpdateBox'>
                            <p className='settingUpdateBoxheading'>Add Gender</p>
                            <input placeholder='Add Gender' className='settingUpdateBoxInput'
                                onChange={(e) => setGender_list(e.target.value)}></input>
                            <div type='submit' style={{ textDecoration: "none", marginTop: "10px" }} onClick={AddGender} className='settingUpdateBoxButton'>
                                {
                                    !isLoading ?
                                        <span>Add</span> :
                                        <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                                }
                            </div>
                        </div>
                    }



                    {
                        selectedSettingOption === "Update Course Interested" &&
                        <div className='settingUpdateBox'>
                            <p className='settingUpdateBoxheading'>Add Course Interested</p>
                            <input placeholder='Add Course Interested' className='settingUpdateBoxInput'
                                onChange={(e) => setCourses_list(e.target.value)}></input>
                            <div type='submit' style={{ textDecoration: "none", marginTop: "10px" }} onClick={AddCourse} className='settingUpdateBoxButton'>
                                {
                                    !isLoading ?
                                        <span>Add</span> :
                                        <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                                }
                            </div>
                        </div>
                    }



                    {
                        selectedSettingOption === "Update Lead Status" &&
                        <div className='settingUpdateBox'>
                            <p className='settingUpdateBoxheading'>Add Lead Status</p>
                            <input placeholder='Add Lead Status' className='settingUpdateBoxInput'
                                onChange={(e) => setLead_Status_list(e.target.value)}></input>
                            <div type='submit' style={{ textDecoration: "none", marginTop: "10px" }} onClick={AddLead_Status} className='settingUpdateBoxButton'>
                                {
                                    !isLoading ?
                                        <span>Add</span> :
                                        <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                                }
                            </div>
                        </div>
                    }



                    {
                        selectedSettingOption === "Update Lead Source" &&
                        <div className='settingUpdateBox'>
                            <p className='settingUpdateBoxheading'>Add Lead Source</p>
                            <input placeholder='Add Lead Source' className='settingUpdateBoxInput'
                                onChange={(e) => setLead_Source_list(e.target.value)}></input>
                            <div type='submit' style={{ textDecoration: "none", marginTop: "10px" }} onClick={AddLead_Source} className='settingUpdateBoxButton'>
                                {
                                    !isLoading ?
                                        <span>Add</span> :
                                        <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                                }
                            </div>
                        </div>
                    }



                    {
                        selectedSettingOption === "Update Campaign" &&
                        <div className='settingUpdateBox'>
                            <p className='settingUpdateBoxheading'>Add Campaign</p>
                            <input placeholder='Add Campaign' className='settingUpdateBoxInput'
                                onChange={(e) => setCampaign_list(e.target.value)}></input>
                            <div type='submit' style={{ textDecoration: "none", marginTop: "10px" }} onClick={AddCampaign} className='settingUpdateBoxButton'>
                                {
                                    !isLoading ?
                                        <span>Add</span> :
                                        <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                                }
                            </div>
                        </div>
                    }


                    {
                        selectedSettingOption === "Delete Email Template" &&
                        <div className='settingUpdateBox'>
                            <p className='settingUpdateBoxheading'>Are you sure you want to delete the template ?</p>
                            <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                                <div type='submit' style={{ textDecoration: "none", marginTop: "10px" }} onClick={DeleteEmailTemplate} className='settingUpdateBoxButton'>
                                    {
                                        !isLoading ?
                                            <span>Yes</span> :
                                            <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                                    }
                                </div>

                                <div type='submit' style={{ textDecoration: "none", marginTop: "10px" }} onClick={handleClose} className='settingUpdateBoxButton'>

                                    <span>No</span>

                                </div>
                            </div>

                        </div>
                    }


                    {
                        selectedSettingOption === "Add Email Template" &&
                        <div className='settingUpdateBox'>
                            <p className='settingUpdateBoxheading'>Add Email Template</p>
                            {<input placeholder='Template Name' className='settingUpdateBoxInput'
                                onChange={(e) => setEmailTemplateName(e.target.value)}></input>}
                            {<input placeholder='Template Subject' className='settingUpdateBoxInput'
                                onChange={(e) => setEmailTemplateSubject(e.target.value)}></input>}
                            {<input placeholder='Template Url' className='settingUpdateBoxInput'
                                onChange={(e) => setEmailTemplateUrl(e.target.value)}></input>}
                            <div type='submit' style={{ textDecoration: "none", marginTop: "10px" }} onClick={AddEmailTemplate} className='settingUpdateBoxButton'>
                                {
                                    !isLoading ?
                                        <span>Add</span> :
                                        <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                                }
                            </div>
                        </div>
                    }


                    {
                        selectedSettingOption === "Edit Email Template" &&
                        <div className='settingUpdateBox'>
                            <p className='settingUpdateBoxheading'>Edit Email Template</p>
                            {<input placeholder='Name' value={emailTemplateName} className='settingUpdateBoxInput'
                                onChange={(e) => setEmailTemplateName(e.target.value)}></input>}
                            { <input placeholder='Subject' value={emailTemplateSubject} className='settingUpdateBoxInput'
                                onChange={(e) => setEmailTemplateSubject(e.target.value)}></input>}
                            { <input placeholder='Url' value={emailTemplateUrl} className='settingUpdateBoxInput'
                                onChange={(e) => setEmailTemplateUrl(e.target.value)}></input>}

                            <div type='submit' style={{ textDecoration: "none", marginTop: "10px" }} onClick={EditEmailTemplate} className='settingUpdateBoxButton'>
                                {
                                    !isLoading ?
                                        <span>Update</span> :
                                        <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                                }
                            </div>
                        </div>
                    }


                    
{
                        selectedSettingOption === "Delete WhatsApp Template" &&
                        <div className='settingUpdateBox'>
                            <p className='settingUpdateBoxheading'>Are you sure you want to delete the template ?</p>
                            <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                                <div type='submit' style={{ textDecoration: "none", marginTop: "10px" }} onClick={DeleteWhatsAppTemplate} className='settingUpdateBoxButton'>
                                    {
                                        !isLoading ?
                                            <span>Yes</span> :
                                            <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                                    }
                                </div>

                                <div type='submit' style={{ textDecoration: "none", marginTop: "10px" }} onClick={handleClose} className='settingUpdateBoxButton'>

                                    <span>No</span>

                                </div>
                            </div>

                        </div>
                    }


                    {
                        selectedSettingOption === "Add WhatsApp Template" &&
                        <div className='settingUpdateBox'>
                            <p className='settingUpdateBoxheading'>Add WhatsApp Template</p>
                            {<input placeholder='Template Name' className='settingUpdateBoxInput'
                                onChange={(e) => setWhatsAppTemplateName(e.target.value)}></input>}
                            {<input placeholder='Template Url' className='settingUpdateBoxInput'
                                onChange={(e) => setWhatsAppTemplateUrl(e.target.value)}></input>}
                            <div type='submit' style={{ textDecoration: "none", marginTop: "10px" }} onClick={AddWhatsAppTemplate} className='settingUpdateBoxButton'>
                                {
                                    !isLoading ?
                                        <span>Add</span> :
                                        <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                                }
                            </div>
                        </div>
                    }


                    {
                        selectedSettingOption === "Edit WhatsApp Template" &&
                        <div className='settingUpdateBox'>
                            <p className='settingUpdateBoxheading'>Edit WhatsApp Template</p>
                            {<input placeholder='Name' value={whatsAppTemplateName} className='settingUpdateBoxInput'
                                onChange={(e) => setWhatsAppTemplateName(e.target.value)}></input>}
                            { <textarea placeholder='API code' value={whatsAppTemplateUrl} className='settingUpdateBoxInput'
                                style={{marginTop:'15px',width:'20vw',height:'20vh'}}
                                onChange={(e) => setWhatsAppTemplateUrl(e.target.value)}></textarea>}

                            <div type='submit' style={{ textDecoration: "none", marginTop: "10px" }} onClick={EditWhatsAppTemplate} className='settingUpdateBoxButton'>
                                {
                                    !isLoading ?
                                        <span>Update</span> :
                                        <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                                }
                            </div>
                            
                        </div>
                    }






                </Box>
            </Modal>

            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: '',
                    duration: 2500,
                    style: {
                        background: 'white',
                        color: '#000',
                    },

                    success: {
                        duration: 2500,
                        theme: {
                            primary: 'green',
                            secondary: 'white',
                        },
                    },
                }}
            />
        </div>
    );
}
