import React from 'react';

function Welcome_mail({ subject, recipientName }) {
  return (
    <html>
      <head>
        <title>{subject}</title>
      </head>
      <body>
        <h1>Hello {recipientName}!</h1>
        <p>This is a test email sent using Python and SMTP with an HTML template.</p>
      </body>
    </html>
  );
}

export default Welcome_mail;
