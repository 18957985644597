import React, { useState, useEffect, useContext } from 'react'
import './ConsultantHomePage.css'
import { MainContext } from '../../ContextStore/MainContext';

import StatsBox from '../../Components/StatsBox/StatsBox'
import CourseChart from '../../Components/AdminCharts/CourseChart';
import BatchesBox from '../../Components/BatchesBox/BatchesBox';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Header from '../../Components/Header/Header';
import CustomNotesModal from './CustomNotesModal';
import toast, { Toaster } from 'react-hot-toast';



export default function ConsultantHomePage() {

  const primary_consultant = localStorage.getItem('consultantEmail')

  const { BASE_URL, selectedMonth, setSelectedMonth, setLeadData1, setOpenCustomNotesModal, setSelectedSubOption, customNotes, setCustomNotes,
    genderList, setGenderList,
    ageList, setAgeList,
    courseInterested, setCourseInterested,
    leadStatusList, setLeadStatusList,
    leadSourceList, setLeadSourceList,
    campaignList, setCampaignList,
    consultantLeadData, setConsultantLeadData,
  } = useContext(MainContext);
  const [leadData, setLeadData] = useState([])
  const [startDate, setStartDate] = useState('')
  const [totalLeads, setTotalLeads] = useState(0)
  const [newLeads, setNewLeads] = useState(0)
  const [lostLeads, setLostLeads] = useState(0)
  const [paidLeads,setPaidLeads]=useState(0)
  const [totalPaymentRemainders, setTotalPaymentRemainders] = useState(0)
  const [follwupLeads, setFollowupLeads] = useState(0)
  const [customNotesList, setCustomNotesList] = useState([])

  const [totalRevenue, setTotalRevenue] = useState(0)
  const [totalDiscount, setTotalDiscount] = useState(0)
  const [totalCollect, setTotalCollect] = useState(0)




  const getGreeting = () => {
    const currentTime = new Date().getHours();
    if (currentTime < 12) {
      return 'Good Morning';
    } else if (currentTime >= 12 && currentTime < 18) {
      return 'Good Afternoon';
    } else {
      return 'Good Evening';
    }
  };


  useEffect(() => {
    genderList.length === 0 && fetch(`${BASE_URL}custom_settings/gender`)
      .then(response => {
        if (!response.ok) throw new Error('Failed to fetch data');
        return response.json();
      })
      .then(data => {
        setGenderList(data)
        localStorage.setItem("gender_list", JSON.stringify(data))
      })
      .catch(error => {
        console.log(error);
      });



    ageList.length === 0 && fetch(`${BASE_URL}custom_settings/age`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        setAgeList(data)
        localStorage.setItem("age_list", JSON.stringify(data))
      })
      .catch(error => {
        console.log(error);
      });



    courseInterested.length === 0 && fetch(`${BASE_URL}custom_settings/course_interested`)
      .then(response => {
        if (!response.ok) throw new Error('Failed to fetch data');
        return response.json();
      })
      .then(data => {
        setCourseInterested(data)
        localStorage.setItem("course_interested", JSON.stringify(data))
      })
      .catch(error => {
        console.log(error);
      });




    leadStatusList.length === 0 && fetch(`${BASE_URL}custom_settings/lead_status`)
      .then(response => {
        if (!response.ok) throw new Error('Failed to fetch data');
        return response.json();
      })
      .then(data => {
        setLeadStatusList(data)
        localStorage.setItem("lead_status", JSON.stringify(data))
      })
      .catch(error => {
        console.log(error);
      })


    leadSourceList.length === 0 && fetch(`${BASE_URL}custom_settings/lead_source`)
      .then(response => {
        if (!response.ok) throw new Error('Failed to fetch data');
        return response.json();
      })
      .then(data => {
        setLeadSourceList(data)
        localStorage.setItem("lead_source", JSON.stringify(data))
      })
      .catch(error => {
        console.log(error);
      });



    campaignList.length === 0 && fetch(`${BASE_URL}custom_settings/campaign`)
      .then(response => {
        if (!response.ok) throw new Error('Failed to fetch data');
        return response.json();
      })
      .then(data => {
        setCampaignList(data)
        localStorage.setItem("campaign", JSON.stringify(data))
      })
      .catch(error => {
        console.log(error);
      });

  }, [])

  useEffect(() => {
    // fetch(`${BASE_URL}lead/leads`)

    consultantLeadData.length===0 &&  fetch(`${BASE_URL}lead/leads`)

      .then(response => response.json())
      .then(data => {
        setConsultantLeadData(data.reverse())
      })
      .catch((err) => {
        console.log(err);
      })


    fetch(`${BASE_URL}custom_settings/custom_notes/?consultant_id=${localStorage.getItem('consultantId')}`)

      .then(response => response.json())
      .then(data => {
        setCustomNotesList(data)
      })
      .catch((err) => {
        console.log(err);
      })

  }, [])


  useEffect(() => {

    // consultantLeadData && localStorage.setItem('leadData', JSON.stringify(consultantLeadData))
    let filteredData = consultantLeadData && consultantLeadData;


    if (primary_consultant) {
      filteredData = filteredData.filter(lead => {
        const lead_primary_consultant = lead.primary_consultant;
        return lead_primary_consultant === primary_consultant;
      });
      setLeadData(filteredData);
    }

    if (startDate) {
      // setSelectedMonth('')
      filteredData = filteredData.filter(lead => {
        const leadDate = new Date(lead.followup_date.split(" ")[0]);
        const startDateObj = new Date(startDate);
        return leadDate.toDateString() === startDateObj.toDateString() 
      });
    }

    if (selectedMonth) {
      // setStartDate('')
      filteredData = filteredData.filter(lead => {
        const leadDateMonth = new Date(lead.followup_date.split(" ")[0]).getMonth() + 1;
        return leadDateMonth === selectedMonth;
      });
    }
    setLeadData1(filteredData)


    let total_lead_count = 0; let new_leads = 0; let lost_leads = 0; let inactive_leads = 0; let closed_leads = 0;
    let followup_leads = 0; let payment_remainders = 0; let Revenue = 0; let Discount = 0; let FeesPaid = 0;
    let paid_leads=0;

    filteredData.map(lead => {
      // console.log(lead);
      console.log("total",lead.total_fees);

      const totalFees = parseFloat(lead?.total_fees) || 0;
      Revenue += totalFees;

      const discountGiven = parseFloat(lead?.discount) || 0;
      Discount += discountGiven

      const fees_paid = parseFloat(lead.fees_paid) || 0;
      FeesPaid += fees_paid

      if (lead.lead_status === "New" && lead.primary_consultant === primary_consultant) {
        new_leads++
      }
      if ((lead.lead_status === "Lost") && lead.primary_consultant === primary_consultant) {
        lost_leads++
      }
      if ((lead.lead_status === "Inactive") && lead.primary_consultant === primary_consultant) {
        inactive_leads++
      }
      if ((lead.lead_status === "Paid") && lead.primary_consultant === primary_consultant) {
        paid_leads++
      }
      if ((lead.lead_status === "Closed") && lead.primary_consultant === primary_consultant) {
        closed_leads++
      }
      // if ((new Date(lead.followup_date).toDateString() === (startDate!=='' ? new Date(startDate).toDateString() : new Date().toDateString())) && lead.primary_consultant === primary_consultant && lead.lead_status==="Follow Up") {
      //   followup_leads++
      // }
     
      const leadDate = new Date(lead.followup_date);
      const compareDate = startDate !== '' ? new Date(startDate) : new Date();
      const leadMonth = leadDate.getMonth() + 1;
  
      if ((leadDate.toDateString() === compareDate.toDateString() 
        || (selectedMonth && leadMonth === selectedMonth) )
        && lead.lead_status === "Follow Up") {
        followup_leads++;
      }
      
      if ((new Date(lead.payment_reminder_date).toDateString() === new Date().toDateString()) && lead.primary_consultant === primary_consultant) {
        payment_remainders++
      }
      if (lead.primary_consultant === primary_consultant) {
        total_lead_count++;
      }






    });



    setTotalLeads(total_lead_count)
    setNewLeads(new_leads)
    setLostLeads(lost_leads + closed_leads + inactive_leads)
    setFollowupLeads(followup_leads)
    setTotalPaymentRemainders(payment_remainders)
    setPaidLeads(paid_leads)

    setTotalRevenue(Revenue)
    setTotalDiscount(Discount)
    setTotalCollect(FeesPaid)


  }, [startDate, selectedMonth, consultantLeadData])

  function deleteNotes(notesId) {
    fetch(`${BASE_URL}custom_settings/custom_notes/${notesId}`, {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        return response.json;
      })
      .then(data => {
        if (data) {
          toast.success("Deleted Successfully")
        }
        window.location.reload()
      })
      .catch(error => {
        console.log(error);
        toast.error('Server error. Please try again later...');
      });
  }







  const months = [
    { number: 1, name: 'January' },
    { number: 2, name: 'February' },
    { number: 3, name: 'March' },
    { number: 4, name: 'April' },
    { number: 5, name: 'May' },
    { number: 6, name: 'June' },
    { number: 7, name: 'July' },
    { number: 8, name: 'August' },
    { number: 9, name: 'September' },
    { number: 10, name: 'October' },
    { number: 11, name: 'November' },
    { number: 12, name: 'December' }
  ];


  return (
    <div>
      <Header />

      <div className='consultant_homepage_main_section'>

        <div className='data_and_charts'>
          <div className='welcome_section'>
            <p>Welcome {localStorage.getItem('consultantName')}; {getGreeting()}</p>

            <div className='admin_page_filters_section'>


              <FormControl className='admin_homepage_dropdown'>
                <InputLabel id="demo-simple-select-label">Month</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedMonth}
                  label="Course"
                  onChange={(e) => {
                    setSelectedMonth(e.target.value)
                    setStartDate('')
                  }}
                >
                  {
                    months.map((e, id) => {
                      return (
                        <MenuItem value={e.number} key={id}>{e.name}</MenuItem>
                      )
                    })
                  }

                </Select>
              </FormControl>


              <DatePicker
                selected={startDate}
                onChange={(date) => { setStartDate(date);setSelectedMonth('') }}
                dateFormat="dd/MM/yyyy"
                placeholderText="Select Date"
                className='search_input admin_page_datepicker'
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />

            </div>

            <div className='clear_filters_section'>
              {selectedMonth && <p className='clear_filters' onClick={() => { setSelectedMonth('') }}>Clear Month</p>}
              {startDate && <p className='clear_filters' onClick={() => { setStartDate('') }}>Clear Date</p>}
              {/* {(selectedMonth || startDate) && <p className='clear_filters' onClick={() => {
                setSelectedMonth(''); setStartDate('')
              }}>Clear All Filters</p>} */}
            </div>

          </div>


          <div className='statsBoxSection' style={{ gridTemplateColumns: 'repeat(4,1fr)' }}>
            <StatsBox statName="Total Leads" field="Total Leads" value={totalLeads} backgroundColor="#FF6E8B" cursor="pointer" />
            <StatsBox statName="New Leads" field="New" value={newLeads} backgroundColor="#FFA500" cursor="pointer" />
            <StatsBox statName="Followup" field="Followup" startDate={startDate} selectedMonth={selectedMonth} value={follwupLeads} backgroundColor="lightblue" cursor="pointer" />
            <StatsBox statName="Total Paid" field="Paid" value={paidLeads} backgroundColor="#7CFC00" cursor="pointer" />
            <StatsBox statName="Today Fee Remainders" field="Payment" value={totalPaymentRemainders} backgroundColor="#7CFC00" cursor="pointer" />
            {/* <StatsBox statName="Lost Leads" field="Closed" value={lostLeads} backgroundColor="#7CFC00" cursor="pointer" /> */}
            <StatsBox statName="Total Revenue" field="Others" value={totalRevenue} backgroundColor="#87CEEB" cursor="default" />
            {/* <StatsBox statName="Discount" field="Others" value={totalDiscount} backgroundColor="#9966FF" cursor="default" /> */}
            <StatsBox statName="Collected" field="Others" value={totalCollect} backgroundColor="#FF69B4" cursor="default" />

          </div>


          {leadData.length > 0 && <div style={{ marginLeft: '-50px' }}>
            <CourseChart leadData={leadData} primary_consultant={primary_consultant} startDate={startDate} />
          </div>}
        </div>

        <div className='batches_section'>
          <div className='upcoming_batches' style={{ height: "32.5vh" }}>
            <p>Up-Coming Batches</p>
            <BatchesBox Batchname="AWS Data Engineering with Snowflake(From Python)" Date="25/04/2024" Time="6:30 AM" Instructor="Santhosh" />
            <BatchesBox Batchname="AWS Data Engineering with Snowflake(From SQL) " Date="15/05/2024" Time="8:00 AM" Instructor="Santhosh" />
            <BatchesBox Batchname="Full Stack Power BI with MS fabric Tools" Date="09/05/2024" Time="8:00 AM" Instructor="Ritesh" />
            <BatchesBox Batchname="DevOps" Date="09/05/2024" Time="7:00 AM" Instructor="Arjun" />
            <BatchesBox Batchname="Full Stack Power BI with MS fabric Tools" Date="06/06/2024" Time="7:00 PM" Instructor="Santhosh" />
            <BatchesBox Batchname="Python for Data Science & Data Analytics" Date="09/05/2024" Time="8:00 PM" Instructor="Koti" />

            <p style={{ marginTop: '50px' }}>On-Going Batches</p>
            <BatchesBox Batchname="AWS Data Engineering with Snowflake" Time="6:30 AM" Instructor="Santhosh" />
            <BatchesBox Batchname="AWS Data Engineering with Snowflake" Time="8:00 AM" Instructor="Santhosh" />
            <BatchesBox Batchname="Full Stack Power BI with MS fabric Tools" Time="6:30 AM" Instructor="Santhosh" />
            <BatchesBox Batchname="Data Science With Generative AI" Time="8:00 PM" Instructor="Koti" />


          </div>

          <div className='upcoming_batches' style={{ height: "32.5vh" }}>
            <p>Custom notes</p>
            <p onClick={() => {
              setOpenCustomNotesModal(true)
              setSelectedSubOption('Add Custom Notes')
            }} className='add_notes_button'>Add Notes</p>
            {
              customNotesList.length > 0 && customNotesList.map((e, id) => {
                return (
                  <div key={id} className='each_custom_note'>
                    <p>{e.notes}</p>
                    <FaRegEdit className='notes_icons' style={{ color: 'var(--mainColor)' }} onClick={() => {
                      setOpenCustomNotesModal(true)
                      setSelectedSubOption('Edit Custom Notes')
                      setCustomNotes(e.notes)
                      localStorage.setItem('notesId', e.id)
                    }} />
                    <MdDelete className='notes_icons' style={{ color: 'var(--mainColor)', marginLeft: '10px' }} onClick={() => {
                      deleteNotes(e.id)
                    }} />
                  </div>
                )
              })

            }

          </div>
        </div>

      </div>

      <CustomNotesModal />

      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 2500,
          style: {
            background: 'white',
            color: '#000',
          },

          success: {
            duration: 2500,
            theme: {
              primary: 'green',
              secondary: 'white',
            },
          },
        }}
      />


    </div>
  )
}
