import React, { useEffect, useState, useContext } from "react";
import { Chart } from "react-google-charts";
import { MainContext } from "../../ContextStore/MainContext";
import './Charts.css'
import { useNavigate } from "react-router-dom";



export default function SourceChart({ leadData }) {
    const navigate=useNavigate()
    const [chartData, setChartData] = useState([]);
    const { selectedYear, selectedMonth, selectedConsultant, selectedCourse, setSelectedChartSource,leadData1,setLeadData1 } = useContext(MainContext)
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']


    useEffect(() => {
        const leadCounts = {};

        let filteredData = leadData
        // .filter(lead=>(lead.lead_status!=='Lost' && lead.lead_status!=='Inactive' && lead.lead_status!=='Closed'));
        if (selectedConsultant) {
            filteredData = filteredData.filter(lead => lead.primary_consultant === selectedConsultant);
        }
        if (selectedCourse) {
            filteredData = filteredData.filter(lead => lead.course_interested === selectedCourse);
        }

        filteredData && filteredData.forEach(lead => {
            const date = new Date(lead.followup_date);
            const leadYear = date.getFullYear();
            const leadMonth = date.getMonth();

            if ((selectedMonth && leadMonth === selectedMonth - 1 && leadYear === selectedYear) || (!selectedMonth && leadYear === selectedYear)) {
                if (leadCounts[lead.lead_source]) {
                    leadCounts[lead.lead_source]++;
                } else {
                    leadCounts[lead.lead_source] = 1;
                }
            } else if (!selectedYear) {
                if (leadCounts[lead.lead_source]) {
                    leadCounts[lead.lead_source]++;
                } else {
                    leadCounts[lead.lead_source] = 1;
                }
            }
        });

        const formattedData = Object.entries(leadCounts).map(([leadSource, count]) => {
            return [leadSource, count];
        });

        setChartData([["Lead Source", "Number of Leads"], ...formattedData]);

    }, [selectedMonth, selectedYear, selectedConsultant, selectedCourse]);



    const chartOptions = {
        hAxis: {
            title: 'Lead Source',
            titleTextStyle: {
                color: '#333',
                fontSize: 14,
            },
            textStyle: {
                fontSize: 12,
            },
        },
        vAxis: {
            title: 'Number of Leads',
            titleTextStyle: {
                color: '#333',
                fontSize: 14,
            },
            format: '0',
        },
    };

    const handleSelect = (e) => {
        try {
            const selectedItem = chartData[e.chartWrapper.getChart().getSelection()[0].row + 1];
            if (selectedItem) {
                const xlabel = selectedItem[0];
                setSelectedChartSource(xlabel)
                // console.log(selectedConsultant);
                // console.log(xlabel);
                // const newData = leadData1.filter(lead => lead.lead_source === xlabel);
                // setLeadData1(newData);
                // navigate('/lead-management')
                // console.log(xlabel);
            }
        }
        catch (err) {
            console.log(err);
        }
    };



    return (
        <div className="chartSection">
            {chartData.length > 1 ? (
                <Chart chartType="ColumnChart" width="43.5vw" height="400px" data={chartData} options={chartOptions}
                chartEvents={[
                  {
                    eventName: 'select',
                    callback: handleSelect,
                  },

                ]}

                />
            ) : <p>No Data Found for Lead Source Column Chart.Please change filter values...</p>}
        </div>
    );
}
