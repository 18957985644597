import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import '../../Components/ModalForm/ModalForm.css'
import { MainContext } from '../../ContextStore/MainContext';
import toast, { Toaster } from 'react-hot-toast';
import ReactLoading from 'react-loading';
import TextField from '@mui/material/TextField';
import 'react-datepicker/dist/react-datepicker.css';



export default function CustomNotesModal() {
    const { openCustomNotesModal,setOpenCustomNotesModal, BASE_URL, selectedSubOption,customNotes, setCustomNotes } = useContext(MainContext)
    const handleClose = () => setOpenCustomNotesModal(false);
    const [isLoading, setIsLoading] = useState(false)


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "30vw",
        bgcolor: '#eee',
        border: 'none !important',
        outline: "none !important",
        boxShadow: 24,
        borderRadius: 2,
        p: 2,
    };

   



    function AddCustomNotes() {
        setIsLoading(true)

        fetch(`${BASE_URL}custom_settings/custom_notes/?consultant_id=${localStorage.getItem('consultantId')}&notes=${customNotes}`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                return response.json;
            })
            .then(data => {
                setIsLoading(false)
                if (data) {
                    toast.success("Added Successfully")
                }
                window.location.reload()
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false)
                toast.error('Server error. Please try again later...');
            });

    }



    function EditCustomNotes() {
        setIsLoading(true)

        fetch(`${BASE_URL}custom_settings/custom_notes/${localStorage.getItem('notesId')}?notes=${customNotes}`, {
            method: "PUT",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                return response.json;
            })
            .then(data => {
                setIsLoading(false)
                if (data) {
                    toast.success("Updates Successfully")
                }

                window.location.reload()
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false)
                toast.error('Server error. Please try again later...');
            });
    }


    return (
        <div>
            <Modal
                open={openCustomNotesModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='modalform_component'
            >
                <Box sx={style}>
                    {
                        selectedSubOption === "Add Custom Notes" &&
                        <div style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center'}}>
                            <p className='settingUpdateBoxheading'>Add Notes</p>
                            <TextField variant="outlined" id="outlined-basic" label="Notes" className='form_input' style={{width:'25vw'}}
                                onChange={(e) => setCustomNotes(e.target.value)}
                            />

                            <div type='submit' style={{ textDecoration: "none", marginTop: "10px", width: 'fit-content', backgroundColor: 'var(--mainColor)' }} 
                             className='settingUpdateBoxButton' onClick={AddCustomNotes}>
                                {
                                    !isLoading ?
                                        <span>Add</span> :
                                        <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                                }
                            </div>
                        </div>

                    }


                    {
                        selectedSubOption === "Edit Custom Notes" &&
                        <div style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center'}}>
                            <p className='settingUpdateBoxheading'>Edit Notes</p>
                            <TextField variant="outlined" value={customNotes} id="outlined-basic" label="Notes" className='form_input'
                                onChange={(e) => setCustomNotes(e.target.value)}
                            />
                            <div type='submit' style={{ textDecoration: "none", marginTop: "10px", width: 'fit-content',backgroundColor: 'var(--mainColor)' }}
                             onClick={EditCustomNotes} 
                             className='settingUpdateBoxButton'>
                                {
                                    !isLoading ?
                                        <span>Update</span> :
                                        <div style={{ display: "flex", justifyContent: "center" }}><ReactLoading type="spin" color="white" height={30} width={30} /></div>
                                }
                            </div>
                        </div>

                    }



                </Box>
            </Modal>

            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: "",
                    duration: 2500,
                    style: {
                        background: 'white',
                        color: '#000',
                    },

                    success: {
                        duration: 2500,
                        theme: {
                            primary: 'green',
                            secondary: 'white',
                        },
                    },
                }}
            />
        </div>
    );
}
