import React from 'react'
import './BatchesBox.css'

export default function BatchesBox({ Batchname, Date, Time, Instructor }) {
  return (
    <div className='each_batch'>
      <p className='batch_name'>{Batchname}</p>
     {Date && <span className='batch_date'><span className='batchbox_heading'>Date:</span> {Date}</span> }
      <span className='batch_time' style={{paddingLeft:Date?"10px":'0px'}}><span className='batchbox_heading'>Time:</span> {Time}</span>
      <p className='instructor_name'><span className='batchbox_heading'>Instructor:</span> {Instructor}</p>
    </div>
  )
}
